
import React, { useState, useEffect } from 'react';
import Dashboard from  '../Dashboard'
import { useOktaAuth } from '@okta/okta-react';
import LinearProgress from '@mui/material/LinearProgress';
import { useUserInfo } from '../Zustand/store';

const Login = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);




  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
      
        setUserInfo(info);

        localStorage.setItem('user_details',JSON.stringify(info));

      }).catch((err) => {
        console.error(err);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes


  if (!authState) {
   
    return (
      <div>Loading...</div>
    );
  }


  return (
    <div id="home">
     

        { authState.isAuthenticated && !userInfo
        && <div> <LinearProgress /></div>}

        {authState.isAuthenticated && userInfo
    
        && (
            //userInfo.name
             <Dashboard/>
        )}

        {!authState.isAuthenticated
        && (
           
            oktaAuth.signInWithRedirect({ originalUri: '/' })
        )}

    </div>
  );
};
export default Login;

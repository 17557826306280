import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import {
    usePlatform,
    useCampaignType,
    useGoal,
    useOptimizationKpi,
    useClient,
    useMasterCampaign,
    useSubCampaign,
    useGoogleCampaign,
    useAccount,
    useSecondaryAccount,
    useFrontSecondaryApi,
    usePickDates,
    useNotes,
    useGoogleBudget,
    useTotalBudget,
    useTotalEstimatedGoal,
    useTotalEstimatedCostPerConversion,
    useDaysOff,
    useCampaignStartDate,
    useCampaignEndDate,
    usePeriodBudget,
    useEstimatedGoal,
    useEstimatedCostPerConversion,
    usePeriodsInfo   ,
    useWeekendsOff,
    useCampaignOwner,
    usePerformanceManager,
    useCampaignBackup,
    useTeamLeader,
    useContinousSecondaryKpi,
    useContinousTotalDays,
    useContinuousDailyBudget,
    useContinuousOptimizationKpi,
    usePeriodsDaysOffInfo,
    useCampaignDates,
    useEditCampaignDetails,
    useContinuousAverageCpm
} from '../Zustand/store';
import moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';
import { Typography,Container, Grid,Item, FormControl,
    InputLabel,Select,MenuItem, TextField, Button,FormHelperText, 
    Dialog, DialogTitle, DialogActions,DialogContent,DialogContentText, CircularProgress} from '@material-ui/core';
import { unstable_extendSxProp } from '@mui/system';
import { useHistory } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { isObject } from '@okta/okta-auth-js';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';




const modules = {
    toolbar: [
   
       [{ header: [1, 2, 3, 4, 5, 6, false] }],
       ["bold", "italic"],
       [{ color: [] }, { background: [] }],
       [{ script:  "sub" }, { script:  "super" }],
   
      
   ],
 }
 

const Step5 = (props) => {

    const history = useHistory();

    const platform = usePlatform(state  => state.platform);
    const campaignType = useCampaignType(state  => state.campaign);
    const goal = useGoal(state  => state.goal);
    const Kpi = useOptimizationKpi(state => state.Kpi);
    const clients = useClient(state => state.client);
    const masterCampaign = useMasterCampaign(state => state.masterCampaign);
    const subCampaign = useSubCampaign(state => state.subCampaign);
    const googleCampaign = useGoogleCampaign(state => state.google_campaign);
    const account = useAccount(state => state.account);
    const subAccount = useSecondaryAccount(state => state.saccount);
    const modes = usePickDates(state => state.dates);
   

    const googleBudget = useGoogleBudget(state => state.budget);
    const totalBUdget = useTotalBudget(state => state.totalBudget);
    const totalEstimatedGoal = useTotalEstimatedGoal(state => state.estimatedGoal);
    const totalEstimatedCostPerConversion = useTotalEstimatedCostPerConversion(state => state.estimatedCostPerConversion);

    const continousDaysOff = useDaysOff(state => state.daysOff);

    const frontSecondaryKpi = useFrontSecondaryApi(state => state.Kpi);

    const continuousAverageCpm = useContinuousAverageCpm(state => state.averageCpm);

    const campaignStartDate = useCampaignStartDate(state => state.date);
    const campaignEndDate = useCampaignEndDate(state => state.date);

    const [open, setOpen] = React.useState(false);
    const [confirm, setConfirm] = React.useState(false);
    
    const [success,setSuccess] = useState(false);

    const periodBudget = usePeriodBudget(state  => state.periodBudget);
  
    const estimatedGoal = useEstimatedGoal(state  => state.estimatedGoal);

    const weekends = useWeekendsOff(state  => state.weekends);
  
    const estimatedCostPerConversion = useEstimatedCostPerConversion(state  => state.estimatedCostPerConversion);


    const editCampaignDetials = useEditCampaignDetails(state  => state.campaignDetails);

    const campaignOwner = useCampaignOwner(state  => state.campaignOwner);
    const performanceManager = usePerformanceManager(state  => state.performanceManager);
    const campaignBackup = useCampaignBackup(state  => state.campaignBackup);
    const teamLeader = useTeamLeader(state  => state.teamLeader);

   

    const continuousSecondaryKpi = useContinousSecondaryKpi(state => state.Kpi);
    const continuousDailyBudget = useContinuousDailyBudget(state => state.budget)
    const continuousOptimizationKpi = useContinuousOptimizationKpi(state => state.Kpi)
    const continuousTotalDays = useContinousTotalDays(state => state.days)


    const notes = useNotes(state  => state.notes);
    const setNotes = useNotes(state  => state.setNotes);


    const periodInfo = usePeriodsInfo(state  => state.periodsInfo);

    const periodDaysOffInfo = usePeriodsDaysOffInfo(state  => state.periodsDaysOffInfo);

    const campaignDates = useCampaignDates(state  => state.dates);
   

    const daysOff = useDaysOff(state  => state.daysOff);

    const validate = ()  => {
    
        handleClickOpen();
     
     }

     
  useEffect(()=>{
  window.scrollTo({  top: 0,left: 0, behavior: "smooth" });
},[])

      


  

     const submitData = ()  => {

       

    
       setConfirm(true);

       const userData =  JSON.parse(localStorage.getItem('user_details'));


       const campaign_list =
         googleCampaign.map((a,b)=>{      
           const adgroup_id = a.adgroup_id ? a.adgroup_id : null;
           return {"name":a.metagroup_name,"id":a.metagroup_id,"adgroup_id":adgroup_id,"account_id":a.account_id,"account_name":a.account_name,"start_date":a.start_date,"end_date":a.end_date}
         });

        
        const periods_info_continous=
            [

                {
                    "start_date": campaignStartDate ? campaignStartDate : moment.utc(new Date(campaignDates[0] )).local().format("YYYY-MM-DD"),
                    "end_date":  campaignEndDate ? campaignEndDate : moment.utc(new Date(campaignDates[1] )).local().format("YYYY-MM-DD"),
                    "total_days":Math.round(continuousTotalDays),
                    "daily_budget": continuousDailyBudget,
                    "estimated_goal" :totalEstimatedGoal,
                    "estimated_optimaization":continuousOptimizationKpi,
                    "estimated_secondary_optimaization":continuousSecondaryKpi,
                  //"cap_optimaization":continuousAverageCpm,
                    "days_off": continousDaysOff.map((a)=>{return {"date":  moment.utc(new Date(a)).local().format("YYYY-MM-DD")}})

                }

            ];

            if(continuousAverageCpm !== undefined && continuousAverageCpm.length > 0  ){
                // Add the new key-value pair to the last object in the array
                periods_info_continous[periods_info_continous.length - 1]["cap_optimaization"] = continuousAverageCpm;
            }

            const periods_info=
            periodInfo.map((a,b)=>{        
                    return {
                    "start_date":   moment.utc(new Date(a.startDate)).local().format("YYYY-MM-DD"),
                    "end_date":  moment.utc(new Date(a.endDate)).local().format("YYYY-MM-DD"),
                    "total_budget": a.pbudget,
                    "daily_budget":(a.dailyBudget).toFixed(2),
                    "estimated_goal":a.pestimatedGoal,
                    "estimated_optimaization":a.pestimatedOptimiaztion,
                    "estimated_secondary_optimaization":a.estimatedSecondaryKpi[b],
                    "total_days":a.finalDays,
                    "days_off": sortedPeriodsDaysOff.map((l,p)=>{

                        const dd = l.split("/");
                        if(b == dd[0]){
                        return {"date":moment.utc(new Date( dd[1] )).local().format("YYYY-MM-DD")} 
                        }
                        else{
                            return {"date":null};
                        }                    
                        
                })
                    
                }
            
            });

    
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "user_id":userData.email,
            "platform": platform,
            "master_campaign_id": masterCampaign[0],
            "master_campaign_name": masterCampaign[1],
            "sub_campaign_name": subCampaign,
            "Client": clients[0],
            "campaign_type": campaignType,
            "goal": goal,
            "opt_kpi": Kpi,
            "secondary_kpi":  frontSecondaryKpi,
            "campaigns":campaign_list,
            "start_date":campaignStartDate ? campaignStartDate : moment.utc(new Date(campaignDates[0] )).local().format("YYYY-MM-DD"),
            "end_date": campaignEndDate ? campaignEndDate : moment.utc(new Date(campaignDates[1] )).local().format("YYYY-MM-DD"),
            "mode": modes,
            "periods_info":modes == "continuous" ? periods_info_continous : periods_info,
            "note": notes,
            "total_budget":totalBUdget.replace(/[€,]+/g,""),
            "estimated_goal":totalEstimatedGoal,
            "campaign_owner":campaignOwner[1],
            "performance_manager":performanceManager[1],
            "campaign_backup":campaignBackup[1],
            "team_leader":teamLeader[1],
            "timestamp":new Date()
        });

        var requestOptions = {
        method: isObject(editCampaignDetials) ? 'PUT' : 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

       
        const insertApi = process.env.REACT_APP_BASE_URL + "extractSubCampaign";
        const editApi = process.env.REACT_APP_BASE_URL + "subCampaign/update/" + editCampaignDetials._id;

        
      
                fetch(isObject(editCampaignDetials) ? editApi : insertApi , requestOptions)
                .then(response => response.json())
                .then(result => {


                            setSuccess(true);
                            // window.location.reload();
                            // history.push('/dashboard');
                            window.setTimeout(() => {  history.push("/dashboard") }, 1000);

                                
                })
                .catch(error => console.log('error occured', error));
         
                

    }
     
     props.step5Ref.current = {
         submit: validate
     };

     const [statusCampaign,setStatusCampaign] = useState(true);
     const collapseCampaign = () =>{
        
        if(statusCampaign == true){
            setStatusCampaign(false);
        }else{
            setStatusCampaign(true);
        }
     }


     const [statusDaysOff,setStatusDaysOff] = useState(true);
     const collapseDaysOff = () =>{
        
        if(statusDaysOff == true){
            setStatusDaysOff(false);
        }else{
            setStatusDaysOff(true);
        }
     }

     const [statusBudgetSetup,setStatusBudgetSetup] = useState(true);
     const collapseBudgetSetup = () =>{
        
        if(statusBudgetSetup == true){
            setStatusBudgetSetup(false);
        }else{
            setStatusBudgetSetup(true);
        }
     }


     const [statusCampaignOwnership,setCampaignOwnership] = useState(true);
     const collapseCamapignOwnership = () =>{
        
        if(statusCampaignOwnership == true){
            setCampaignOwnership(false);
        }else{
            setCampaignOwnership(true);
        }
     }


     const [statusNotes,setStatusNotes] = useState(true);
     const collapseNotes = () =>{
        
        if(statusNotes == true){
            setStatusNotes(false);
        }else{
            setStatusNotes(true);
        }
     }
     

 const handleClickOpen = () => {
    setOpen(true);
  };
 
  const handleClose = () => {
    setOpen(false);
  //  window.location.reload();
 
  };


  const addNotes = (e) =>{

    setNotes(e);

}
     
const [sortedPeriodsDaysOff,setSortedPeriodsDaysOff] = useState([]);


useEffect(()=>{



  let arr=[]



  periodDaysOffInfo.filter((a,b) =>{

 
            arr.push(a[1]+ "/" + a[0]);


    /*
    a.map((c,d)=>{

            console.log(c + ",","dfsa")
           // console.log([1],"compare")

        
            /*
            if(periodDaysOffInfo[d][1] == b )
            {
                
                
               // console.log(periodDaysOffInfo[b][0],"-",d );

                arr.push(d+"/"+periodDaysOffInfo[b][0]);
        
            }

   })*/


  // console.log(arr, "see array")
  

    
  })

 setSortedPeriodsDaysOff(arr);

},[])




  return (
    <div className="card-body">

    <div className="form first">
    <div className="details personal">
     <div onClick={collapseCampaign} style={{marginLeft:'5%',marginRight:'5%'}}>  <h2 style={{fontSize:15,padding:'.5rem', borderTop:'1px solid lightgray', borderLeft:'1px solid lightgray', borderRight:'1px solid lightgray',color:'white', backgroundColor:'#4169e1',cursor:'pointer'}}>Add Campaign   
        <span style={{float:'right'}}>{statusCampaign ?<KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowLeftIcon/>   }</span>
        </h2></div>
      {statusCampaign ?
       <div style={{padding:'3%', backgroundColor:'aliceblue', marginLeft:'5%',marginRight:'5%'}}>
       
        <table style={{display:'flex', flexDirection:'column', width:'100%', alignContent:'space-around'}}>
            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Platform 
                </td>
                <td>
                    {platform}
                </td>

            </tr>


            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Campaign Type 
                </td>
                <td>
                    {campaignType}
                </td>

            </tr>



            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Goal
                </td>
                <td>
                    {goal}
                </td>

            </tr>

           
            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                   Advertiser
                </td>
                <td>
                   {clients}
                </td>

            </tr>


            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                   Master Campaign
                </td>
                <td>
                    {masterCampaign[1]}
                </td>

            </tr>



            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                  Sub Campaign
                </td>
                <td>
                    {subCampaign}
                </td>

            </tr>


            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                   Campaigns
                </td>
                <td>
                  {

                    googleCampaign.map((a,b)=>{
                        return(
                          <p> { a.metagroup_name}</p> 
                        )
                    })
                   
                  }
                </td>

            </tr>

        </table>
        </div>

        :''}

    </div>


    {modes == "continuous" ?
    <div>
    <div onClick={collapseDaysOff}  style={{marginLeft:'5%',marginRight:'5%',marginTop:'.5rem'}}> 
        
         <h2 style={{fontSize:15,padding:'.5rem', borderTop:'1px solid lightgray', borderLeft:'1px solid lightgray', borderRight:'1px solid lightgray',color:'white', backgroundColor:'#4169e1',cursor:'pointer'}}>Days Off 
            
         <span style={{float:'right'}}>{statusDaysOff ?<KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowLeftIcon/>   }</span>
            </h2></div>
       {statusDaysOff?
       <div style={{padding:'3%', backgroundColor:'aliceblue', marginLeft:'5%',marginRight:'5%'}}>
       

        <table style={{display:'flex', flexDirection:'column', width:'100%', alignContent:'space-around'}}>
            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Campaign Start Date
                </td>
                <td>
                   {campaignStartDate ? campaignStartDate :  moment.utc(new Date(campaignDates[0])).local().format("YYYY-MM-DD")}
                </td>

            </tr>

            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Campaign End  Date
                </td>
                <td>
                {campaignEndDate ? campaignEndDate :  moment.utc(new Date(campaignDates[1])).local().format("YYYY-MM-DD")}
                </td>

            </tr>

            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                Weekends Off
                </td>
                <td>
                {weekends}
                </td>

            </tr>

            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                 Days Off
                </td>
                <td>
                {continousDaysOff.length > 0 ? continousDaysOff.map(a => {

                   const checkWeekends = new Date(a).getDay() % 6 === 0;

                //   if(!checkWeekends)
                 //  { 
                   return(
                    <p style={{ color:'red', padding:10,margin:5, border:'1px solid black',float:'left'}}>{  moment.utc(new Date(a)).local().format("YYYY-MM-DD")}</p>
                   )
                   //}
                }):'No Days Off Set'}
                </td>

            </tr>

        </table>
       
        </div>
        :''}

    </div>
    :''}
    </div>
      
    {modes == "continuous" ?
    <div>
    <div  onClick={collapseBudgetSetup}  style={{marginLeft:'5%',marginRight:'5%',marginTop:'.5rem'}}>  <h2 style={{fontSize:15,padding:'.5rem', borderTop:'1px solid lightgray', borderLeft:'1px solid lightgray', borderRight:'1px solid lightgray',color:'white', backgroundColor:'#4169e1',cursor:'pointer'}}>Budget Set Up
    <span style={{float:'right'}}>{statusBudgetSetup ?<KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowLeftIcon/>   }</span>
        </h2></div>
     {statusBudgetSetup?
       <div style={{padding:'3%', backgroundColor:'aliceblue', marginLeft:'5%',marginRight:'5%'}}>
      
        <table style={{display:'flex', flexDirection:'column', width:'100%', alignContent:'space-around'}}>

       
        <tr className='finalTblTr'>
            
                <td className='finalTblTd'>
                  Total Budget
                </td>
                <td>
                 
                    {isNaN(totalBUdget.replace(/[€,]+/g,"")) ? 0 : parseFloat(totalBUdget).toLocaleString('en-US').replace(/,/g," ",).replace('.',',') }
                </td>

            </tr>


            <tr className='finalTblTr'>
            
            <td className='finalTblTd'>
              Total Days
            </td>
            <td>
                {Math.round(continuousTotalDays).toLocaleString().replace(/,/g," ",).replace('.',',') }
            </td>

        </tr>


        <tr className='finalTblTr'>
            
            <td className='finalTblTd'>
             Daily Budget
            </td>
            <td>
                {parseFloat(continuousDailyBudget).toLocaleString().replace(/,/g," ",).replace('.',',') }
            </td>

        </tr>

            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Estimated {goal}
                </td>
                <td>                    
                    {totalEstimatedGoal.toLocaleString().replace(/,/g," ",).replace('.',',') }
                </td>

            </tr>

            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Estimated  {Kpi}
                </td>
                <td>
                    {isNaN(continuousOptimizationKpi) ? 0 : continuousOptimizationKpi.toLocaleString().replace(/,/g," ",).replace('.',',') }
                </td>

            </tr>

            {frontSecondaryKpi.length > 0 ?
            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Estimated  {frontSecondaryKpi} 
                </td>
                <td>
                    {continuousSecondaryKpi.toLocaleString().replace(/,/g," ",).replace('.',',') }
                </td>

            </tr>
            :''}

            {continuousAverageCpm !== undefined && continuousAverageCpm.length > 0 ?
            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                CAP {Kpi}
                </td>
                <td>
                    {continuousAverageCpm.toLocaleString().replace(/,/g," ",).replace('.',',') }
                </td>

            </tr>
            :''}

        </table>

      
    </div>
    :''}
    </div>
    :''}


    {modes == "periods" ?
    <div>
    <div  onClick={collapseBudgetSetup}  style={{marginLeft:'5%',marginRight:'5%',marginTop:'.5rem'}}>  <h2 style={{fontSize:15,padding:'.5rem', borderTop:'1px solid lightgray', borderLeft:'1px solid lightgray', borderRight:'1px solid lightgray',color:'white', backgroundColor:'#4169e1',cursor:'pointer'}}>Periods Info
    <span style={{float:'right'}}>{statusBudgetSetup ?<KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowLeftIcon/>   }</span>
        </h2></div>
     {statusBudgetSetup?
       <div style={{padding:'3%', backgroundColor:'aliceblue', marginLeft:'5%',marginRight:'5%'}}>
      

       {periodInfo.map((a,b)=>{

       
        return(
            <table style={{display:'flex', flexDirection:'column', width:'100%', alignContent:'space-around', marginTop:'30px'}}>

                    
                <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Start Date
                </td>
                <td>
                { 
                 moment.utc(new Date(a.startDate)).local().format("YYYY-MM-DD")
                    
                }
                                  
                </td>

                </tr>

                <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    End Date
                </td>
                <td>
                { 
                 moment.utc(a.endDate).local().format("YYYY-MM-DD")
                    
                }
                </td>

                </tr>

                <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Total Budget 
                </td>
                <td>
                   
                    {parseFloat(a.pbudget).toLocaleString().replace(/,/g," ",).replace('.',',') }
                </td>

                </tr>

                <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Daily Budget
                </td>
                <td>
                    {(a.dailyBudget).toLocaleString().replace(/,/g," ",).replace('.',',')  }
                </td>

                </tr>

                <tr className='finalTblTr'>
                <td className='finalTblTd'>
                   
                    Estimated {goal}
                </td>
                <td>
               
                    {parseFloat(a.pestimatedGoal).toLocaleString().replace(/,/g," ",).replace('.',',') }
                </td>

                </tr>


                <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Estimated {Kpi}
                </td>
                <td>
                 
                    {parseFloat(a.pestimatedOptimiaztion).toLocaleString().replace(/,/g," ",).replace('.',',') }
                </td>

                </tr>

                  {frontSecondaryKpi.length > 0  ?
                <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Estimated {frontSecondaryKpi}
                </td>
                <td>
                    {a.estimatedSecondaryKpi[b].toLocaleString().replace(/,/g," ",).replace('.',',') }
                </td>

                </tr>:''}


                {periodDaysOffInfo.length > 0 ?
                <tr className='finalTblTr'>
                <td className='finalTblTd'>
                   Days Off
                </td>
                <td>
                    {

                        periodDaysOffInfo.map((o,p)=>{

                          

                           return(

                               

                                    <span style={{margin:2, padding:5, color:'red'}}>                                       
                                        {

                                        periodDaysOffInfo[p][1] == b ?  moment.utc(new Date(periodDaysOffInfo[p][0])).local().format("YYYY-MM-DD") : ''
    
                                        }
                                        
                                      </span>
                              

                           )

                            //periodDaysOffInfo[b][1] == b ?  moment.utc(new Date(periodDaysOffInfo[0][0])).local().format("YYYY-MM-DD") : ''


                        })
                        
                    }
                </td>

                </tr>
                :''}

                </table>

        )

       })}
      
    </div>
    :''}
    </div>
    :''}

  
    <div  onClick={collapseCamapignOwnership}  style={{marginLeft:'5%',marginRight:'5%',marginTop:'.5rem'}}>  <h2 style={{fontSize:15,padding:'.5rem', borderTop:'1px solid lightgray', borderLeft:'1px solid lightgray', borderRight:'1px solid lightgray',color:'white', backgroundColor:'#4169e1',cursor:'pointer'}}>Campaign Ownership
    <span style={{float:'right'}}>{statusCampaignOwnership ?<KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowLeftIcon/>   }</span>
        </h2></div>
    { statusCampaignOwnership ?
       <div style={{padding:'3%', backgroundColor:'aliceblue', marginLeft:'5%',marginRight:'5%'}}>
      
        <table style={{display:'flex', flexDirection:'column', width:'100%', alignContent:'space-around'}}>

       
        <tr className='finalTblTr'>
            
                <td className='finalTblTd'>
                   Campaign Owner
                </td>
                <td>
                    {campaignOwner[0]}
                </td>

            </tr>

            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                   Performance Manager
                </td>
                <td>
                    {performanceManager[0]}
                </td>

            </tr>

            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                   Campaign Backup
                </td>
                <td>
                    {campaignBackup[0]}
                </td>

            </tr>


            <tr className='finalTblTr'>
                <td className='finalTblTd'>
                    Team Leader
                </td>
                <td>
                    {teamLeader[0]}
                </td>

            </tr>

        </table>
    
   
    </div>
    :''}
  
    <div  onClick={collapseNotes}  style={{marginLeft:'5%',marginRight:'5%',marginTop:'.5rem'}}>  <h2 style={{fontSize:15,padding:'.5rem', borderTop:'1px solid lightgray', borderLeft:'1px solid lightgray', borderRight:'1px solid lightgray',color:'white', backgroundColor:'#4169e1',cursor:'pointer'}}>Add Notes
    <span style={{float:'right'}}>{statusNotes ?<KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowLeftIcon/>   }</span>
        </h2></div>
    { statusNotes ?
       <div style={{padding:'3%', backgroundColor:'aliceblue', marginLeft:'5%',marginRight:'5%'}}>
      
       
       <FormControl variant="outlined"  fullWidth style={{marginTop:10, backgroundColor:'white'}}>

       <ReactQuill style={{height: 400}} modules={modules} theme="snow" value={notes} onChange={addNotes} placeholder="Enter comments ...."/>
                       {/**<TextField variant='outlined' fullWidth multiline minRows={15}
                     
                                id="demo-simple-select"
                                value={notes}
                                onChange={(e) => {addNotes(e.target.value);}}
                                label="Notes"
                                
                              // error={notesVal}
                            
                /> */} 
                </FormControl>
   
    </div>
    :''}
        
     

    {confirm ? 

            <Dialog
            open={open}
        //  onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{width:600}}>

            
            <div style={{display:'flex',marginTop:"10%", paddingLeft:"10%",  }}>

                {success ?
                <>
                <div style={{flexBasis:'10%',marginRight:'1%',fontSize:'20px'}}> <CheckIcon style={{fontSize:'2.5rem',color:'green',}} /> </div>
                <div style={{flexBasis:'72%', marginTop:10, color:'black'}}>Your Data has been submitted successfully</div> 
                </>
                :
                <>
                <div style={{flexBasis:'10%',marginRight:'5%'}}> <CircularProgress /> </div>
                <div style={{flexBasis:'72%', marginTop:10, color:'black', padding:10}}>Submitting data in process ...... Please Wait ......</div> 
                </>
                }
                

            </div>


            </DialogContentText>
            </DialogContent>
            <DialogActions>
            
            {success?
            <Button onClick={handleClose} style={{margin:5,backgroundColor:'#f4f4f4', color:'black'}}>CLOSE</Button>
           :''}
            </DialogActions>
            </Dialog>


          :
    

          <Dialog
            open={open}
        //  onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{width:600}}>

            
            <div style={{display:'flex',marginTop:"10%",padding:'1%'  }}>

                
                <div style={{flexBasis:'100%',marginLeft:'13%'}}>
                <h3>Do you confirm submitting this data ?</h3>    
                </div>
               
                
                

            </div>


            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} style={{margin:5,backgroundColor:'#f4f4f4', textAlign:'center', color:'black'}}>NO</Button>
            <Button onClick={submitData} style={{margin:5,backgroundColor:'#f4f4f4', color:'black'}}>YES</Button>
            
            </DialogActions>
            </Dialog>

        
            
            
        }




</div>
   

    


  )
}

export default Step5
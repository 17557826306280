import React from 'react'
import './layout.css';
import { Link,useHistory } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContactMailOutlined } from '@material-ui/icons';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import logo from '../../assets/mlogo.png';


const Header = () => {



  let location = useLocation();
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();

  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err) => (err.name === 'AuthApiError' && !err.errorCode && err.xhr.message === 'Failed to fetch');




  const searchParams = new URLSearchParams(document.location.search);
  const cid = searchParams.get('cid');


  const logout = async () => {

  //  console.log(window.location.origin,"check origin");
   
   // localStorage.removeItem("user_details")
    const basename = window.location.origin;
    oktaAuth.tokenManager.clear();
    oktaAuth.signOut({postLogoutRedirectUri:basename});

    window.location.reload();


  };


  if (!authState) {
    return null;
  }


  return (
   <>

      
   <input type="checkbox" id="nav-toggle" />

<div className="sidebar">

    <div className="sidebar-brand">
        <h2>
            <span style={{fontSize:25}}></span>
            <span><img src={logo} style={{width:180}} /></span>
        </h2>
    </div>

    <div className="sidebar-menu" >
      
       <ul>

        {/**
        <li><a href="/dashboard" class = {location.pathname == "/dashboard" ? "active" :''}>
          <span class="las la-igloo"> </span>
          <span><label>Overview</label></span></a>
     
        
        </li> */}

        <li><a href="/dashboard" className = {location.pathname == "/dashboard" ? "active" : location.pathname == "/" ?'active':'' }><span className="las la-clipboard">
            </span><span> Dashboard</span></a>
        </li>
      

        <li><a href="/masterCampaign" className = {location.pathname == "/masterCampaign" ? "active" :''}><span className="las la-tasks">
            </span><span> Master Campaign</span></a>
        </li>
      
        <li><a href="/addCampaign" className = {location.pathname == "/addCampaign"  ? "active" : ''}>
        {cid ?  <span className="las la-edit"> </span>   :  <span className="las la-plus-circle"> </span> }

          {cid ?  <span> Edit Sub Campaign </span> :
          <span> Sub Campaign </span>
          }

        </a>
        </li>

        <li><a href="/backup" className = {location.pathname == "/backup" ? "active" :'' }><span className="las la-clone">
            </span><span> Backup Campaigns</span></a>
        </li>


        <li><a href="/archive" className = {location.pathname == "/archive" ? "active" :'' }><span className="las la-archive">
            </span><span> Archived Campaigns</span></a>
        </li>

        <li><a href="/archiveMaster" className = {location.pathname == "/archiveMaster" ? "active" :'' }><span className="las la-archive">
            </span><span> Archived Masters </span></a>
        </li>

          {/**
          <li><a href="https://forms.office.com/e/jXVth7rEcR" target="_blank">
          <span className="las la-bug"> </span>
          <span> Error Reporting</span></a>
          </li>
           */}

          <li onClick={logout}><a href="" >
          <span className="las la-sign-out-alt"> </span>
          <span> Logout</span></a>
          </li>

   
          {/** 

            <li onClick={logout}><a href="" >
          <span class="las la-sign-out-alt"> </span>
          <span> Logout</span></a>
          </li>

          <li onClick={logout}><a href="" >
          <span class="las la-sign-out-alt"> </span>
          <span> Logout</span></a>
          </li>

          <li onClick={logout}><a href="" >
          <span class="las la-sign-out-alt"> </span>
          <span> Logout</span></a>
          </li>

          <li onClick={logout}><a href="" >
          <span class="las la-sign-out-alt"> </span>
          <span> Logout</span></a>
          </li>
  
         <li><a href=""><span class="las la-sign-in-alt"> </span>
         <span>Logout</span></a>
         </li>

         */}


        </ul>

        
    </div>

  

    
</div>

 

</>
  )
}

export default Header
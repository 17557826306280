import React,{useState, useEffect} from 'react'
import Layout from '../Layout'

import AppBar from '../Layout/appBar';

import Header from '../Layout/header';
import Footer from '../Layout/footer';
import Listing from './listing';
import ListingDetails from './listingDetails';



const MasterCampaignListing = () => {


  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("cid")


  return (
    
   
<>
<Header/>

      
<div className="main-content">
  
<AppBar/>


        <main>

        
        
        <ListingDetails data={id} /> 

         

        </main>
        
        
   </div>
      
<Footer/>
</>
  )
}

export default MasterCampaignListing
import React, { useEffect } from 'react'
import { useState } from 'react';
import moment from 'moment';
import Api from '../Service/network';
import LinearProgress from '@mui/material/LinearProgress';
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { Box,Tab,FormControl, InputLabel,Select,MenuItem, TextField, Checkbox, Button, TableCell, TableFooter, TableRow, TablePagination, makeStyles,} from '@material-ui/core';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { isObject } from '@okta/okta-auth-js';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';


const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
  });
  

  const pmuiCache = createCache({
    key: "mui-datatables",
    prepend: true
  });

  const gmuiCache = createCache({
   key: "mui-datatables",
   prepend: true
 });
 
  
  const cmuiCache = createCache({
    key: "mui-datatables",
    prepend: true
  });
  
export const getMuiTheme = () =>
  createTheme({
   overrides: {
      MUIDataTable: {
        responsiveStacked: {
          maxHeight: 'none',
          overflowX:'auto'
        },
      },
    },
  });



const ListingDetails = ({check,data}) => {

//console.log(data,"recevice in listing details page buddy")
const [mediafyCampaign,setMediafyCampaign] = useState([]);
const[mLoading,setMloading] = useState(false);

const [platformCampaign,setPlatformCampaign] = useState([]);
const[campaignId, setCampaignId] = useState([]);
const[pLoading,setPloading] = useState(false);

const[campaignIdList, setCampaignIdList] = useState([]);

const [commentsVal, setCommentsVal] = useState(false);

const [showComments, setShowComments] = useState(true);


const[dailyTable, setDailyTable] = useState([]);
const[dLoading,setDloading] = useState(false);

const[ptLoading,setPtloading] = useState(false);

const[dataTable, setDataTable] = useState([]);
const[dataTableDays, setDataTableDays] = useState([]);
const[dtLoading,setDtloading] = useState(false);

const[comments,setComments] = useState('');
const[cLoading,setCloading] = useState(false);

const[listComments,setListComments] = useState([]);

const[hGlobalTbl, setHglobaltbl] = useState(false);


const [responsive, setResponsive] = useState("standard");
const [tableBodyHeight, setTableBodyHeight] = useState("600px");
const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("700px");
const [searchBtn, setSearchBtn] = useState(false);
const [downloadBtn, setDownloadBtn] = useState(false);
const [printBtn, setPrintBtn] = useState(false);
const [viewColumnBtn, setViewColumnBtn] = useState(false);
const [filterBtn, setFilterBtn] = useState(false);
const[currentPeriod, setCurrentPeriod] = useState();

const[performanceGlobalData, setPerformanceGlobalData]  = useState([]);
const[pfLoading, setPfloading]  = useState(false);

const[periodsGlobalData, setPeriodsGlobalData]  = useState([]);
const[pgLoading, setPgloading]  = useState(false);


const[variationDataBudget, setVariationDataBudget]  = useState([]);
const[variationDataGoal, setVariationDataGoal]  = useState([]);
const[variationDataOptKpi, setVariationDataOptKpi]  = useState([]);
const[variationDataSecKPI, setVariationDataSecKPI]  = useState([]);
const[vdLoading, setVdloading]  = useState(false);

const[checkCampaign, setCheckCampaign] = useState();

const[lastUpdate, setLastUpdate] = useState();


const sub_campaign_name_data=data[0];
const campaign_id_data = data[7];
const campaign_data = data[18];
const client_data = data[3];
const master_campaign_id_data= data[17];
const master_campaign_name_data= data[4];
const campaign_type_data = data[14];
const campaign_owner_data = data[8];
const opt_kpi_data=data[15];
const platform_data = data[9];
const sec_kpi_data = data[16];
const goal_data = data[10];
const team_leader_data = data[11];
const campaign_backup_data = data[13];
const performance_manager_data = data[12];
const notes_data = data[24];
const mode_data = data[22];
const total_budget_data = data[27];
const esitmated_goal_data =  data[19];
const periods_info_data =  data[23];
const start_date_data = data[20];
const end_date_data = data[21];
const days_off = data[25];
const sub_campaign_full_name_data = data[26];

//const budget_data =  esitmated_goal_data;
//const budget_data =  periods_info_data ;

useEffect(()=>{


 //  console.log(data[25],"main data");
  
    setDtloading(true);
    const campaign_id = campaign_id_data;
   
    const endpoint = process.env.REACT_APP_BASE_URL + "getGlobalData";
   
     Api(endpoint,"POST",{id: campaign_id})
     .then(result => {

       

        setDataTableDays(result.data.days);

        const test = result.data.nperiod;
        
        setCurrentPeriod(test);

        setDataTable(result.data.res[0]);

        setDtloading(false);
       
        }).catch((err)=>console.log(err))
   
            

    

 },[])


 useEffect(()=>{

   const campaign_id = campaign_id_data;
   const endpoint = process.env.REACT_APP_BASE_URL + "lastUpdate/"+campaign_id;
  
    Api(endpoint,"GET")

    .then(result => {

     
 
      setLastUpdate(result.data.res[0].last_update);

    }).catch((err)=>console.log(err))

 },[])

 useEffect(()=>{

   const campaign_id = campaign_id_data;
   const endpoint = process.env.REACT_APP_BASE_URL + "subCampaign/actions/"+campaign_id;
  
    Api(endpoint,"GET")

    .then(result => {

 
      setCheckCampaign(result.data);

    }).catch((err)=>console.log(err))


 })

 useEffect(()=>{
  
   setVdloading(true);
   const campaign_id = campaign_id_data;
  
   const endpoint = process.env.REACT_APP_BASE_URL + "VariationData?id="+campaign_id;
  
    Api(endpoint,"POST")

    .then(result => {

 

      setVariationDataBudget(result.data.Budget[0]);
      setVariationDataGoal(result.data.Goal[0]);
      setVariationDataOptKpi(result.data.OptKPI[0]);
      
    //  setVariationDataSecKPI(result.data.SecKPI[0]);


      if('SecKPI' in result.data){
         setVariationDataSecKPI(result.data.SecKPI[0]);
      }
      
     

      setVdloading(false);

       }).catch((err)=>console.log(err))
  
           

   

},[])





 useEffect(()=>{
    //process.env.REACT_APP_BASE_URLsubCampaign/master/637f55418780eb7faeae740b
    setMloading(true);
    const master_campaign_id = master_campaign_id_data;
    const endpoint = process.env.REACT_APP_BASE_URL +  "subCampaign/master/" + master_campaign_id ;
   
    Api(endpoint,"GET")
    .then(result => {

       setMediafyCampaign(result.data);
       setMloading(false);
      
     }).catch((err)=>console.log(err))
     

 },[])





 useEffect(()=>{
  
  
 setDloading(true);  

 const campaign_id = campaign_id_data

 const endpoint = process.env.REACT_APP_BASE_URL + "getDailyData";

  Api(endpoint,"POST",{id:campaign_id})
  .then(result => {

    
    setDloading(false);

    setDailyTable(result.data.res);
    
   }).catch((err)=>console.log(err))

 },[])





 useEffect(()=>{

   
            
    const endpoint = process.env.REACT_APP_BASE_URL + "subCampaign/comment/"+ campaign_id_data;

    Api(endpoint,"GET")
    .then(result => {

    

    setListComments(result.data);

   
    
    }).catch((err)=>console.log(err))

    



},[comments])



 const daily_columns = [
   
    {
        name: "day",
        label: "Day",
        options: {
         filter: false,
         sort: true,
         filterType:'multiselect',
         
        
        }
       },
 "Spend",
 {
   name: "app_engagement",
   label: "App Engagement",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: goal_data === "APP Engagement" ? true : false

   }
  },
  {
   name: "app_installs",
   label: "App Installs",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: goal_data === "APP installs" ? true : false
   }
  },
  {
   name: "cpa",
   label: "CPA",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: opt_kpi_data == "CPA" ? true : false

   }
  }, 
  {
   name: "cpc",
   label: "CPC",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
   display: opt_kpi_data == "CPC" || sec_kpi_data == "CPC"  ? true : false

   }
  }, 
  {
   name: "cpm",
   label: "CPM",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display:opt_kpi_data == "CPM" ? true : false

   }
  }, 
  {
   name: "cpv",
   label: "CPV",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display:opt_kpi_data == "CPV" ? true : false

   }
  }, 
  {
   name: "ctr",
   label: "CTR",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display:false

   }
  }, 
  {
   name: "clicks",
   label: "CLICKS",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: goal_data === "Clicks" ? true : false


   }
  }, 
  {
   name: "conversion_rate",
   label: "Conversion Rate",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display:false

   }
  }, 

  {
   name: "conversion_value",
   label: "Conversion Value",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: goal_data === "Conversion value" ? true : false

   }
  }, 
  {
   name: "conversion",
   label: "Conversion",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: goal_data === "Conversions" ? true : false

   }
  }, 
  {
   name: "is_lost_by_budget",
   label: "Is Lost By Budget",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display:false

   }
  }, 
  {
   name: "is_lost_by_rank",
   label: "Is Lost By Rank",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display:false

   }
  }, 
  {
   name: "impression_share",
   label: "Impression Share",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: goal_data == "Impression Share" ? true : false

   }
  }, 
  {
   name: "impressions",
   label: "Impressions",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: goal_data == "Impressions" ? true : false

   }
  }, 
  {
   name: "maximise_conversion_value",
   label: "Maximise Conversion Value",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: goal_data == "Maximise conversion value" ? true : false

   }
  }, 
  {
   name: "target_roas",
   label: "Target ROAS",

   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: opt_kpi_data == "Target ROAS" ? true : false

   }
  }, 
  {
   name: "view_rate",
   label: "View Rate",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display:false

   }
  }, 
  {
   name: "views",
   label: "Views",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display:goal_data === "Views" ? true : false

   }
  }, 
  {
   name: "cost_per_install",
   label: "Cost Per Install",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display:opt_kpi_data === "Cost_per_install" ? true : false

   }
  }, 
 
];

const daily_data = dailyTable.map((a,b)=> { 
return([
moment.utc(new Date(a.day)).local().format("YYYY-MM-DD"), 
a.spend,
a.APP_Engagement,
a.APP_installs,
a.CPA,
a.CPC,
a.CPM,
a.CPV,
a.CTR,
a.Clicks,
a.ConversionRate,
a.Conversion_value,
a.Conversions,
a.IS_Lost_by_Budget,
a.IS_Lost_by_Rank,
a.Impression_Share,
a.Impressions,
a.Maximise_conversion_value,
a.Target_ROAS,
a.ViewRate,
a.Views,
a.Cost_per_install

])});

const daily_options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: false,
    filterType: 'multiselect',
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    sortOrder: {
        name: 'day',
        direction: 'desc'
    },
    responsive: "standard",
    fixedHeader:true,
    fixedSelectColumn:false,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
        return (
          <TableFooter>
            <TableRow >
              <TableCell style={{backgroundColor:'aliceblue', fontWeight:'900', fontSize:13}}>Total Budget(Spend) : {parseFloat(dataTable.spend).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',') } € &nbsp;&nbsp; | &nbsp;&nbsp; {goal_data} : {parseFloat(dataTable[goal_data.replace(/ /g,"_")]).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',') } {opt_kpi_data == "Impression Share" ? '%' : ''}    &nbsp;&nbsp;| &nbsp;&nbsp;  {opt_kpi_data}: {parseFloat(dataTable[opt_kpi_data.replace(/ /g,"_")]).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',')} {opt_kpi_data == "Impression Share" ? '%' : '€'}   &nbsp;&nbsp; {sec_kpi_data ? '|' : '' } &nbsp;&nbsp; {sec_kpi_data ? sec_kpi_data +": "+ parseFloat(dataTable[sec_kpi_data.replace(/ /g,"_")]).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',')  :   ''} {sec_kpi_data == "Impression Share" ? '%' : '€'}  </TableCell>
            
            
             
            </TableRow>
            <TableRow>
                <TablePagination
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(_, page) => changePage(page)}
                    onRowsPerPageChange={event => changeRowsPerPage(event.target.value)}
                    rowsPerPageOptions={[10, 15, 100]}
                />
            </TableRow>
          </TableFooter>
        );
      }
 
     
  };

  const comments_columns = [
   
    {
        name: "date",
        label: "Date",
        options: {
         filter: true,
         sort: true,
         filterType:'multiselect',
        
        }
       },
 "Comments",
  {
    name: "user",
    label: "User",
    options: {
     filter: true,
     sort: true,
     filterType:'multiselect',

    }
   },

];


const comments_data = listComments.map((a,b)=> {
   
return([
moment.utc(new Date(a.date)).local().format("YYYY-MM-DD"), 
a.comment,
a.user
])});

const comments_options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: false,
    filterType: 'multiselect',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    sortOrder: {
        name: 'day',
        direction: 'desc'
    },
    responsive: "standard",
    fixedHeader:true,
    fixedSelectColumn:true
 
     
  };


const addComments = () => {


if(comments == "")
{
    setCommentsVal(true);
}

else{

    setCloading(true);
    let user = JSON.parse(localStorage.getItem('user_details'));        
    const endpoint = process.env.REACT_APP_BASE_URL + "subCampaign/extractComment";

    Api(endpoint,"POST", 
    { 
 
    "comment": comments,
    "campaignID": campaign_id_data,
    "user": user.name})
    .then(result => {

        setComments('');
        
        notify()
        setCloading(false);
    
    
    }).catch((err)=>console.log(err))
}


/*

  */


}

const notify = () => toast.success("Your comments submitted successfully",
{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
}
);

const handleShowComments = () => {

    showComments == false ? setShowComments(true) : setShowComments(false);
}

const[showCampaign,setShowCampaign] = useState(false);

const handleShowCampaign = () => {

   showCampaign == false ? setShowCampaign(true) : setShowCampaign(false);
}


const[showMasterCampaign,setShowMasterCampaign] = useState(false);

const handleShowMasterCampaign = () => {

   showMasterCampaign == false ? setShowMasterCampaign(true) : setShowMasterCampaign(false);
}


const [avalue, setValue] = useState('');
const handleChange = (event, newValue) => {

   setValue(newValue);
 };



const getPeriods = (sdate,edate) =>{
//  console.log(data[5]+"//"+sdate+"--" + edate,"check p data");

  
  setHglobaltbl(false);
  
  const campaign_id = campaign_id_data
   
  const endpoint = process.env.REACT_APP_BASE_URL + "getGlobalData" ;
  setPtloading(true);
   Api(endpoint,"POST",  { id:campaign_id, start_date_period:sdate, end_date_period:edate})
   .then(result => {

   //   console.log(result.data,"check data fetching");
       setPtloading(false);
      setDataTableDays(result.data.days);
      setDataTable(result.data.res[0]);

     
      }).catch((err)=>console.log(err))


   
   
   const endpointa = process.env.REACT_APP_BASE_URL +  "getDailyData";
   setDloading(true);
   Api(endpointa,"POST",{ id:campaign_id, start_date_period:sdate, end_date_period:edate})
   .then(result => {

   
      
      setDloading(false);

      setDailyTable(result.data.res);
      
   }).catch((err)=>console.log(err));
}


const getGlobalData = () => {


   setPfloading(true);
   setHglobaltbl(true);
   setPgloading(true);

   const campaign_id = campaign_id_data

   const endpoint = process.env.REACT_APP_BASE_URL +  "performanceControlData?id="+campaign_id;
   Api(endpoint,"POST")
   .then(result => {

     setPerformanceGlobalData(result.data);
     setPfloading(false);
       
   }).catch((err)=>console.log(err));




   const endpointa = process.env.REACT_APP_BASE_URL + "GlobalPeriodsData?id="+campaign_id;
   Api(endpointa,"POST")
   .then(result => {


      setPeriodsGlobalData(result.data);
      setPgloading(false);


         
   }).catch((err)=>console.log(err));

}



const performance_columns = [
   
   {
       name: "periods",
       label: "Period",
       options: {
        filter: false,
        sort: true,
        filterType:'multiselect',
        
       
       }
      },
"Spend",
{
  name: "app_engagement",
  label: "App Engagement",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display: goal_data === "APP Engagement" ? true : false

  }
 },
 {
  name: "app_installs",
  label: "App Installs",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display: goal_data === "APP installs" ? true : false
  }
 },
 {
  name: "cpa",
  label: "CPA",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display: opt_kpi_data == "CPA" ? true : false

  }
 }, 
 {
  name: "cpc",
  label: "CPC",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
  display: opt_kpi_data == "CPC" || sec_kpi_data == "CPC"  ? true : false

  }
 }, 
 {
  name: "cpm",
  label: "CPM",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display:opt_kpi_data == "CPM" ? true : false

  }
 }, 
 {
  name: "cpv",
  label: "CPV",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display:opt_kpi_data == "CPV" ? true : false

  }
 }, 
 {
  name: "ctr",
  label: "CTR",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display:false

  }
 }, 
 {
  name: "clicks",
  label: "CLICKS",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display: goal_data === "Clicks" ? true : false


  }
 }, 
 {
  name: "conversion_rate",
  label: "Conversion Rate",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display:false

  }
 }, 

 {
  name: "conversion_value",
  label: "Conversion Value",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display: goal_data === "Conversion value" ? true : false

  }
 }, 
 {
  name: "conversion",
  label: "Conversion",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display: goal_data === "Conversions" ? true : false

  }
 }, 
 {
  name: "is_lost_by_budget",
  label: "Is Lost By Budget",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display:false

  }
 }, 
 {
  name: "is_lost_by_rank",
  label: "Is Lost By Rank",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display:false

  }
 }, 
 {
  name: "impression_share",
  label: "Impression Share",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display: goal_data == "Impression Share" ? true : false

  }
 }, 
 {
  name: "impressions",
  label: "Impressions",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display: goal_data == "Impressions" ? true : false

  }
 }, 
 {
  name: "maximise_conversion_value",
  label: "Maximise Conversion Value",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display: goal_data == "Maximise conversion value" ? true : false

  }
 }, 
 {
  name: "target_roas",
  label: "Target ROAS",

  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display: opt_kpi_data == "Target ROAS" ? true : false

  }
 }, 
 {
  name: "view_rate",
  label: "View Rate",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display:false

  }
 }, 
 {
  name: "views",
  label: "Views",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display:goal_data === "Views" ? true : false

  }
 }, 
 {
  name: "spend",
  label: "Spend",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
   display:false

  }
 }, 
 {
   name: "cost_per_install",
   label: "Cost Per Install",
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display:opt_kpi_data === "Cost_per_install" ? true : false

   }
  }, 
];


const performance_data = performanceGlobalData.map((a,b)=> { 
 
    // console.log(a,"check a");

     return([
      a.name, 
      a.res[0].spend,
      a.res[0].APP_Engagement,
      a.res[0].APP_installs,
      a.res[0].CPA,
      a.res[0].CPC,
      a.res[0].CPM,
      a.res[0].CPV,
      a.res[0].CTR,
      a.res[0].Clicks,
      a.res[0].ConversionRate,
      a.res[0].Conversion_value,
      a.res[0].Conversions,
      a.res[0].IS_Lost_by_Budget,
      a.res[0].IS_Lost_by_Rank,
      a.res[0].Impression_Share,
      a.res[0].Impressions,
      a.res[0].Maximise_conversion_value,
      a.res[0].Target_ROAS,
      a.res[0].ViewRate,
      a.res[0].Views,
      a.res[0].spend,
      a.res[0].Cost_per_install

      ])

});
   

const performance_options = {
   search: true,
   download: true,
   print: true,
   viewColumns: true,
   filter: false,
   filterType: 'multiselect',
   tableBodyHeight,
   tableBodyMaxHeight,
   selectableRows: 'none',
   sortOrder: {
       name: 'day',
       direction: 'desc'
   },
   responsive: "standard",
   fixedHeader:true,
   fixedSelectColumn:false,
  
    
};


const pglobal_columns = [
   
   {
       name: "periods",
       label: "Period",
       options: {
        filter: false,
        sort: true,
        filterType:'multiselect',
        
       
       }
      },
"Days",
"Spend",
 {
  name: "estimated_budget",
  label: "Estimated Budget",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
  
  }
 },
 {
  name: goal_data,
  label: goal_data,
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
 

  }
 }, 
 {
  name: "estimated_goal_kpi",
  label: "Estimated "+goal_data,
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
 

  }
 }, 
 {
   name: opt_kpi_data,
   label:opt_kpi_data,
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
  
 
   }
  }, 

  {
   name: 'estimated_optimization_kpi',
   label:'Estimated ' + opt_kpi_data,
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
  
 
   }
  }, 
  {
   name: sec_kpi_data,
   label:sec_kpi_data,
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: sec_kpi_data === "" ? false : true
 
   }
  }, 

  {
   name: 'estimated_secondary_kpi',
   label:'Estimated ' + sec_kpi_data,
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: sec_kpi_data === "" ? false : true
 
   }
  }, 


];



const pglobal_data = periodsGlobalData.map((a,b)=> { 
 
   // console.log(a,"check a");data

    const pgoal = goal_data.replace(/ /g,"_");
    const pokpi = opt_kpi_data.replace(/ /g,"_");
    const pskpi = sec_kpi_data.replace(/ /g,"_");


    //estimated conversion value  = estimated goal, target roars = estiamed_optimization in periods 
    return([
     a.name, 
     a.days.total_days,
     a.res[0].spend,
     a.info.periods_info[b].total_budget,
     a.res[0][pgoal],
     a.info.periods_info[b].estimated_goal,
     a.res[0][pokpi],
     a.info.periods_info[b].estimated_optimaization,
     a.res[0][pskpi],
     a.info.periods_info[b].estimated_secondary_optimaization,
   
   
     ])

});
  

const pglobal_options = {
   search: true,
   download: true,
   print: true,
   viewColumns: true,
   filter: false,
   filterType: 'multiselect',
   tableBodyHeight,
   tableBodyMaxHeight,
   selectableRows: 'none',
   sortOrder: {
       name: 'day',
       direction: 'desc'
   },
   responsive: "standard",
   fixedHeader:true,
   fixedSelectColumn:false,
    
};


const checkedCampaign = () => {

   let isExecuted = window.confirm("Do you confirmed you checked this campaign?");

   setCheckCampaign(true);

   if(isExecuted){
         let user = JSON.parse(localStorage.getItem('user_details'));    
         const endpoint = process.env.REACT_APP_BASE_URL + "subCampaign/extractAction";

         Api(endpoint,"POST", 
         { 

            "action": "checked",
            "campaignID": campaign_id_data,
            "user": user.email

         })
         .then(result => {

            
            setCheckCampaign(false);
          
         
         
         }).catch((err)=>console.log(err))

   }  


}

return (
    <>

   
    <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
    <div className="projects">

    <div style={{fontSize:12, margin:5, display:'flex', justifyContent:'right'}}>Last updated at: {lastUpdate ? moment.utc(new Date(lastUpdate)).local().format("YYYY-MM-DD HH:mm:ss") : 'loading..' }</div>
   
    <div className="card" style={{padding:'20px'}}>

        <div style={{backgroundColor:'#4169e1', borderRadius:50,  display:'flex', justifyContent:'space-between' , padding:10,}}>
        
        <span style={{fontSize:15, paddingLeft:5, color:'white'}}><b>Campaign  Details</b></span>
        <span  onClick={check} className="las la-arrow-alt-circle-left" style={{fontSize:25, cursor:'pointer', color:'white'}}></span> 
       
      

        </div>

      


    
     {/** Sub Campaign*/}



     <div style={{display:'flex',flexDirection:'column',alignContent:'center', borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15,  }} onClick={handleShowCampaign}>
        
        <div style={{backgroundColor:'#D9EAF7',color:'black', padding:10, marginBottom:5, display:'flex', flexDirection:'row',justifyContent:'space-between' }}> 

         <div>
            <b>Sub Campaign: </b><span style={{fontSize:'15px'}}>&nbsp;&nbsp; {sub_campaign_full_name_data} </span> 

         </div>

         <div>
           <b> Start Date:</b> { moment.utc(new Date(start_date_data)).local().format("YYYY-MM-DD")}  | <b> End Date:</b> { moment.utc(new Date(end_date_data)).local().format("YYYY-MM-DD")}
           <span style={{paddingTop:50, paddingLeft:20, color:'#4169e1'}} >
                {showCampaign ?
                 <KeyboardDoubleArrowDownIcon /> 
                : 
                 <KeyboardDoubleArrowLeftIcon />
                }
                </span>
         </div>
         
                                                             
        </div>

       {showCampaign ?
        <div>           
        <table id="test" width="100%" style={{border:'1px solid lightgray', backgroundColor:'white',}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div>
                                                                  <table style={{width:'100%',border:'1px solid lightgray', height:'200px', overflowY:'scroll'}}>
                                                                    
                                                                     <tbody>

                                                                        


                                                                   
                                                                       <tr style={{border:'1px solid lightgray'}}>
                                                                        <td style={{border:'1px solid lightgray',width:'15%'}}><b>Advertiser</b> </td>
                                                                        <td style={{border:'1px solid lightgray',width:'35%'}}>{client_data}</td>
                                                                        <td style={{border:'1px solid lightgray',width:'15%'}}><b>Campaign Type</b> </td>
                                                                        <td style={{border:'1px solid lightgray',width:'35%'}}>{campaign_type_data}</td>
                                                                       </tr>

                                                                       <tr style={{border:'1px solid lightgray'}}>
                                                                        <td style={{border:'1px solid lightgray',width:'15%'}}><b>Campaign Owner</b> </td>
                                                                        <td style={{border:'1px solid lightgray',width:'35%'}}>{campaign_owner_data}</td>
                                                                        <td style={{border:'1px solid lightgray',width:'15%'}}><b>Optimization KPI </b></td>
                                                                        <td style={{border:'1px solid lightgray',width:'35%'}}>{opt_kpi_data}</td>
                                                                       </tr>

                                                                       <tr style={{border:'1px solid lightgray'}}>
                                                                        <td style={{border:'1px solid lightgray',width:'15%'}}><b>Platform</b> </td>
                                                                        <td style={{border:'1px solid lightgray',width:'35%'}}>{platform_data}</td>
                                                                        <td style={{border:'1px solid lightgray',width:'15%'}}><b>Secondary KPI</b> </td>
                                                                        <td style={{border:'1px solid lightgray',width:'35%'}}>{sec_kpi_data  ? sec_kpi_data : 'Not Present'}</td>
                                                                       </tr>

                                                                       <tr style={{border:'1px solid lightgray'}}>
                                                                        <td style={{border:'1px solid lightgray',width:'15%'}}><b>Goal</b> </td>
                                                                        <td style={{border:'1px solid lightgray',width:'35%'}}>{goal_data}</td>
                                                                        <td style={{border:'1px solid lightgray',width:'15%'}}><b>Team Leader</b> </td>
                                                                        <td style={{border:'1px solid lightgray',width:'35%'}}>{team_leader_data}</td>
                                                                       </tr>

                                                                       <tr style={{border:'1px solid lightgray'}}>
                                                                        <td style={{border:'1px solid lightgray',width:'15%'}}><b>Campaign Backup</b> </td>
                                                                        <td style={{border:'1px solid lightgray',width:'35%'}}>{campaign_backup_data}</td>
                                                                        <td style={{border:'1px solid lightgray',width:'15%'}}><b>Performance Manager </b></td>
                                                                        <td style={{border:'1px solid lightgray',width:'35%'}}>{performance_manager_data}</td>
                                                                       </tr>

                                                                  
                                                                       <tr>
                                                                        <td  style={{border:'1px solid lightgray',width:'15%'}}>

                                                                          <b> Days Off</b>

                                                                        </td>

                                                                        <td colSpan={3}>
                                                                           {
                                                                          
                                                                           days_off.map((a,b)=>{return(
                                                                              

                                                                              a.days_off.length > 0 ?
                                                                              a.days_off.map((c,d)=>{
                                                                                 
                                                                                 
                                                                                 return(

                                                                                 c.date ?
                                                                                 <span style={{float:"left", margin:5, border:'1px solid red', padding:5}}>

                                                                                    {c.date ? c.date : ''}

                                                                                 </span>
                                                                                 : ''

                                                                                  )
                                                                           
                                                                           
                                                                               }) : ''

                                                                           
                                                                           )

                                                                            

                                                                             

                                                                           })
                                                                        
                                                                           }
                                                                           


                                                                        </td>
                                                                       </tr>

                                                                        
                                                                      
                                                                   
                                                                      
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
        </div>
       :''}

      


        </div>

    
  {/**Sub Campaign */}
                

{/** Notes */}

{notes_data?
<div style={{display:'flex',flexDirection:'column',alignContent:'center', borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15}}>
        
        <div style={{backgroundColor:'#D9EAF7',color:'black', padding:10, marginBottom:5, display:'flex', flexDirection:'row',justifyContent:'space-between' }}> 

       <b> Campaign Notes </b>

       </div>

       <div style={{border:'1px solid lightgray', backgroundColor:'white', padding:10}}>

         {notes_data}

       </div>
      
</div>
:''}

{/** Data Variation */}
<div style={{display:'flex',flexDirection:'column',alignContent:'center', borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15}}>
        
        <div style={{backgroundColor:'#D9EAF7',color:'black', padding:10, marginBottom:5, display:'flex', flexDirection:'row',justifyContent:'space-between' }}> 

       <b> Data Variation </b>

       </div>

       <div style={{border:'1px solid lightgray', backgroundColor:'white', padding:10}}>
       {vdLoading ? <> <LinearProgress  /> <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center'}}> loading data ..... Please Wait..</div> </> : 
                                                    <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div >
                                                                  <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'20%', fontWeight:'900' }}>1. Budget Daily Estimate </td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>2. Real Daily Average</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>3. Variation Between 1 and 2</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>4. Yesterday's Spend</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>5. Variation Between 2 and 4</td>
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>
                                                                    

                                                                    
                                                                   
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',}}>{ variationDataBudget.daily_estimate }</td>           
                                                                           <td style={{border:'1px solid lightgray',width:'20%'  }}>{ variationDataBudget.daily_average}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ variationDataBudget.variation_total}</td>
                                                                           <td style={{border:'1px solid lightgray', }}> { variationDataBudget.yesterday_spend} </td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ variationDataBudget.variation_yesterday}</td>
                                                                        </tr>
                                                                       

                                                                                                
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
                                                }

                                                   <br/>
                                                   <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div >
                                                                  <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'20%', fontWeight:'900' }}>1. Daily {goal_data}  Estimate </td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>2. Real Daily Average</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>3. Variation Between 1 and 2</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>4. Yesterday's {goal_data}</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>5. Variation Between 2 and 4</td>
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                   
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                        <td style={{border:'1px solid lightgray',}}>{ variationDataGoal.daily_estimate }</td>           
                                                                           <td style={{border:'1px solid lightgray',width:'20%'  }}>{ variationDataGoal.daily_average}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ variationDataGoal.variation_total}</td>
                                                                           <td style={{border:'1px solid lightgray', }}> { variationDataGoal.yesterday_spend} </td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ variationDataGoal.variation_yesterday}</td>
                                                                        </tr>
                                                                       

                                                                                                
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>

                                                   <br/>
                                                   <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div >
                                                                  <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                     <td style={{border:'1px solid lightgray',width:'20%', fontWeight:'900' }}>1. Daily {opt_kpi_data}  Estimate </td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>2. Real Daily Average</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>3. Variation Between 1 and 2</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>4. Yesterday's {opt_kpi_data}</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>5. Variation Between 2 and 4</td>
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                   
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                        <td style={{border:'1px solid lightgray',}}>{ variationDataOptKpi.daily_estimate }</td>           
                                                                           <td style={{border:'1px solid lightgray',width:'20%'  }}>{ variationDataOptKpi.daily_average}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ variationDataOptKpi.variation_total}</td>
                                                                           <td style={{border:'1px solid lightgray', }}> { variationDataOptKpi.yesterday_spend} </td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ variationDataOptKpi.variation_yesterday}</td>
                                                                        </tr>
                                                                       

                                                                                                
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>

                                                   <br/>
                                                
                                                   {sec_kpi_data ?
                                                   <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div >
                                                                  <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'20%', fontWeight:'900' }}>1. Budget {sec_kpi_data}  Estimate  </td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>2. Real Daily Average</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>3. Variation Between 1 and 2</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>4. Yesterday's {sec_kpi_data}</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>5. Variation Between 2 and 4</td>
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                   
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                        <td style={{border:'1px solid lightgray',}}>{ variationDataSecKPI.daily_estimate }</td>           
                                                                           <td style={{border:'1px solid lightgray',width:'20%'  }}>{ variationDataSecKPI.daily_average}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ variationDataSecKPI.variation_total}</td>
                                                                           <td style={{border:'1px solid lightgray', }}> { variationDataSecKPI.yesterday_spend} </td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ variationDataSecKPI.variation_yesterday}</td>
                                                                        </tr>
                                                                       

                                                                                                
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
                                                   :''}
                                             

       </div>

      
      
</div>


   
{/** */}


   {/** Data Table */}
  
  { mode_data === "continuous" ?

   <div style={{display:'flex',flexDirection:'column',alignContent:'center',  borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15}}>
        
        <div style={{backgroundColor:'#D9EAF6', padding:10, marginBottom:5}}> 
            <b>Data Table </b>
                                                             
        </div>


        <div>  

        {dtLoading ? <> <LinearProgress  /> <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center'}}> loading data ..... Please Wait..</div> </>:   
        <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div >
                                                                  <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'20%', fontWeight:'900' }}></td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Spent/Achieved (Until Today)</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Left Until Total</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Left Per Day</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Total/Goal</td>
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                   
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',}}><b>Days</b></td>

                                                                           
                                                                           <td style={{border:'1px solid lightgray',width:'20%'  }}>{dataTableDays.daysdone ? dataTableDays.daysdone.toLocaleString().replace(/,/g," ",).replace('.',',') : ''}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{dataTableDays.daysleft ? dataTableDays.daysleft.toLocaleString().replace(/,/g," ",).replace('.',',') : dataTableDays.daysleft}</td>
                                                                           <td style={{border:'1px solid lightgray', }}> ------- </td>
                                                                           <td style={{border:'1px solid lightgray', }}>{dataTableDays.total_days ? dataTableDays.total_days.toLocaleString().replace(/,/g," ",).replace('.',',') : '' }</td>
                                                                        </tr>
                                                                       

                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                         <td style={{border:'1px solid lightgray',}}><b>Budget</b></td>
                                                                         <td style={{border:'1px solid lightgray',width:'20%'  }}>{dataTable.spend ? dataTable.spend.toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',') : '' } €</td>
                                                                         <td style={{border:'1px solid lightgray', }}>{ (total_budget_data - dataTable.spend).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',')  } €</td>
                                                                         <td style={{border:'1px solid lightgray', }}>{isFinite((total_budget_data - dataTable.spend / dataTableDays.daysleft).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',') ) ? ((total_budget_data - dataTable.spend) / dataTableDays.daysleft).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',')  : 0 } €</td>
                                                                         <td style={{border:'1px solid lightgray', }}>{total_budget_data.toLocaleString().replace(/,/g," ",).replace('.',',') } €</td>
                                                                      </tr>
                                                                     


                                                                      <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                         <td style={{border:'1px solid lightgray',}}><b>{goal_data}</b></td>
                                                                         <td style={{border:'1px solid lightgray',width:'20%'  }}>{parseFloat(dataTable[goal_data.replace(/ /g,"_")]).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',') } {goal_data == "Impression Share" ? '%': ''} <b></b></td>
                                                                         <td style={{border:'1px solid lightgray', }}>{parseFloat(esitmated_goal_data - dataTable[goal_data.replace(/ /g,"_")]).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',') } {goal_data == "Impression Share" ? '%': ''}</td>
                                                                         <td style={{border:'1px solid lightgray', }}>{isFinite((( esitmated_goal_data - dataTable[goal_data.replace(/ /g,"_")]) /  dataTableDays.daysleft).toLocaleString().replace(/,/g," ",).replace('.',',') ) ? (( esitmated_goal_data - dataTable[goal_data.replace(/ /g,"_")]) /  dataTableDays.daysleft ).toFixed(2).toLocaleString().replace(/,/g," ",).replace('.',',')  : 0 } {goal_data == "Impression Share" ? '%': ''}</td>
                                                                         <td style={{border:'1px solid lightgray', }}>{ esitmated_goal_data.toLocaleString().replace(/,/g," ",).replace('.',',') } {goal_data == "Impression Share" ? '%': ''} </td>
                                                                      </tr>

                                                                      
                                                                     


                                                                                                                                       
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
                                                    }
        </div>

        <div style={{ margin:20}}>
         <Button  onClick={checkedCampaign} className={checkCampaign ? 'btnDisabled' : 'btnEnabled' } disabled={checkCampaign ? true : false} > 
          {checkCampaign ? 'Already Checked': 'Check Campaign ' }
         </Button>
       </div>
       
  {/** Daily Table Continuous */}
  <div style={{backgroundColor:'#D9EAF6', padding:10, marginBottom:5, marginTop:10, width:'100%'}}> 
            <b>Daily Table </b>
                                                             
        </div>


        <div>  
        {dLoading ? <> <LinearProgress  />
        
       <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center', overflow:'hidden' }}> loading data ..... Please Wait..</div> </>:          
        <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white',}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                           

                                                                <CacheProvider value={muiCache}>
                                                                        <ThemeProvider theme={getMuiTheme()}>
                                                                        
                                                                            <MUIDataTable
                                                                             //title={"Campaign Information"}   
                                                                            data={daily_data}
                                                                            columns={daily_columns}
                                                                            options={daily_options}
                                                                           
                                                                            />
                                                                        </ThemeProvider>
                                                                        </CacheProvider>
                                                             
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
            }
        </div>

         {/** Daily Table Continuous */}


        </div>

      :
      
      <div style={{display:'flex',flexDirection:'column',alignContent:'center',  borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15}}>
        
      <div style={{backgroundColor:'white'}}>   


   

      <TabContext value={avalue ? avalue : currentPeriod}>
         
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList variant="scrollable" onChange={handleChange} style={{backgroundColor:'#D9EAF6', padding:10, marginBottom:5, overflow:'scroll'}}>
          <Tab label="Global" value="global"  onClick={getGlobalData}  /> 
       
           { periods_info_data .map((a,b)=>{

            const test = "P"+ (b + 1) ;
            const label =  " ( " + a.start_date + " / "+ a.end_date + " ) " ;
           
            return(
            
            <Tab label={test + label} value={b+1} onClick={(e) => getPeriods(a.start_date,a.end_date) }   />
            
         
           )})}
         
          
       

          </TabList>
          
        </Box>
        
        { periods_info_data .map((c,d)=>{
         return(
        <TabPanel value={d + 1}>
        <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
        {ptLoading ? <LinearProgress  /> : '' }
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div >
                                                                  <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                                 
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'20%', fontWeight:'900' }}></td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Spent/Achieved (Until Today)</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Left Until Total</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Left Per Day</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Total/Goal</td>
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                   
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',}}><b>Days</b></td>

                                                                           
                                                                           <td style={{border:'1px solid lightgray',width:'20%'  }}>{dataTableDays.daysdone}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{dataTableDays.daysleft}</td>
                                                                           <td style={{border:'1px solid lightgray', }}> ------- </td>
                                                                           <td style={{border:'1px solid lightgray', }}>{parseFloat(dataTableDays.total_days).toLocaleString('en-PT',{minimumFractionDigits: 0, maximumFractionDigits: 0}).replace(/,/g," ",).replace('.',',')}</td>
                                                                        </tr>
                                                                       

                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                         <td style={{border:'1px solid lightgray',}}><b>Budget</b></td>
                                                                         <td style={{border:'1px solid lightgray',width:'20%'  }}>{parseFloat(dataTable.spend).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',')} €</td>
                                                                         <td style={{border:'1px solid lightgray', }}>{ parseFloat( periods_info_data [d].total_budget - dataTable.spend ).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',')} €</td>
                                                                         <td style={{border:'1px solid lightgray', }}>{isFinite(parseFloat(( periods_info_data [d].total_budget  - parseFloat(dataTable.spend) / dataTableDays.daysleft))) ? parseFloat(( periods_info_data [d].total_budget  - dataTable.spend) / dataTableDays.daysleft).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',') : 0 } €</td>
                                                                         <td style={{border:'1px solid lightgray', }}>{ parseFloat(periods_info_data[d].total_budget).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',')} €</td>
                                                                      </tr>
                                                                     


                                                                      <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                         <td style={{border:'1px solid lightgray',}}><b>{goal_data}</b></td>
                                                                         <td style={{border:'1px solid lightgray',width:'20%'  }}>{parseFloat(dataTable[goal_data.replace(/ /g,"_")]).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',')} {goal_data == "Impression Share" ? '%': ''}</td>
                                                                         <td style={{border:'1px solid lightgray', }}>{parseFloat( periods_info_data [d].estimated_goal  - dataTable[goal_data.replace(/ /g,"_")]).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',')} {goal_data == "Impression Share" ? '%': ''}</td>
                                                                         <td style={{border:'1px solid lightgray', }}>{isFinite(( periods_info_data [d].estimated_goal  - dataTable[goal_data.replace(/ /g,"_")]) /  dataTableDays.daysleft) ? parseFloat( ( periods_info_data [d].estimated_goal  - dataTable[goal_data.replace(/ /g,"_")]) /  dataTableDays.daysleft ).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',') : 0 } {goal_data == "Impression Share" ? '%': ''}</td>
                                                                         <td style={{border:'1px solid lightgray', }}>{ parseFloat(periods_info_data [d].estimated_goal).toLocaleString('en-PT',{minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g," ",).replace('.',',') } {goal_data == "Impression Share" ? '%': ''}</td>
                                                                      </tr>
                                                                     


                                                                    
                                                                     
                                                                      
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
        </TabPanel>
        )})}



        <TabPanel value="global">
        {pfLoading ? <LinearProgress  /> : '' }
                     <CacheProvider value={pmuiCache}>
                            <ThemeProvider theme={getMuiTheme()}>
                                                                        
                                                                            <MUIDataTable
                                                                            title={"Performance Control"}   
                                                                            data={performance_data}
                                                                            columns={performance_columns}
                                                                            options={performance_options}
                                                                           
                                                                            />
                            </ThemeProvider>
                       </CacheProvider>

                  <br/>

                       <CacheProvider value={gmuiCache}>
                            <ThemeProvider theme={getMuiTheme()}>
                                                                        
                                                                            <MUIDataTable
                                                                            title={"Global Table"}   
                                                                            data={pglobal_data}
                                                                            columns={pglobal_columns}
                                                                            options={pglobal_options}
                                                                           
                                                                            />
                            </ThemeProvider>
                       </CacheProvider>
                                                             



        </TabPanel>
      </TabContext>
   
                                      
        </div>
        <div style={{ margin:20}}>
         <Button  onClick={checkedCampaign} className={checkCampaign ? 'btnDisabled' : 'btnEnabled' } disabled={checkCampaign ? true : false} > 
          {checkCampaign ? 'Already Checked': 'Check Campaign ' }
         </Button>
       </div>

  {/** Daily Table Continuous */}

  {hGlobalTbl ? '' :
  <>
        <div style={{backgroundColor:'#D9EAF6', padding:10, marginBottom:5, marginTop:10, width:'100%'}}> 
            <b>Daily Table  </b>
                                                             
        </div>


        <div>  
        {dLoading ? <> <LinearProgress  />
        
       <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center', overflow:'hidden' }}> loading data ..... Please Wait..</div> </>:          
        <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white',}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                           

                                                                <CacheProvider value={muiCache}>
                                                                        <ThemeProvider theme={getMuiTheme()}>
                                                                        
                                                                            <MUIDataTable
                                                                            //title={"Campaign Information"}   
                                                                            data={daily_data}
                                                                            columns={daily_columns}
                                                                            options={daily_options}
                                                                           
                                                                            />
                                                                        </ThemeProvider>
                                                                        </CacheProvider>
                                                             
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
            }
        </div>
             </>
          }
         {/** Daily Table Continuous */}


        </div>
      
      
      }

      {/**Data Table */}



       {/** Comments */}


    <div style={{display:'flex',flexDirection:'column',alignContent:'center', borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15}} >
        
        <div style={{backgroundColor:'#D9EAF6', padding:10,paddingTop:15, marginBottom:5}}> 
            <b>Comments </b>
            <span style={{float:'right',color:'#4169e1'}} >
                {showComments ?
                <KeyboardDoubleArrowDownIcon onClick={handleShowComments} /> 
                :  <KeyboardDoubleArrowLeftIcon  onClick={handleShowComments} />  }
                
                </span>
                                                             
        </div>


        <div style={{ backgroundColor:'aliceblue', }}>
      
       {showComments ? 
       <FormControl variant="outlined"  fullWidth style={{marginTop:2,marginBottom:25, backgroundColor:'white'}}>
                        <TextField variant='outlined' fullWidth multiline minRows={15}
                     
                                id="demo-simple-select"
                                value={comments}
                                onChange={(e) => {setComments(e.target.value); setCommentsVal(false);}}
                                label={commentsVal ? <span style={{color:'red'}}>Please Add Comments</span> : "Add Comments"} 
                                
                                error={commentsVal}
                            
                />
            
         <Button { ...cLoading ?  'disabled' : '' } onClick={addComments} style={{backgroundColor:'#4169e1',color:'white', marginTop:5}} >{ cLoading ?  <CircularProgress style={{color:'white'}} size="1.5rem" /> : 'Submit'}</Button>
        </FormControl>
        :''}
       

        <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                          

                                                                <CacheProvider value={cmuiCache}>
                                                                        <ThemeProvider theme={createTheme()}>
                                                                        
                                                                       <MUIDataTable
                                                                            // title={"Campaign Information"}   
                                                                            data={comments_data}
                                                                            columns={comments_columns}
                                                                            options={comments_options}
                                                                            
                                                                            
                                                                            /> 
                                                                        </ThemeProvider>
                                                                        </CacheProvider>
                                                             
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
   
    </div>


       


        </div>

      {/** Daily Table Continuous */}

       {/**Campaign Hireacrchy */}




       <div style={{alignContent:'center', marginTop:20, backgroundColor:'aliceblue', borderRadius:50, padding:30, fontSize:15}} onClick={handleShowMasterCampaign}>
        
        <div style={{display:'flex', justifyContent:'space-between', backgroundColor:'#D9EAF6',color:'black', padding:10, marginBottom:5}}> 
           <div><b>Master Campaign: </b><span style={{fontSize:'15px'}}> {master_campaign_name_data.replace(/ ..../g," ",)}</span> </div> 

            <div>
            <span style={{paddingTop:50, paddingLeft:20,color:'#4169e1'}} >
                {showMasterCampaign ?
                <KeyboardDoubleArrowDownIcon /> 
                :  <KeyboardDoubleArrowLeftIcon />  }
                </span>
                </div>
           
                                                         
        </div>

       

       {showMasterCampaign ?
       <>
        <div>           
        <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div style={{height:'200px', overflow:'auto'}}>

                                                               {mLoading ? <> <LinearProgress  /> <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center'}}> loading data ..... Please Wait..</div> </>: 
                                                                  <table style={{width:'100%',border:'1px solid lightgray', height:'200px', overflowY:'scroll'}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'60%', fontWeight:'900' }}>Mediafy Campaign</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Start Date</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>End Date</td>
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                     

                                                                      {mediafyCampaign.map((key,index)=>{
                                                                        return(                                                                   
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'60%'}}>{key.sub_campaign_name}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ moment.utc(new Date(key.start_date)).local().format("YYYY-MM-DD")}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ moment.utc(new Date(key.end_date)).local().format("YYYY-MM-DD")}</td>
                                                                        </tr>
                                                                      
                                                                      )})}
                                                                     </tbody>
                                                                  </table>
                                                               }
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
        </div>
       
        <div>

        <table width="100%" style={{border:'1px solid lightgray',  backgroundColor:'white', marginTop:5}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div style={{height:'200px', overflow:'auto'}}>

                                                               
                                                                  <table style={{width:'100%',border:'1px solid lightgray', height:'200px', overflowY:'scroll'}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'60%', fontWeight:'900' }}>Platform Campaign</td>
                                                                          
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                        {campaign_data.map((key,index)=>{

                                                                         

                                                                          
                                                                            return(

                                                                                <tr  style={{border:'1px solid lightgray', height:'5%'}} key={index}>
                                                                                    
                                                                                    <td style={{border:'1px solid lightgray',width:'60%' }}>{key.name}</td>
                                                                                    
                                                                                </tr>

                                                                            )
                                                                        })}
                                                                       
                                                                       
                                                                      
                                                                     </tbody>
                                                                  </table>
                                                               
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>

        </div>
        </>

         : '' }
                
        </div>

    
  {/**Campaign Hireacrchy */}

   {/** 

        <div style={{backgroundColor:'#f0f8ff', padding:10, marginTop:10}}>
      

        <table width="100%" style={{border:'1px solid lightgray'}}>
                                                      <tbody >
                                                         <tr>
                                                            <td>
                                                               <i ></i>
                                                               <b>Total Campaings <span style={{fontSize:'15px'}}>1212</span> </b>
                                                               <span style={{float:'right'}} ><b>Start Date:</b>444 |  <b>End Date:</b>  43434</span>
                                                            </td>                                                            
                                                         </tr>
                                                         <tr >
                                                            <td>
                                                               <div style={{height:'200px', overflow:'auto'}}>
                                                                  <table style={{width:'100%',border:'1px solid lightgray', height:'200px', overflowY:'scroll'}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                           <td style={{border:'1px solid lightgray', }}>#</td>
                                                                           <td style={{border:'1px solid lightgray',width:'60%', fontWeight:'900' }}>Campaign Name</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Stard Date</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>End Date</td>
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                    
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                           <td style={{border:'1px solid lightgray', }}>fasdasf</td>
                                                                           <td style={{border:'1px solid lightgray',width:'60%' }}>fsdasfa</td>
                                                                           <td style={{border:'1px solid lightgray', }}>fsafa</td>
                                                                           <td style={{border:'1px solid lightgray', }}>afsd</td>
                                                                        </tr>
                                                                     
                                                                      
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>

        </div>
   */}

   {/** <div>{data.map((a,b)=>{return(<div>{a}</div>)})}</div> */}


    </div>
    </div>


    </>
  )
}

export default ListingDetails
import React from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback,} from '@okta/okta-react';
import Login from './components/Login';
import { Logout } from './components/Login/logout';
import AddCampaign from './components/Campaign/addCampaign';
import MasterCampaign from './components/MasterCampaign';
import Dashboard from './components/Dashboard';
import Archive from './components/Dashboard/archive';
import Backup from './components/Dashboard/backup';
import MasterArchive from './components/MasterCampaign/archive';
import SubCampaignListing from './components/Dashboard/subCampaignListing';
import MasterCampaignListing from './components/MasterCampaign/masterCampaignListing';

const oktaAuth = new OktaAuth({
 

   issuer: process.env.REACT_APP_OKTA_ISSUER, 
   clientId: process.env.REACT_APP_OKTA_CLIENTID,
   redirectUri: window.location.origin + '/login/callback',
   scopes: ['openid', 'profile', 'email'],
   pkce: true,
   disableHttpsCheck: true,

   

});


const App = () => {

  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);

  const history = useHistory();

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
     
        <Switch>
          
          <Route path="/" exact component={Login} />
          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute path="/dashboard" component={Dashboard} />
          <SecureRoute path="/backup" component={Backup} />
          <SecureRoute path="/archive" component={Archive} />
          <SecureRoute path="/addCampaign" component={AddCampaign} />
          <SecureRoute path="/masterCampaign" component={MasterCampaign} />


          <SecureRoute path="/archiveMaster" component={MasterArchive} />

          <SecureRoute path="/subCampaignListing" component={SubCampaignListing} />
          <SecureRoute path="/masterCampaignListing" component={MasterCampaignListing} />         
        </Switch>
     
    </Security>

    
  );
};



export default App;

import React from 'react'

const Footer = () => {
  return (
     <div style={{fontSize:'10px', justifyContent:' center', alignItems:'center', display:'flex', padding:'10px'}}>Copyright Mediafy 2023, All Rights Reserved | Groupm Portugal <br/>
     
     </div> 
     
   
  )
}

export default Footer
import React, { useEffect, } from 'react'
import { useState } from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import moment from 'moment';
import Api from '../Service/network';
import LinearProgress from '@mui/material/LinearProgress';
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { Box,Tab,FormControl, InputLabel,Select,MenuItem, TextField, Checkbox, Button, TableCell, TableFooter, TableRow, TablePagination, makeStyles,} from '@material-ui/core';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { isObject } from '@okta/okta-auth-js';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import ReactSpeedometer from "react-d3-speedometer"

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser'



const modules = {
   toolbar: [
  
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic"],
      [{ color: [] }, { background: [] }],
      [{ script:  "sub" }, { script:  "super" }],
  
     
  ],
}

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
  });
  

  const pmuiCache = createCache({
    key: "mui-datatables",
    prepend: true
  });

  const gmuiCache = createCache({
   key: "mui-datatables",
   prepend: true
 });
 
  
  const cmuiCache = createCache({
    key: "mui-datatables",
    prepend: true
  });


  const amuiCache = createCache({
   key: "mui-datatables",
   prepend: true
 });

  
export const getMuiTheme = () =>
  createTheme({
   overrides: {
      MUIDataTable: {
        responsiveStacked: {
          maxHeight: 'none',
          overflowX:'auto'
        },
      },
    },
  });



const ListingDetails = ({check,data}) => {

//console.log(data,"recevice in listing details page buddy")
const [mediafyCampaign,setMediafyCampaign] = useState([]);
const[mLoading,setMloading] = useState(false);

const [platformCampaign,setPlatformCampaign] = useState([]);
const[campaignId, setCampaignId] = useState([]);
const[pLoading,setPloading] = useState(false);

const[campaignIdList, setCampaignIdList] = useState([]);

const [commentsVal, setCommentsVal] = useState(false);

const [showComments, setShowComments] = useState(true);

const [showDataVariation, setShowDataVariation] = useState(false);

const[dailyTable, setDailyTable] = useState([]);
const[dLoading,setDloading] = useState(false);
const[dailyTableMap, setDailyTableMap] = useState([]);
const[dailyTableResMap, setDailyTableResMap] = useState([]);

const[ptLoading,setPtloading] = useState(false);

const[dataTable, setDataTable] = useState([]);
const[dataTableDays, setDataTableDays] = useState([]);
const[dataTableBudget, setDataTableBudget] = useState([]);
const[dataTableGoal, setDataTableGoal] = useState([]);

const[dtLoading,setDtloading] = useState(false);

const[comments,setComments] = useState('');
const[cLoading,setCloading] = useState(false);

const[listComments,setListComments] = useState([]);
const[listActions,setListActions] = useState([]);

const[hGlobalTbl, setHglobaltbl] = useState(false);


const [responsive, setResponsive] = useState("standard");
const [tableBodyHeight, setTableBodyHeight] = useState("600px");
const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("700px");
const [searchBtn, setSearchBtn] = useState(false);
const [downloadBtn, setDownloadBtn] = useState(false);
const [printBtn, setPrintBtn] = useState(false);
const [viewColumnBtn, setViewColumnBtn] = useState(false);
const [filterBtn, setFilterBtn] = useState(false);
const[currentPeriod, setCurrentPeriod] = useState();

const[performanceGlobalData, setPerformanceGlobalData]  = useState([]);
const[performanceGlobalDataMap, setPerformanceGlobalDataMap]  = useState([]);
const[performanceGlobalDataResMap, setPerformanceGlobalDataResMap]  = useState([]);
const[pfLoading, setPfloading]  = useState(false);

const[periodsGlobalData, setPeriodsGlobalData]  = useState([]);
const[pgLoading, setPgloading]  = useState(false);


const[variationDataBudget, setVariationDataBudget]  = useState([]);
const[variationDataGoal, setVariationDataGoal]  = useState([]);
const[variationDataOptKpi, setVariationDataOptKpi]  = useState([]);
const[variationDataSecKPI, setVariationDataSecKPI]  = useState([]);
const[vdLoading, setVdloading]  = useState(false);

const[checkCampaign, setCheckCampaign] = useState();

const[lastUpdate, setLastUpdate] = useState();


const searchParams = new URLSearchParams(document.location.search);
const cid = searchParams.get('cid');
const campaign_id_data = cid ? cid : data;

const [campaign_data,set_campaign_data] = useState([]);
const [client_data,set_client_data] = useState();
const [master_campaign_id_data,set_master_campaign_id_data]= useState();
const [master_campaign_name_data,set_master_campaign_name_data]= useState();
const [campaign_type_data,set_campaign_type_data] = useState();
const [campaign_owner_data, set_campaign_owner_data] = useState();
const [opt_kpi_data,set_opt_kpi_data]= useState();
const [platform_data,set_platform_data] = useState();
const [sec_kpi_data,set_sec_kpi_data] = useState();
const [goal_data,set_goal_data] = useState();
const [team_leader_data,set_team_leader_data] = useState();
const [campaign_backup_data,set_campaign_backup_data] = useState();
const [performance_manager_data,set_performance_manager_data] = useState();
const [notes_data,set_notes_data] = useState();
const [mode_data,set_mode_data] = useState();

const [periods_info_data,set_periods_info_data] =  useState( []);
const [start_date_data,set_start_date_data] = useState();
const [end_date_data,set_end_date_data] = useState();
const [days_off,set_days_off] = useState([]);
const [sub_campaign_full_name_data,set_sub_campaign_full_name_data] = useState();

//const budget_data =  esitmated_goal_data;
//const budget_data =  periods_info_data ;

const history = useHistory();




useEffect(()=>{
  
   const endpoint = process.env.REACT_APP_BASE_URL + "subCampaign/"+ campaign_id_data;
   
   Api(endpoint,"GET")
   .then(res => {


      set_sub_campaign_full_name_data(res.data['sub_campaign_name']);
      set_mode_data(res.data['mode']);
      set_periods_info_data(res.data['periods_info']);
      set_master_campaign_id_data(res.data['master_campaign_id']);
      set_master_campaign_name_data(res.data['master_campaign_name']);
      set_campaign_data(res.data['campaigns']);
      set_notes_data(res.data['note']);

      set_client_data(res.data['Client']);
      set_campaign_owner_data(res.data['campaign_owner']);
      set_platform_data(res.data['platform']);
      set_goal_data(res.data['goal']);
      set_campaign_backup_data(res.data['campaign_backup'])
      set_campaign_type_data(res.data['campaign_type'])
      set_opt_kpi_data(res.data['opt_kpi'])
      set_sec_kpi_data(res.data['secondary_kpi'])
      set_team_leader_data(res.data['team_leader'])
      set_performance_manager_data(res.data['performance_manager'])

      set_start_date_data(res.data['start_date']);
      set_end_date_data(res.data['end_date']);

   })

},[campaign_id_data])

  
useEffect(()=>{


    //console.log(data[25],"main data");
  
    setDtloading(true);
    const campaign_id = campaign_id_data;
   
    const endpoint = process.env.REACT_APP_BASE_URL + "getGlobalData";
   
     Api(endpoint,"POST",{id: campaign_id})
     .then(result => {


        setDataTableDays(result.data.days);
        setDataTableBudget(result.data.budget);
        setBudgetBarColor(result.data.budget.percentage > 100 ? 'red' : '');
        setDataTableGoal(result.data.goal);
        setGoalBarColor(result.data.goal.percentage >= 100 ? 'green' : '');


        const nperiod = result.data.nperiod;
        
        setCurrentPeriod(nperiod);

        setDataTable(result.data);

        setDtloading(false);
       
        }).catch((err)=>console.log(err))
   
 },[campaign_id_data])


 useEffect(()=>{

   const campaign_id = campaign_id_data;
   const endpoint = process.env.REACT_APP_BASE_URL + "lastUpdate/"+campaign_id;
  
    Api(endpoint,"GET")

    .then(result => {

      setLastUpdate(result.data.res[0].last_update);

    }).catch((err)=>console.log(err))

 },[campaign_id_data])

 useEffect(()=>{

   const campaign_id = campaign_id_data;
   const endpoint = process.env.REACT_APP_BASE_URL + "subCampaign/actions/today/"+campaign_id;
  
    Api(endpoint,"GET")

    .then(result => {

      setCheckCampaign(result.data);

    }).catch((err)=>console.log(err))


 },[checkCampaign])

 const[ferreroLoading,setFerreroLoading] = useState(false);
const[brandSafety,setBrandSafety] = useState([]);
const[fraud,setFraud] = useState([]);
const[inGeo,setInGeo] = useState([]);
const[viewability,setViewability] = useState([]);

useEffect(() => {


   setFerreroLoading(true);
   console.log(client_data,"am i getting client here")

   const campaign_id = campaign_id_data;
   
   const endpoint = process.env.REACT_APP_BASE_URL + "getIASData";
  
   Api(endpoint,"POST",{id:campaign_id,start_date_period:start_date_data,end_date_period:end_date_data})
   .then(result => {


      console.log(result,"main result")
      setBrandSafety(result.data.brand_safety[0]);
      setFraud(result.data.fraud[0]);
      setInGeo(result.data.in_geo[0]);
      setViewability(result.data.viewability[0]);
      setFerreroLoading(false);


    })

},[])


 const variationData = (sdate,edate) => {

   setVdloading(true);
   const campaign_id = campaign_id_data;
  
   const endpoint = process.env.REACT_APP_BASE_URL + "VariationData";
  
   if(sdate && edate){

      Api(endpoint,"POST",{id:campaign_id,start_date_period:sdate,end_date_period:edate})
      .then(result => {

         console.log(result,"test variation 1")
         setVariationDataBudget(result.data.Budget[0]);
         setVariationDataGoal(result.data.Goal[0]);
         setVariationDataOptKpi(result.data.OptKPI[0]);
         
         
         //setVariationDataSecKPI(result.data.SecKPI[0]);
   
   
         if('SecKPI' in result.data){
            setVariationDataSecKPI(result.data.SecKPI[0]);
         }
         
         setVdloading(false);
   
         }).catch((err)=>console.log(err))
   
         

   }else{
    Api(endpoint,"POST",{id:campaign_id})
   .then(result => {

      console.log(result.data.OptKPI[0],"test variation opt kpi 2")
      setVariationDataBudget(result.data.Budget[0]);
      setVariationDataGoal(result.data.Goal[0]);
      setVariationDataOptKpi(result.data.OptKPI[0]);
      
    //  setVariationDataSecKPI(result.data.SecKPI[0]);


      if('SecKPI' in result.data){
         setVariationDataSecKPI(result.data.SecKPI[0]);
      }
      
      setVdloading(false);

      }).catch((err)=>console.log(err))

      }
  
           


 }

 useEffect(()=>{
  
   variationData();

},[campaign_id_data]);



const[alerts, setAlerts] = useState([]);
useEffect(()=>{

   const campaign_id = campaign_id_data;
   const endpoint = process.env.REACT_APP_BASE_URL + "alerts/"+campaign_id;
  
    Api(endpoint,"GET")

    .then(result => {

      setAlerts(result.data);

    }).catch((err)=>console.log(err))
    
 },[campaign_id_data])




 useEffect(()=>{


    //process.env.REACT_APP_BASE_URLsubCampaign/master/637f55418780eb7faeae740b
    setMloading(true);
    const master_campaign_id = master_campaign_id_data;
    const endpoint = process.env.REACT_APP_BASE_URL +  "subCampaign/master/" + master_campaign_id ;
   
    Api(endpoint,"GET")
    .then(result => {

       setMediafyCampaign(result.data);
       setMloading(false);
      
     }).catch((err)=>console.log(err))
     

 },[master_campaign_id_data])





useEffect(()=>{

 setDloading(true);  

 const campaign_id = campaign_id_data;

 const endpoint = process.env.REACT_APP_BASE_URL + "getDailyData";

  Api(endpoint,"POST",{id:campaign_id})
  .then(result => {

    //console.log(result.data.res,"checking daily table")   
    setDloading(false);
    setDailyTable(result.data.res);
    setDailyTableMap(result.data.map);
    setDailyTableResMap(result.data.res_map);
    

   }).catch((err)=>console.log(err))

 },[campaign_id_data])


 useEffect(()=>{
            
    const endpoint = process.env.REACT_APP_BASE_URL + "subCampaign/comment/"+ campaign_id_data;

    Api(endpoint,"GET")
    .then(result => {

    setListComments(result.data);
    
    }).catch((err)=>console.log(err))

   
},[comments])




useEffect(()=>{
            
   const endpoint = process.env.REACT_APP_BASE_URL + "subCampaign/actions/"+ campaign_id_data;

   Api(endpoint,"GET")
   .then(result => {

     

   setListActions(result.data);
   
   }).catch((err)=>console.log(err))

  
},[checkCampaign])


///////////////////Dynamic Daily Table//////////////

const daily_columns = dailyTableMap? dailyTableMap.map((a,b)=> { return(
   a
)}) : ''


const daily_data = dailyTable.map((a,b)=> { 

  let data =[];

   dailyTableResMap.map((c,d)=>{

          data.push(a[c]);
   })

   return(
      data
   )
   
})
///////////////////Dynamic Daily Table//////////////

const daily_options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: false,
    filterType: 'multiselect',
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    sortOrder: {
        name: 'day',
        direction: 'desc'
    },
    responsive: "standard",
    fixedHeader:true,
    fixedSelectColumn:false,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
        return (
          <TableFooter>
           
            <TableRow>
                <TablePagination
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(_, page) => changePage(page)}
                    onRowsPerPageChange={event => changeRowsPerPage(event.target.value)}
                    rowsPerPageOptions={[10, 15, 100]}
                />
            </TableRow>
          </TableFooter>
        );
      }
 
     
  };

  const comments_columns = [
   
    {
        name: "date",
        label: "Date",
        options: {
         filter: true,
         sort: true,
         filterType:'multiselect',
        
        }
    },
 "Comments",
  {
    name: "user",
    label: "User",
    options: {
     filter: true,
     sort: true,
     filterType:'multiselect',

    }
   },

];


const comments_data = listComments.map((a,b)=> {
   
return([
moment.utc(new Date(a.date)).local().format("YYYY-MM-DD"), 
parse(a.comment),
a.user
])});

const comments_options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: false,
    filterType: 'multiselect',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    sortOrder: {
        name: 'day',
        direction: 'desc'
    },
    responsive: "standard",
    fixedHeader:true,
    fixedSelectColumn:true
 
     
  };




  const actions_columns = [
   
   {
       name: "date",
       label: "Date",
       options: {
        filter: true,
        sort: true,
        filterType:'multiselect',
       
       }
   },
 {
   name: "user",
   label: "User",
   options: {
    filter: true,
    sort: true,
    filterType:'multiselect',

   }
  },

];


const actions_data = listActions.map((a,b)=> {
return([
moment.utc(new Date(a.date)).local().format("YYYY-MM-DD"), 
a.user
])});


const actions_options = {
   search: true,
   download: false,
   print: false,
   viewColumns: false,
   filter: false,
   filterType: 'multiselect',
   responsive,
   tableBodyHeight,
   tableBodyMaxHeight,
   selectableRows: 'none',
   sortOrder: {
       name: 'day',
       direction: 'desc'
   },
   responsive: "standard",
   fixedHeader:true,
   fixedSelectColumn:true

    
 };


const addComments = () => {
if(comments == "")
{
    setCommentsVal(true);
}

else{

    setCloading(true);
    let user = JSON.parse(localStorage.getItem('user_details'));        
    const endpoint = process.env.REACT_APP_BASE_URL + "subCampaign/extractComment";

    Api(endpoint,"POST", 
    { 
 
    "comment": comments,
    "campaignID": campaign_id_data,
    "user": user.name})
    .then(result => {

        setComments('');
        
        notify()
        setCloading(false);
    
    
    }).catch((err)=>console.log(err))
}

}

const notify = () => toast.success("Your comments submitted successfully",
{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
}
);

const handleShowComments = () => {

    showComments == false ? setShowComments(true) : setShowComments(false);
}



const handleShowDataVariation = () => {

   showDataVariation == false ? setShowDataVariation(true) : setShowDataVariation(false);
}

const[showCampaign,setShowCampaign] = useState(false);

const handleShowCampaign = () => {

   showCampaign == false ? setShowCampaign(true) : setShowCampaign(false);
}

const[showAlert,setShowAlert] = useState(true);
const handleShowAlert = () => {

   showAlert == false ? setShowAlert(true) : setShowAlert(false);
}



const[showMasterCampaign,setShowMasterCampaign] = useState(false);

const handleShowMasterCampaign = () => {

   showMasterCampaign == false ? setShowMasterCampaign(true) : setShowMasterCampaign(false);
}


const [avalue, setValue] = useState('');
const handleChange = (event, newValue) => {
   setValue(newValue);
 };


 const subCampaign = (id) => {

   window.scrollTo({ top: 0,left: 0,behavior: "smooth" });

   history.push({
      pathname: '/subCampaignListing',
      search: '?cid='+id,
    });

}


const getPeriods = (sdate,edate) =>{
//  console.log(data[5]+"//"+sdate+"--" + edate,"check p data");

   const campaign_id = campaign_id_data;

  variationData(sdate,edate);
  setHglobaltbl(false);
  
  const endpoint = process.env.REACT_APP_BASE_URL + "getGlobalData" ;
  setPtloading(true);
   Api(endpoint,"POST",  { id:campaign_id, start_date_period:sdate, end_date_period:edate})
   .then(result => {

     
   //   console.log(result.data,"check data fetching");
      setPtloading(false);
      setDataTableDays(result.data.days);
      setBudgetBarColor(result.data.budget.percentage > 100 ? 'red' : '');
      setDataTableBudget(result.data.budget);
      setDataTableGoal(result.data.goal);
      setGoalBarColor(result.data.goal.percentage > 100 ? 'green' : '');
      setDataTable(result.data);

     
      }).catch((err)=>console.log(err))


   
   
   const endpointa = process.env.REACT_APP_BASE_URL +  "getDailyData";
   setDloading(true);
   Api(endpointa,"POST",{ id:campaign_id, start_date_period:sdate, end_date_period:edate})
   .then(result => {
    
      setDloading(false);
      setDailyTable(result.data.res);
      setDailyTableMap(result.data.map);
      setDailyTableResMap(result.data.res_map);
      
   }).catch((err)=>console.log(err));


}


const editCampaign = (id) =>{


    history.push({
     pathname: '/addCampaign',
     search: '?cid='+id,
   }) 

 }



const getGlobalData = () => {

   setPfloading(true);
   setHglobaltbl(true);
   setPgloading(true);

   const campaign_id = campaign_id_data

   const endpoint = process.env.REACT_APP_BASE_URL +  "performanceControlData?id="+campaign_id;
   Api(endpoint,"POST")
   .then(result => {

      //console.log(result.data,"check result data")

     setPerformanceGlobalData(result.data);
     setPerformanceGlobalDataMap(result.data[0].map);
     setPerformanceGlobalDataResMap(result.data);
     setPfloading(false);
       
   }).catch((err)=>console.log(err));




   const endpointa = process.env.REACT_APP_BASE_URL + "GlobalPeriodsData?id="+campaign_id;
   Api(endpointa,"POST")
   .then(result => {


      setPeriodsGlobalData(result.data);
      setPgloading(false);


         
   }).catch((err)=>console.log(err));

}



////////////Dynamic Performance Table/////////////////////
const performance_columns = performanceGlobalDataMap.map((a,b)=> { return(
    a
)})


const performance_data = performanceGlobalData.map((a,b)=> { 

  let data =[a.name];

  a.res_map.map((c,d)=>{

       data.push(a.res[0][c]);
   })

   return(
      data
   )
 
})


////////////Dynamic Performance Table/////////////////////

const performance_options = {
   search: true,
   download: true,
   print: true,
   viewColumns: true,
   filter: false,
   filterType: 'multiselect',
   tableBodyHeight,
   tableBodyMaxHeight,
   selectableRows: 'none',
   sortOrder: {
       name: 'day',
       direction: 'desc'
   },
   responsive: "standard",
   fixedHeader:true,
   fixedSelectColumn:false,
  
    
};


const pglobal_columns = [
   
   {
       name: "periods",
       label: "Period",
       options: {
        filter: false,
        sort: true,
        filterType:'multiselect',
        
       
       }
      },
"Days",
"Spend",
 {
  name: "estimated_budget",
  label: "Estimated Budget",
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
  
  }
 },
 {
  name: goal_data,
  label: goal_data,
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
 

  }
 }, 
 {
  name: "estimated_goal_kpi",
  label: "Estimated "+goal_data,
  options: {
   filter: false,
   sort: true,
   filterType:'multiselect',
   viewColumns:true,
 

  }
 }, 
 {
   name: opt_kpi_data,
   label:opt_kpi_data,
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
  
 
   }
  }, 

  {
   name: 'estimated_optimization_kpi',
   label:'Estimated ' + opt_kpi_data,
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
  
 
   }
  }, 
  {
   name: sec_kpi_data,
   label:sec_kpi_data,
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: sec_kpi_data === "" ? false : true
 
   }
  }, 

  {
   name: 'estimated_secondary_kpi',
   label:'Estimated ' + sec_kpi_data,
   options: {
    filter: false,
    sort: true,
    filterType:'multiselect',
    viewColumns:true,
    display: sec_kpi_data === "" ? false : true
 
   }
  }, 


];



const pglobal_data = periodsGlobalData.map((a,b)=> { 
 
   // console.log(a,"check a");data

    const pgoal = goal_data.replace(/ /g,"_");
    const pokpi = opt_kpi_data.replace(/ /g,"_");
    const pskpi = sec_kpi_data.replace(/ /g,"_");


    //estimated conversion value  = estimated goal, target roars = estiamed_optimization in periods 
    return([
     a.name, 
     a.days.total_days,
     a.res[0].spend,
     a.info.periods_info[b].total_budget,
     a.res[0][pgoal],
     a.info.periods_info[b].estimated_goal,
     a.res[0][pokpi],
     a.info.periods_info[b].estimated_optimaization,
     a.res[0][pskpi],
     a.info.periods_info[b].estimated_secondary_optimaization,
      
     ])

});
  

const pglobal_options = {
   search: true,
   download: true,
   print: true,
   viewColumns: true,
   filter: false,
   filterType: 'multiselect',
   tableBodyHeight,
   tableBodyMaxHeight,
   selectableRows: 'none',
   sortOrder: {
       name: 'day',
       direction: 'desc'
   },
   responsive: "standard",
   fixedHeader:true,
   fixedSelectColumn:false,
    
};


const [barBudgetColor,setBudgetBarColor] = useState();
const [barGoalColor,setGoalBarColor] = useState();

const checkedCampaign = () => {

   let isExecuted = window.confirm("Do you confirmed you checked this campaign?");

   setCheckCampaign(true);

   if(isExecuted){
         let user = JSON.parse(localStorage.getItem('user_details'));    
         const endpoint = process.env.REACT_APP_BASE_URL + "subCampaign/extractAction";

         Api(endpoint,"POST", 
         { 

            "action": "checked",
            "campaignID": campaign_id_data,
            "user": user.email

         })
         .then(result => {

            
            setCheckCampaign(false);
          
        
         }).catch((err)=>console.log(err))

   } 

}


const masterCampaign = (id) => {
   
   history.push({
       pathname: '/masterCampaignListing',
       search: '?cid='+id,
     }) 
}


return (
    <>

   
    <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
    <div className="projects">

    <div style={{fontSize:12, margin:5, display:'flex', justifyContent:'right'}}>Last updated at: {lastUpdate ? moment.utc(new Date(lastUpdate)).local().format("YYYY-MM-DD HH:mm:ss") : 'loading..' }</div>
   
    <div className="card" style={{padding:'20px'}}>

        <div style={{backgroundColor:'#4169e1', borderRadius:50,  display:'flex', justifyContent:'space-between' , padding:10,}}>
        
        <span style={{fontSize:15, paddingLeft:5, color:'white'}}><b>Campaign  Details</b></span>
        <div>
        <span onClick={e => editCampaign(campaign_id_data) } className="las la-edit" style={{cursor:'pointer',color:'white', padding:0,marginRight:15, fontSize:23}} ></span> 
        <span  onClick={check} className="las la-arrow-alt-circle-left" style={{fontSize:25, cursor:'pointer', color:'white'}}></span> 
        </div>
      

        </div>

      

    
     {/** Alert Campaign*/}
     {alerts.length > 0 ?
     <div style={{display:'flex',flexDirection:'column',alignContent:'center', borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15,  }} onClick={handleShowAlert}>
        
        <div style={{backgroundColor:'#D9EAF7',color:'black', padding:10, marginBottom:5, display:'flex', flexDirection:'row',justifyContent:'space-between' }}> 

         <div>
            <b> Campaign Alerts: </b><span style={{fontSize:'15px'}}> ({alerts.length}) </span> 
         </div>

         <div>
         
           <span style={{paddingTop:50, paddingLeft:20, color:'#4169e1'}} >
                {showAlert ?
                 <KeyboardDoubleArrowDownIcon /> 
                : 
                 <KeyboardDoubleArrowLeftIcon />
                }
            </span>

         </div>
                                                            
        </div>

       {showAlert ?
        <div>           
        <table id="test" width="100%" style={{border:'1px solid lightgray', backgroundColor:'white',}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div>
                                                                  <table style={{width:'100%',border:'1px solid lightgray', height:'100px', overflowY:'scroll'}}>
                                                                    
                                                                     <tbody>

                                                
                                                                   
                                                                       {alerts.map((a,b)=>{
                                                                        return(
                                                                       <tr style={{border:'1px solid lightgray'}}>
                                                                        <td >{b+1}</td>
                                                                       
                                                                        <td style={{border:'1px solid lightgray',width:'80%', color:'red'}}>{a.alert}</td>
                                                                        <td>{moment.utc(new Date(a.timestamp)).local().format("YYYY-MM-DDTHH:mm:ss")}</td>
                                                                       </tr>
                                                                         )
                                                                       })}                                                        
                                                                      
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
        </div>
       :''}

      


        </div>
        :''}

    
  {/**Alert Campaign */}

    
     {/** Sub Campaign*/}



     <div style={{display:'flex',flexDirection:'column',alignContent:'center', borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15,  }}>
        
        <div style={{backgroundColor:'#D9EAF7',color:'black', padding:10, marginBottom:5, display:'flex', flexDirection:'row',justifyContent:'space-between' }}> 

         <div >
            <b>Sub Campaign: </b><span style={{fontSize:'15px'}}>&nbsp;&nbsp; {sub_campaign_full_name_data} </span> 

         </div>

         <div>
           <b> Start Date:</b> { moment.utc(new Date(start_date_data)).local().format("YYYY-MM-DD")}  | <b> End Date:</b> { moment.utc(new Date(end_date_data)).local().format("YYYY-MM-DD")}
              <span style={{paddingTop:50, paddingLeft:20, color:'#4169e1'}} >
                {showCampaign ?
                 <KeyboardDoubleArrowDownIcon style={{cursor:'hand'}}  onClick={handleShowCampaign} /> 
                : 
                 <KeyboardDoubleArrowLeftIcon  style={{cursor:'hand'}}  onClick={handleShowCampaign} />
                }
                </span>
         </div>
         
                                                             
        </div>

       {showCampaign ?
        <div>           
        <table id="test" width="100%" >
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div>
                                                                  <table style={{width:'100%',height:'200px'}}>
                                                                    
                                                                     <tbody>

                                                                        


                                                                   
                                                                       <tr >
                                                                        <td style={{width:'15%'}}><b>Advertiser</b> </td>
                                                                        <td style={{width:'35%'}}>{client_data}</td>
                                                                        <td style={{width:'15%'}}><b>Campaign Type</b> </td>
                                                                        <td style={{width:'35%'}}>{campaign_type_data}</td>
                                                                       </tr>

                                                                       <tr >
                                                                        <td style={{width:'15%'}}><b>Campaign Owner</b> </td>
                                                                        <td style={{width:'35%'}}>{campaign_owner_data}</td>
                                                                        <td style={{width:'15%'}}><b>Optimization KPI </b></td>
                                                                        <td style={{width:'35%'}}>{opt_kpi_data}</td>
                                                                       </tr>

                                                                       <tr >
                                                                        <td style={{width:'15%'}}><b>Platform</b> </td>
                                                                        <td style={{width:'35%'}}>{platform_data}</td>
                                                                        <td style={{width:'15%'}}><b>Secondary KPI</b> </td>
                                                                        <td style={{width:'35%'}}>{sec_kpi_data  ? sec_kpi_data : 'Not Present'}</td>
                                                                       </tr>

                                                                       <tr >
                                                                        <td style={{width:'15%'}}><b>Goal</b> </td>
                                                                        <td style={{width:'35%'}}>{goal_data}</td>
                                                                        <td style={{width:'15%'}}><b>Team Leader</b> </td>
                                                                        <td style={{width:'35%'}}>{team_leader_data}</td>
                                                                       </tr>

                                                                       <tr>
                                                                        <td style={{width:'15%'}}><b>Campaign Backup</b> </td>
                                                                        <td style={{width:'35%'}}>{campaign_backup_data}</td>
                                                                        <td style={{width:'15%'}}><b>Performance Manager </b></td>
                                                                        <td style={{width:'35%'}}>{performance_manager_data}</td>
                                                                       </tr>

                                                                  
                                                                       <tr>
                                                                        <td  style={{width:'15%'}}>

                                                                          <b> {days_off.length > 0 ? 'Days Off': ''}</b>

                                                                        </td>

                                                                        <td colSpan={3}>
                                                                           {
                                                                          
                                                                           days_off.map((a,b)=>{return(
                                                                              

                                                                              a.days_off.length > 0 ?
                                                                              a.days_off.map((c,d)=>{
                                                                                 
                                                                                 
                                                                                 return(

                                                                                 c.date ?
                                                                                 <span style={{float:"left", margin:5, border:'1px solid red', padding:5}}>

                                                                                    {c.date ? c.date : ''}

                                                                                 </span>
                                                                                 : ''

                                                                                  )
                                                                           
                                                                           
                                                                               }) : ''

                                                                           
                                                                           )

                                                                            

                                                                             

                                                                           })
                                                                        
                                                                           }
                                                                           


                                                                        </td>
                                                                       </tr>

                                                                        
                                                                      
                                                                   
                                                                      
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
        </div>
       :''}

      


        </div>

    
  {/**Sub Campaign */}
                

{/** Notes */}

{notes_data?
<div style={{display:'flex',flexDirection:'column',alignContent:'center', borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15}}>
        
        <div style={{backgroundColor:'#D9EAF7',color:'black', padding:10, marginBottom:5, display:'flex', flexDirection:'row',justifyContent:'space-between' }}> 

       <b> Campaign Notes </b>

       </div>

       <div style={{border:'1px solid lightgray', backgroundColor:'white', padding:10}}>

         {parse(notes_data)}

       </div>
      
</div>
:''}



   {/** Data Table */}
  
  { mode_data === "continuous" ?

   <div style={{display:'flex',flexDirection:'column',alignContent:'center',  borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15}}>
        
     

        <div>  

        {dtLoading ? <> <LinearProgress  /> <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center'}}> loading data ..... Please Wait..</div> </>:   
         <>
         <div style={{display:'flex', flexDirection:'row'}}>
            <div className='dt_box'>

                

               <div> DAYS UNTIL THE END</div> 
               <div style={{fontSize:40, fontWeight:600}}>{dataTableDays.left}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div></div>
                  <div>&nbsp;</div>
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div></div>
                  <div>&nbsp;</div>
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5}}>
                  <div className='dt_box_label'>Total</div>
                  <div>{dataTableDays.total}</div>
               </div>

            

               <div style={{display:'flex', flexDirection:'column !important', justifyContent:'space-between', paddingTop:15, padding:5}}>
                     <div style={{width:"82%", paddingTop:8}}>
                     <LinearProgress 
                        // sx={{ 
                        //   '& .MuiLinearProgress-bar': {
                        //  backgroundColor: 'red'
                        // }}}
                        variant="determinate" value={dataTableDays.percentage > 100 ? 100 : dataTableDays.percentage} />
                     </div>
                     
                     <div>{dataTableDays.percentage}%</div>
               </div>
                   
            
       
            </div>
            <div className='dt_box'> 
             <div> BUDGET LEFT PER DAY</div>
             <div style={{fontSize:40, fontWeight:600}}>{dataTableBudget.left_per_day}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div className='dt_box_label'>Spent until Yesterday</div>
                  <div>{dataTableBudget.done}</div>
                 
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div className='dt_box_label'>Left until Total</div>
               <div>{dataTableBudget.left}</div>
                  
               </div>
               <hr/>
               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5}}>
                  <div className='dt_box_label'>Total</div>
                  <div>{dataTableBudget.total}</div>
               </div>

            

               <div style={{display:'flex', flexDirection:'column !important', justifyContent:'space-between', paddingTop:15, padding:5}}>
                     <div style={{width:"82%", paddingTop:8}}>

                    
                     <LinearProgress 
                         sx={{ 
                           '& .MuiLinearProgress-bar': {
                             backgroundColor: barBudgetColor
                         }}}
                        variant="determinate" value={dataTableBudget.percentage > 100 ? 100 : dataTableBudget.percentage } />
                     </div>
                     
                     <div>{dataTableBudget.percentage}%</div>
               </div>
            
            
            </div>
            <div className='dt_box'> 
            <div>{goal_data.toUpperCase()} LEFT PER DAY</div>

            <div style={{fontSize:40, fontWeight:600}}>{dataTableGoal.left_per_day}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div className='dt_box_label'>Achieved until Yesterday</div>
                  <div>{dataTableGoal.done}</div>
                 
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div className='dt_box_label'>Left until Total</div>
               <div>{dataTableGoal.left}</div>
                  
               </div>
               <hr/>
               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5}}>
                  <div className='dt_box_label'>Total</div>
                  <div>{dataTableGoal.total}</div>
               </div>

            

               <div style={{display:'flex', flexDirection:'column !important', justifyContent:'space-between', paddingTop:15, padding:5}}>
                     <div style={{width:"82%", paddingTop:8}}>

                      
                     <LinearProgress 
                         sx={{ 
                           '& .MuiLinearProgress-bar': {
                            backgroundColor: barGoalColor
                         }}}
                        variant="determinate" value={dataTableGoal.percentage > 100 ? 100 : dataTableGoal.percentage} />
                     </div>
                     
                     <div>{dataTableGoal.percentage}%</div>
               </div>
            
            
            
            </div>

            <div className='dt_box'> 

               <div> AVERAGE {opt_kpi_data.toUpperCase()}</div>

              

                  {variationDataOptKpi.actual_value != undefined ? 
                  <>
                   <div style={{fontSize:40, fontWeight:600, }}>{variationDataOptKpi.daily_average}</div>
                  <div style={{ flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
                     <ReactSpeedometer
                          
                           maxValue={variationDataOptKpi.cap[variationDataOptKpi.cap.length - 1]}
                           value={variationDataOptKpi.actual_value}
                           valueFormat={".2f"}
                           customSegmentStops={variationDataOptKpi.cap}
                           segmentColors={["green", "orange","red"]}
                           currentValueText={opt_kpi_data + ": ${value} € "}
                           textColor={"black"}
                     />


                  <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between',padding:5}}>
                  <div className='dt_box_label'>Estimated {opt_kpi_data}</div>
                  <div>{variationDataOptKpi.daily_estimate}</div>
                 
                  </div>

                  <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
                   <div className='dt_box_label'>Yesterday's {opt_kpi_data}</div>
                  <div>{variationDataOptKpi.yesterday_spend}</div>
                  
    
                  </div>

                 
               </div>
                  
                  </>
                  :
                  <>
                  <div style={{fontSize:40, fontWeight:600}}>{variationDataOptKpi.daily_average}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div className='dt_box_label'>Estimated {opt_kpi_data}</div>
                  <div>{variationDataOptKpi.daily_estimate}</div>
                 
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div className='dt_box_label'>Yesterday's {opt_kpi_data}</div>
               <div>{variationDataOptKpi.yesterday_spend}</div>
                  
               </div>
               </>
              
            }
               



               
               
               
            </div>

            {/**  Old Average CPM
            <div className='dt_box'> 

            <div> AVERAGE {opt_kpi_data.toUpperCase()}</div>

             <div style={{fontSize:40, fontWeight:600}}>{variationDataOptKpi.daily_average}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div className='dt_box_label'>Estimated {opt_kpi_data}</div>
                  <div>{variationDataOptKpi.daily_estimate}</div>
                 
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div className='dt_box_label'>Yesterday's {opt_kpi_data}</div>
               <div>{variationDataOptKpi.yesterday_spend}</div>
                  
               </div>
              

            

             
             
             
             </div>
             */}

         </div>

        { client_data.match(/\b\w+\b/g).slice(0, 7)[0] === "Ferrero"  ? 
        
        <>
       
       {ferreroLoading ? <> <LinearProgress  /> <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center'}}> loading charts ..... Please Wait..</div> </>:   
         <>
         <div style={{display:'flex', flexDirection:'row',}}>
            <div className='dt_box'>

            <div> VIEWABILITY </div>

            <div style={{fontSize:40, fontWeight:600, }}>{viewability.actual_value} %</div>
            <div style={{ flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
            {brandSafety ?
            <ReactSpeedometer
                  minValue={viewability.cap[0]}
                  maxValue={viewability.cap[viewability.cap.length - 1]}
                  value={viewability.actual_value}
                  valueFormat={".2f"}
                  customSegmentStops={viewability.cap}
                  segmentColors={["red", "green"]}
                  currentValueText={"Viewability: ${value} %"}
                  textColor={"black"}
            />
            : 'getting data' }


       

            </div>
            
       
            </div>
            <div className='dt_box'> 
            <div> FRAUD </div>

               <div style={{fontSize:40, fontWeight:600, }}>{fraud.actual_value} %</div>
               <div style={{ flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <ReactSpeedometer
                  minValue={fraud.cap[0]}
                  maxValue={fraud.cap[fraud.cap.length - 1]}
                  value={fraud.actual_value}
                  valueFormat={".2f"}
                  customSegmentStops={fraud.cap}
                  segmentColors={["red","green"]}
                  currentValueText={"Invalid Traffic: ${value} %"}
                  textColor={"black"}
            />

            



               </div>
            
            
            </div>
            <div className='dt_box'> 
            
            <div> BRAND SAFETY </div>

            <div style={{fontSize:40, fontWeight:600, }}>{brandSafety.actual_value} {brandSafety.actual_value != "NA" ? '%' : ''}</div>
            <div style={{ flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
            {brandSafety.actual_value != "NA" ? 
            <ReactSpeedometer
                 minValue={brandSafety.cap[0]}
                 maxValue={brandSafety.cap[brandSafety.cap.length - 1]}
                  value={brandSafety.actual_value}
                  valueFormat={".2f"}
                  customSegmentStops={brandSafety.cap}
                  segmentColors={["red", "orange","green"]}
                  currentValueText={"Brand Safety: ${value} %"}
                  textColor={"black"}
            />
            :''}


           


            </div>
            
            
            </div>

            <div className='dt_box'> 

               <div> IN-GEO </div>

                   <div style={{fontSize:40, fontWeight:600, }}>{inGeo.actual_value} {inGeo.actual_value != "NA" ? '%' : ''}</div>
                  <div style={{ flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
                     {inGeo.actual_value != "NA" ? 
                     <ReactSpeedometer
                          minValue={inGeo.cap[0]}
                           maxValue={inGeo.cap[inGeo.cap.length - 1]}
                           value={inGeo.actual_value }
                           valueFormat={".2f"}
                           customSegmentStops={inGeo.cap}
                           segmentColors={["red", "green"]}
                           currentValueText={"In-Geo: ${value} %"}
                           textColor={"black"}
                     />
                     :''}


                 

                 
               </div>
                  
    
            </div>

            {/**  Old Average CPM
            <div className='dt_box'> 

            <div> AVERAGE {opt_kpi_data.toUpperCase()}</div>

             <div style={{fontSize:40, fontWeight:600}}>{variationDataOptKpi.daily_average}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div className='dt_box_label'>Estimated {opt_kpi_data}</div>
                  <div>{variationDataOptKpi.daily_estimate}</div>
                 
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div className='dt_box_label'>Yesterday's {opt_kpi_data}</div>
               <div>{variationDataOptKpi.yesterday_spend}</div>
                  
               </div>
              

            

             
             
             
             </div>
             */}

         </div>

         

         </>
                                       
         }


        
        
        </>
            
        : ''}

         </>
                                       
         }
        </div>
         






       

        <div style={{ margin:20}}>
         <Button  onClick={checkedCampaign} className={checkCampaign ? 'btnDisabled' : 'btnEnabled' } disabled={checkCampaign ? true : false} > 
          {checkCampaign ? 'Already Checked': 'Check Campaign ' }
         </Button>
       </div>
       
  {/** Daily Table Continuous */}
  <div style={{backgroundColor:'#D9EAF6', padding:10, marginBottom:5, marginTop:10, width:'100%'}}> 
            <b>Daily Table</b>
                                                             
        </div>


        <div>  
        {dLoading ? <> <LinearProgress  />
        
       <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center', overflow:'hidden' }}> loading data ..... Please Wait..</div> </>:          
        <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white',}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                           

                                                                <CacheProvider value={muiCache}>
                                                                        <ThemeProvider theme={getMuiTheme()}>
                                                                        
                                                                            <MUIDataTable
                                                                             //title={"Campaign Information"}   
                                                                            data={daily_data}
                                                                            columns={daily_columns}
                                                                            options={daily_options}
                                                                           
                                                                            />
                                                                        </ThemeProvider>
                                                                        </CacheProvider>
                                                             
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
            }
        </div>

         {/** Daily Table Continuous */}



{/** Data Variation */}
 <div style={{backgroundColor:'#D9EAF7',color:'black', padding:10, marginTop:10, marginBottom:5, display:'flex', flexDirection:'row',justifyContent:'space-between' }} > 

       <b> Estimated and Average Values </b>
       <span style={{float:'right',color:'#4169e1'}} >
                {showDataVariation ?
                <KeyboardDoubleArrowDownIcon style={{cursor:'hand'}}  onClick={handleShowDataVariation} /> 
                :  <KeyboardDoubleArrowLeftIcon style={{cursor:'hand'}}  onClick={handleShowDataVariation}  />  }
                
                </span>

       </div>

       {showDataVariation ? 
       <div style={{border:'1px solid lightgray', backgroundColor:'white', padding:10}}>
       {vdLoading ? <> <LinearProgress  /> <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center'}}> loading data ..... Please Wait..</div> </> : 
                                                    <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div >
                                                                  <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'30.33%', fontWeight:'900' }}>Estimated Daily Budget </td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Average Daily Spend</td>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Yesterday's Spend</td>
                                                                          
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',}}>{ variationDataBudget.daily_estimate }</td>           
                                                                           <td style={{border:'1px solid lightgray',width:'30.33%'  }}>{ variationDataBudget.daily_average}</td>
                                                                          
                                                                           <td style={{border:'1px solid lightgray', }}> { variationDataBudget.yesterday_spend} </td>
                                                                          
                                                                        </tr>
                                                                                                                                                                       
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
                                                }

                                                   <br/>
                                                   <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div >
                                                                  <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'30.33%', fontWeight:'900' }}>Estimated Daily {goal_data}  </td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Average Daily {goal_data} </td>
                                                                          
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Yesterday's {goal_data}</td>
                                                                         
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                   
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                        <td style={{border:'1px solid lightgray',}}>{ variationDataGoal.daily_estimate }</td>           
                                                                           <td style={{border:'1px solid lightgray',width:'30%'  }}>{ variationDataGoal.daily_average}</td>
                                                                         
                                                                           <td style={{border:'1px solid lightgray', }}> { variationDataGoal.yesterday_spend} </td>
                                                                          
                                                                        </tr>
                                                                       

                                                                                                
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>

                                                  

                                                   <br/>
                                                
                                                   {sec_kpi_data ?
                                                   <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div >
                                                                  <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'30.33%', fontWeight:'900' }}>Estimated {sec_kpi_data}   </td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Average {sec_kpi_data}</td>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Yesterday's {sec_kpi_data}</td>
                                                                          
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                   
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                        <td style={{border:'1px solid lightgray',}}>{ variationDataSecKPI.daily_estimate }</td>           
                                                                           <td style={{border:'1px solid lightgray',width:'30.33%'  }}>{ variationDataSecKPI.daily_average}</td>
                                                                          
                                                                           <td style={{border:'1px solid lightgray', }}> { variationDataSecKPI.yesterday_spend} </td>
                                                                          
                                                                        </tr>
                                                                       

                                                                                                
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
                                                   :''}
                                             

       </div>

       :''}

         
{/** */}

   </div>

   

      :
      
      <div style={{display:'flex',flexDirection:'column',alignContent:'center',  borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15}}>
        
      <div>   

      <TabContext value={avalue ? avalue : currentPeriod}>
         
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList variant="scrollable" onChange={handleChange} style={{backgroundColor:'#D9EAF6', padding:10, marginBottom:5, overflow:'scroll'}}>
          <Tab label="Global" value="global"  onClick={getGlobalData}  /> 
       
           { periods_info_data .map((a,b)=>{

            const test = "P"+ (b + 1) ;
            const label =  " ( " + a.start_date + " / "+ a.end_date + " ) " ;
           
            return(
            
            <Tab label={test + label} value={b+1} onClick={(e) => getPeriods(a.start_date,a.end_date) }   />
            
         
           )})}
         
          
          </TabList>
          
        </Box>
        
        { periods_info_data.map((c,d)=>{
         return(
        <TabPanel value={d + 1}>

               <div>  

               {dtLoading ? <> <LinearProgress  /> <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center'}}> loading data ..... Please Wait..</div> </>:   
               <>

<div style={{display:'flex', flexDirection:'row'}}>
            <div className='dt_box'>

                

               <div> DAYS UNTIL THE END </div> 
               <div style={{fontSize:40, fontWeight:600}}>{dataTableDays.left}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div></div>
                  <div>&nbsp;</div>
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div></div>
                  <div>&nbsp;</div>
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5}}>
                  <div className='dt_box_label'>Total</div>
                  <div>{dataTableDays.total}</div>
               </div>

            

               <div style={{display:'flex', flexDirection:'column !important', justifyContent:'space-between', paddingTop:15, padding:5}}>
                     <div style={{width:"82%", paddingTop:8}}>
                     <LinearProgress 
                        // sx={{ 
                        //   '& .MuiLinearProgress-bar': {
                        //  backgroundColor: 'red'
                        // }}}
                        variant="determinate" value={dataTableDays.percentage > 100 ? 100 : dataTableDays.percentage} />
                     </div>
                     
                     <div>{dataTableDays.percentage}%</div>
               </div>
                   
            
       
            </div>
            <div className='dt_box'> 
             <div> BUDGET LEFT PER DAY</div>
             <div style={{fontSize:40, fontWeight:600}}>{dataTableBudget.left_per_day}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div className='dt_box_label'>Spent until Yesterday</div>
                  <div>{dataTableBudget.done}</div>
                 
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div className='dt_box_label'>Left until Total</div>
               <div>{dataTableBudget.left}</div>
                  
               </div>
               <hr/>
               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5}}>
                  <div className='dt_box_label'>Total</div>
                  <div>{dataTableBudget.total}</div>
               </div>

            

               <div style={{display:'flex', flexDirection:'column !important', justifyContent:'space-between', paddingTop:15, padding:5}}>
                     <div style={{width:"82%", paddingTop:8}}>
                     <LinearProgress 
                         sx={{ 
                           '& .MuiLinearProgress-bar': {
                          backgroundColor: barBudgetColor
                         }}}
                        variant="determinate" value={dataTableBudget.percentage > 100 ? 100 : dataTableBudget.percentage} />
                     </div>
                     
                     <div>{dataTableBudget.percentage}%</div>
               </div>
            
            
            </div>
            <div className='dt_box'> 
            <div>{goal_data.toUpperCase()} LEFT PER DAY</div>

            <div style={{fontSize:40, fontWeight:600}}>{dataTableGoal.left_per_day}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div className='dt_box_label'>Achieved until Yesterday</div>
                  <div>{dataTableGoal.done}</div>
                 
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div className='dt_box_label'>Left until Total</div>
               <div>{dataTableGoal.left}</div>
                  
               </div>
               <hr/>
               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5}}>
                  <div className='dt_box_label'>Total</div>
                  <div>{dataTableGoal.total}</div>
               </div>

            

               <div style={{display:'flex', flexDirection:'column !important', justifyContent:'space-between', paddingTop:15, padding:5}}>
                     <div style={{width:"82%", paddingTop:8}}>
                     <LinearProgress 
                         sx={{ 
                           '& .MuiLinearProgress-bar': {
                              backgroundColor: barGoalColor
                         }}}
                        variant="determinate" value={dataTableGoal.percentage > 100 ? 100 : dataTableGoal.percentage} />
                     </div>
                     
                     <div>{dataTableGoal.percentage}%</div>
               </div>
            
            
            
            </div>
            <div className='dt_box'> 

            <div> AVERAGE {opt_kpi_data.toUpperCase()}</div>

             <div style={{fontSize:40, fontWeight:600}}>{variationDataOptKpi.daily_average}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div className='dt_box_label'>Estimated {opt_kpi_data}</div>
                  <div>{variationDataOptKpi.daily_estimate}</div>
                 
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div className='dt_box_label'>Yesterday's {opt_kpi_data}</div>
               <div>{variationDataOptKpi.yesterday_spend}</div>
                  
               </div>
              
             </div>
         </div>

               

               </>
                                             
               }
               </div>

        
        </TabPanel>
        )})}



        <TabPanel value="global">
        {pfLoading ? <LinearProgress  /> : '' }
                     <CacheProvider value={pmuiCache}>
                            <ThemeProvider theme={getMuiTheme()}>
                                                                        
                                                                            <MUIDataTable
                                                                            title={"Performance Control"}   
                                                                            data={performance_data}
                                                                            columns={performance_columns}
                                                                            options={performance_options}
                                                                           
                                                                            />
                            </ThemeProvider>
                       </CacheProvider>

                  <br/>

                       <CacheProvider value={gmuiCache}>
                            <ThemeProvider theme={getMuiTheme()}>
                                                                        
                                                                            <MUIDataTable
                                                                            title={"Global Table"}   
                                                                            data={pglobal_data}
                                                                            columns={pglobal_columns}
                                                                            options={pglobal_options}
                                                                           
                                                                            />
                            </ThemeProvider>
                       </CacheProvider>
                                                             



        </TabPanel>
      </TabContext>
   
                                      
        </div>

 {/*Data Varation periods*/}



         {/*Data Varation periods*/}
        <div style={{ margin:20}}>
         <Button  onClick={checkedCampaign} className={checkCampaign ? 'btnDisabled' : 'btnEnabled' } disabled={checkCampaign ? true : false} > 
          {checkCampaign ? 'Already Checked': 'Check Campaign' }
         </Button>
       </div>

         {/** Daily Table Continuous */}

  {hGlobalTbl ? '' :
  <>
        <div style={{backgroundColor:'#D9EAF6', padding:10, marginBottom:5, marginTop:10, width:'100%'}}> 
            <b>Daily Table  </b>
                                                             
        </div>


        <div>  
        {dLoading ? <> <LinearProgress  />
        
       <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center', overflow:'hidden' }}> loading data ..... Please Wait..</div> </>:          
        <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white',}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                           

                                                                <CacheProvider value={muiCache}>
                                                                        <ThemeProvider theme={getMuiTheme()}>
                                                                        
                                                                            <MUIDataTable
                                                                            //title={"Campaign Information"}   
                                                                            data={daily_data}
                                                                            columns={daily_columns}
                                                                            options={daily_options}
                                                                           
                                                                            />


                                                                        </ThemeProvider>


                                                               </CacheProvider>
                                                             
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
            }
        </div>
             </>
          }
         {/** Daily Table Continuous */}



{/** Data Variation */}

{hGlobalTbl ? '' :
<div>
<div style={{backgroundColor:'#D9EAF7',color:'black', padding:10, marginTop:10, marginBottom:5, display:'flex', flexDirection:'row',justifyContent:'space-between' }} > 

<b> Estimated and Average Values </b>
<span style={{float:'right',color:'#4169e1'}} >
         {showDataVariation ?
            <KeyboardDoubleArrowDownIcon style={{cursor:'hand'}} onClick={handleShowDataVariation} /> 
         :  <KeyboardDoubleArrowLeftIcon style={{cursor:'hand'}}  onClick={handleShowDataVariation} />  }
         
         </span>

</div>


{showDataVariation ? 
<div style={{border:'1px solid lightgray', backgroundColor:'white', padding:10}}>
{vdLoading ? <> <LinearProgress  /> <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center'}}> loading data ..... Please Wait..</div> </> : 
                                             <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                               <tbody >
                                                
                                                  <tr >
                                                     <td>
                                                        <div >
                                                           <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                              <thead>
                                                              <tr style={{border:'1px solid lightgray'}}>
                                                                  
                                                                    <td style={{border:'1px solid lightgray',width:'30.33%', fontWeight:'900' }}>Estimated Daily Budget </td>
                                                                    <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Average Daily Spend</td>
                                                                  
                                                                    <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Yesterday's Spend</td>
                                                                   
                                                                 </tr>
                                                              </thead>
                                                              <tbody>
                                                             

                                                             
                                                            
                                                                 <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                  
                                                                    <td style={{border:'1px solid lightgray',}}>{ variationDataBudget.daily_estimate }</td>           
                                                                    <td style={{border:'1px solid lightgray',width:'30.33%'  }}>{ variationDataBudget.daily_average}</td>
                                                                   
                                                                    <td style={{border:'1px solid lightgray', }}> { variationDataBudget.yesterday_spend} </td>
                                                                   
                                                                 </tr>
                                                                

                                                                                         
                                                              </tbody>
                                                           </table>
                                                        </div>
                                                     </td>
                                                  </tr>
                                               </tbody>
                                            </table>
                                         }

                                            <br/>
                                            <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                               <tbody >
                                                
                                                  <tr >
                                                     <td>
                                                        <div >
                                                           <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                              <thead>
                                                              <tr style={{border:'1px solid lightgray'}}>
                                                                  
                                                                    <td style={{border:'1px solid lightgray',width:'30.33%', fontWeight:'900' }}>Estimated Daily {goal_data}  </td>
                                                                    <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Average Daily {goal_data} </td>
                                                                   
                                                                    <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Yesterday's {goal_data}</td>
                                                                  
                                                                 </tr>
                                                              </thead>
                                                              <tbody>

                                                            
                                                                 <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                  
                                                                 <td style={{border:'1px solid lightgray',}}>{ variationDataGoal.daily_estimate }</td>           
                                                                    <td style={{border:'1px solid lightgray',width:'30%'  }}>{ variationDataGoal.daily_average}</td>
                                                                  
                                                                    <td style={{border:'1px solid lightgray', }}> { variationDataGoal.yesterday_spend} </td>
                                                                   
                                                                 </tr>
                                                                

                                                                                         
                                                              </tbody>
                                                           </table>
                                                        </div>
                                                     </td>
                                                  </tr>
                                               </tbody>
                                            </table>

                                            {/**
                                            <br/>
                                            <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                               <tbody >
                                                
                                                  <tr >
                                                     <td>
                                                        <div >
                                                           <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                              <thead>
                                                              <tr style={{border:'1px solid lightgray'}}>
                                                                  
                                                              <td style={{border:'1px solid lightgray',width:'30.33%', fontWeight:'900' }}>1. Estimated {opt_kpi_data}  </td>
                                                                    <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>2. Average {opt_kpi_data}</td>
                                                                  
                                                                    <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>4. Yesterday's {opt_kpi_data}</td>
                                                                   
                                                                 </tr>
                                                              </thead>
                                                              <tbody>

                                                            
                                                                 <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                  
                                                                 <td style={{border:'1px solid lightgray',}}>{ variationDataOptKpi.daily_estimate }</td>           
                                                                    <td style={{border:'1px solid lightgray',width:'30%'  }}>{ variationDataOptKpi.daily_average}</td>
                                                                  
                                                                    <td style={{border:'1px solid lightgray', }}> { variationDataOptKpi.yesterday_spend} </td>
                                                                   
                                                                 </tr>
                                                                

                                                                                         
                                                              </tbody>
                                                           </table>
                                                        </div>
                                                     </td>
                                                  </tr>
                                               </tbody>
                                            </table>
                                             */}

                                            <br/>
                                         
                                            {sec_kpi_data ?
                                            <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                               <tbody >
                                                
                                                  <tr >
                                                     <td>
                                                        <div >
                                                           <table style={{width:'100%',border:'1px solid lightgray',}}>
                                                              <thead>
                                                              <tr style={{border:'1px solid lightgray'}}>
                                                                  
                                                                    <td style={{border:'1px solid lightgray',width:'30.33%', fontWeight:'900' }}> Estimated {sec_kpi_data}   </td>
                                                                    <td style={{border:'1px solid lightgray',fontWeight:'900'  }}> Average {sec_kpi_data}</td>
                                                                  
                                                                    <td style={{border:'1px solid lightgray',fontWeight:'900'  }}> Yesterday's {sec_kpi_data}</td>
                                                                   
                                                                 </tr>
                                                              </thead>
                                                              <tbody>

                                                            
                                                                 <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                  
                                                                 <td style={{border:'1px solid lightgray',}}>{ variationDataSecKPI.daily_estimate }</td>           
                                                                    <td style={{border:'1px solid lightgray',width:'30.33%'  }}>{ variationDataSecKPI.daily_average}</td>
                                                                   
                                                                    <td style={{border:'1px solid lightgray', }}> { variationDataSecKPI.yesterday_spend} </td>
                                                                   
                                                                 </tr>
                                                                

                                                                                         
                                                              </tbody>
                                                           </table>
                                                        </div>
                                                     </td>
                                                  </tr>
                                               </tbody>
                                            </table>
                                            :''}
                                      

</div>
:''}
                                            
</div>
}
</div>

      
      
      }

      {/**Data Table */}



       {/** Comments */}


    <div style={{display:'flex',flexDirection:'column',alignContent:'center', borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15}} >
        
        <div style={{backgroundColor:'#D9EAF6', padding:10,paddingTop:15, marginBottom:5}} onClick={handleShowComments}> 
            <b>Comments  </b> 
            <span style={{float:'right',color:'#4169e1'}} >
                {showComments ?
                  <KeyboardDoubleArrowDownIcon  /> 
                : <KeyboardDoubleArrowLeftIcon  />  }
                
                </span>
                                                             
        </div>


        <div style={{ backgroundColor:'aliceblue'}}>
      
       {showComments ? 
       <FormControl variant="outlined"  fullWidth style={{marginTop:2,marginBottom:25, backgroundColor:'white'}}>
                       {/** <TextField variant='outlined' fullWidth multiline minRows={15}
                     
                                id="demo-simple-select"
                                value={comments}
                                onChange={(e) => {setComments(e.target.value); setCommentsVal(false);}}
                                label={commentsVal ? <span style={{color:'red'}}>Please Add Comments</span> : "Add Comments"} 
                                
                                error={commentsVal}
                            
                /> */}
                <ReactQuill style={{height: 400}} modules={modules} theme="snow" value={comments} onChange={setComments} placeholder="Enter comments ...."/>
            
         <Button { ...cLoading ?  'disabled' : '' } onClick={addComments} style={{backgroundColor:'#4169e1',color:'white', marginTop:5}} >{ cLoading ?  <CircularProgress style={{color:'white'}} size="1.5rem" /> : 'Submit'}</Button>
        </FormControl>
        :''}
       

        <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                          

                                                                <CacheProvider value={cmuiCache}>
                                                                        <ThemeProvider theme={createTheme()}>
                                                                        
                                                                       <MUIDataTable
                                                                            // title={"Campaign Information"}   
                                                                            data={comments_data}
                                                                            columns={comments_columns}
                                                                            options={comments_options}
                                                                            
                                                                            
                                                                            /> 
                                                                        </ThemeProvider>
                                                                        </CacheProvider>
                                                             
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
   
    </div>


       


        </div>

      {/** Daily Table Continuous */}

       {/**Campaign Hireacrchy */}




       <div style={{alignContent:'center', marginTop:20, backgroundColor:'aliceblue', borderRadius:50, padding:30, fontSize:15}} >
        
        <div style={{display:'flex', justifyContent:'space-between', backgroundColor:'#D9EAF6',color:'black', padding:10, marginBottom:5}}> 
           <div><b>Master Campaign: </b><span style={{fontSize:'15px', cursor:'pointer'}}  onClick={(e) => masterCampaign(master_campaign_id_data)}> {master_campaign_name_data} <i class="las la-external-link-alt"></i></span> </div> 

            <div>
            <span style={{paddingTop:50, paddingLeft:20,color:'#4169e1'}} >
                {showMasterCampaign ?
                   <KeyboardDoubleArrowDownIcon style={{cursor:'hand'}} onClick={handleShowMasterCampaign}/> 
                :  <KeyboardDoubleArrowLeftIcon style={{cursor:'hand'}} onClick={handleShowMasterCampaign} />  }
                </span>
                </div>
           
                                                         
        </div>

       

       {showMasterCampaign ?
       <>
        <div>           
        <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div style={{height:'200px', overflow:'auto'}}>

                                                               {mLoading ? <> <LinearProgress  /> <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center'}}> loading data ..... Please Wait..</div> </>: 
                                                                  <table style={{width:'100%',border:'1px solid lightgray', height:'200px', overflowY:'scroll'}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'60%', fontWeight:'900' }}>Mediafy Campaign</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Start Date</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>End Date</td>
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                     

                                                                      {mediafyCampaign.map((key,index)=>{
                                                                        return(                                                                   
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'60%'}} ><span style={{cursor:'pointer'}} onClick={(e) => subCampaign(key._id)}>{key.sub_campaign_name} <i class="las la-external-link-alt"></i></span> </td> 
                                                                          {/** <td style={{border:'1px solid lightgray',width:'60%'}} >{key.sub_campaign_name} </td>*/}
                                                                           <td style={{border:'1px solid lightgray', }}>{ moment.utc(new Date(key.start_date)).local().format("YYYY-MM-DD")}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ moment.utc(new Date(key.end_date)).local().format("YYYY-MM-DD")}</td>
                                                                        </tr>
                                                                      
                                                                      )})}
                                                                     </tbody>
                                                                  </table>
                                                               }
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
        </div>
       
        <div>

        <table width="100%" style={{border:'1px solid lightgray',  backgroundColor:'white', marginTop:5}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                               <div style={{height:'200px', overflow:'auto'}}>

                                                               
                                                                  <table style={{width:'100%',border:'1px solid lightgray', height:'200px', overflowY:'scroll'}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'60%', fontWeight:'900' }}>Platform Campaign</td>
                                                                          
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                        {campaign_data.map((key,index)=>{

                                                                         

                                                                          
                                                                            return(

                                                                                <tr  style={{border:'1px solid lightgray', height:'5%'}} key={index}>
                                                                                    
                                                                                    <td style={{border:'1px solid lightgray',width:'60%' }}>{key.name}</td>
                                                                                    
                                                                                </tr>

                                                                            )
                                                                        })}
                                                                       
                                                                       
                                                                      
                                                                     </tbody>
                                                                  </table>
                                                               
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>

        </div>
        </>

         : '' }
                
        </div>


         
    
     {/**Action Data*/}



     <div style={{display:'flex',flexDirection:'column',alignContent:'center', borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15,  }}>
        
        <div style={{backgroundColor:'#D9EAF7',color:'black', padding:10, marginBottom:5, display:'flex', flexDirection:'row',justifyContent:'space-between' }}> 

         <div >
            <b>Checked Actions</b>

         </div>

         <div>
       
              <span style={{paddingTop:50, paddingLeft:20, color:'#4169e1'}} >
                {showCampaign ?
                 <KeyboardDoubleArrowDownIcon style={{cursor:'hand'}}  onClick={handleShowCampaign} /> 
                : 
                 <KeyboardDoubleArrowLeftIcon  style={{cursor:'hand'}}  onClick={handleShowCampaign} />
                }
                </span>
         </div>
         
                                                             
        </div>

       {showCampaign ?
        <div>           
        <table width="100%" style={{border:'1px solid lightgray', backgroundColor:'white'}}>
                                                      <tbody >
                                                       
                                                         <tr >
                                                            <td>
                                                          

                                                                <CacheProvider value={cmuiCache}>
                                                                        <ThemeProvider theme={createTheme()}>
                                                                        
                                                                       <MUIDataTable
                                                                            // title={"Campaign Information"}   
                                                                            data={actions_data}
                                                                            columns={actions_columns}
                                                                            options={actions_options}
                                                                            
                                                                            
                                                                            /> 
                                                                        </ThemeProvider>
                                                                        </CacheProvider>
                                                             
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
        </div>
       :''}

      


        </div>

    
  {/**Sub Campaign */}
                                                                         
    


    </div>
    </div>


    </>
  )
}

export default ListingDetails


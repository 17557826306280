import React,{useState,useEffect} from 'react';
//import { useNavigate } from "react-router-dom";
import Api from '../Service/network';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton, Button,Dialog, FormControl,TextField,Box, DialogTitle} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import AppBar from '../Layout/appBar';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import MUIDataTable from "mui-datatables";
import Header from '../Layout/header';
import Footer from '../Layout/footer';
import { isObject } from '@okta/okta-auth-js';
import Alert from '@mui/material/Alert';
import Listing from './listing';
import ListingDetails from './listingDetails';

const MasterCampaign = ({check}) => {
  
  const[listingDetail, setListingDetail] = useState(false);

  const[listingData,setListingData] = useState([]);

  const [countListing,setCountListing] = useState();


  const showListingDetails= (rowData) => {

   // console.log(rowData,"my row data")

    setListingDetail(true);
    setListingData(rowData)

   // alert(arg)
  }


  const hideListingDetails= () => {

    setListingDetail(false);

   // alert(arg)
  }


  const manageListing= (count) => {

    setCountListing(count);

  }

  return (
    
     
<>
<Header/>

      
<div className="main-content">
  
<AppBar/>


      
            
      {/** {listingDetail ? '' : <InfoCards  totalCampaign={countListing}/>} */} 

        
        
                   
            { listingDetail ? 
            <ListingDetails 
             check={hideListingDetails}          
             data={listingData}
            /> : 
            <Listing  
             check={showListingDetails} 
             count={manageListing} 
            /> }


         

     
        
   </div>
      
</>

  )
}

export default MasterCampaign
import React, { useState,useEffect } from 'react'
import Notification from '../../components/Layout/badge';



const AppBar = () => {

  const [userDetail, setUserDetail] = useState([]);

  useEffect(() =>{

    let user = JSON.parse(localStorage.getItem('user_details'));
    setUserDetail(user);
   
  
    

  },[])

  return (
    <div>


    <header>
            <h2>

                <label htmlFor='nav-toggle'>
                    <i className="las la-window-maximize" title="Maximize Screen"> </i>
                </label>
               
            </h2>

            <h4 style={{marginTop:10}}>Hi, {userDetail.name} </h4>

     
            {/**
            <div className="user-wrapper">
               
                <div>
                   <Notification />
                </div>

            </div>
             */}
        </header>
        
    </div>
  )
}

export default AppBar
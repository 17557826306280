import React,{useState,useEffect} from 'react';
//import { useNavigate } from "react-router-dom";
import Api from '../Service/network';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton, Button,Dialog, FormControl,TextField,Box, DialogTitle} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';

import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import AppBar from '../Layout/appBar';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import MUIDataTable from "mui-datatables";
import Header from '../Layout/header';
import Footer from '../Layout/footer';
import { isObject } from '@okta/okta-auth-js';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs'

const ArchiveListing = ({check}) => {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("700px");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(false);
  const [filterBtn, setFilterBtn] = useState(false);

  const [mError, setMerror] = useState(false);
  const [emsg, setEmsg] = useState('');

const [startDate, setStartDate] = useState('');
const [endDate, setEndDate] = useState('');

const [clientLoading,setClientLoading] = useState(false);
const [client,setClient] = useState([]); 
const [clientRes,setClientRes] = useState([]); 

const[listMasterCampaign,setListMasterCampaign] = useState([]);
const[masterCampaign, setMasterCampaign ] = useState();

const[totalBudget, setTotalBudget ] = useState();
const [dataLoading,setDataLoading] = useState(true);


const [listingReload, setListingReload] = useState();

const [campaignOwner,setCampaignOwner] = useState([]);
const [performanceManager,setPerformanceManager] = useState([]);
const [campaignBackup, setCampaignBackup]= useState([]);
const [teamLeader, setTeamLeader]= useState([]);
const [estimatedGoal, setEstimatedGoal] = useState();


const [users,setUsers] = useState([]);
const [campaignOwnerVal, setCampaignOwnerVal] = useState(false);
const [campaignBackupVal, setCampaignBackupVal] = useState(false);
const [performanceManagerVal, setPerformanceManagerVal] = useState(false);
const [teamLeaderVal, setTeamLeaderVal] = useState(false);
const [estimatedGoalVal, setEstimatedGoalVal] = useState(false);




const [goal, setGoal] = useState([]);
const [goalVal, setGoalVal] = useState(false);
const [goals, setGoals] = useState([]);




const [editMode, setEditMode] = useState(false);
const [mid,setMid] = useState();

const editMasterCampaign = (id) => {

const endpoint = process.env.REACT_APP_BASE_URL + "mastercampaign/"+id;

Api(endpoint,"GET")
.then(result => {

 console.log(result,"testes result")

 setMid(id);
 setMasterCampaign(result.data.master_campaign_name);
 setTotalBudget(result.data.total_budget);
 setClient(result.data.client);
 setStartDate(result.data.start_date);
 setEndDate(result.data.end_date);
 setCampaignOwner([result.data.campaign_owner]);
 setPerformanceManager([result.data.performance_manager]);
 setCampaignBackup([result.data.campaign_backup]);
 setTeamLeader([result.data.team_leader])
 setGoal([result.data.goal]);
 setEstimatedGoal(result.data.estimated_goal)

   
 }).catch((err)=>console.log(err))



 setEditMode(true);
 setOpen(true);



}

useEffect(()=>{

    
    const endpoint = process.env.REACT_APP_BASE_URL + "getAccounts";
 
    Api(endpoint,"POST", { platform: "Mediafy"})
    .then(result => {
      
    
      
      setClientRes(result.data.res);
      setClientLoading(false);
      
     }).catch((err)=>console.log(err))
     

},[])



useEffect(()=>{

  const getUserEmail = JSON.parse(localStorage.getItem('user_details'));
    const endpoint = process.env.REACT_APP_BASE_URL + "mastercampaign/archived/"+ getUserEmail.email;
  //  setClientLoading(true);
    Api(endpoint,"GET")
    .then(result => {
     
     
        setListMasterCampaign(result.data);
        setDataLoading(false);
      
     }).catch((err)=>console.log(err))
     

},[listingReload])




    const [open, setOpen] = React.useState(false);


  //  let navigate = useNavigate();


    const navigateAddMasterCampaign = () => {
      //  navigate('/addMasterCampaign');
    }


    const handleClickOpen = () => {
        setOpen(true);
      };
     
      const handleClose = () => {
        setOpen(false);
      //  window.location.reload();
     
      };


    const unarchiveMasterCampaign = (id) => {


      //console.log(e);
    let isExecuted = window.confirm("Are you sure you want to Un-Archive this Master Campaign?");
    if(isExecuted){

    const endpoint = process.env.REACT_APP_BASE_URL+"mastercampaign/unarchive/"+id;
   // setClientLoading(true);
     Api(endpoint,"PUT")
    .then(result => {


      if(result.data.status == 100 ){

        setMerror(true);
        setEmsg(result.data.message);
        
      }
      else{
         setListingReload([]);
      }

      
     }).catch((err)=>console.log(err))
    }

    }
    




    const addMasterCampaign = () => {
      setEditMode(false);
      setMasterCampaign('');
      setTotalBudget('');
      setClient('');
      setStartDate('');
      setEndDate('');
      setCampaignOwner([]);
      setPerformanceManager([]);
      setCampaignBackup([]);
      setTeamLeader([]);
      setGoal('');
      setEstimatedGoal([]);
      setOpen(true);

    }



    const deleteMasterCampaign = (id) => {
       

        let isExecuted = window.confirm("Are you sure you want to delete this Master Campaign?");


        if(isExecuted){

        const endpoint = process.env.REACT_APP_BASE_URL + "mastercampaign/delete/"+id;
       // setClientLoading(true);
         Api(endpoint,"PUT")
        .then(result => {
         

            if(result.data.status == 100 ){

              setMerror(true);
              setEmsg(result.data.message);
              
            }
            else{

            setMasterCampaign([]);
            setListingReload([])
            
            }
          
         }).catch((err)=>console.log(err))
        }
         
    }


    const[masterCampaignVal,setMasterCampaignVal] = useState(false);
    const[totalBudgetVal,setTotalBUdgetVal] = useState(false);
    const[clientVal,setClientVal] = useState(false);

   

    const submitEditMasterCampaign = () =>{


      if(campaignOwner == ""){
     
        setCampaignOwnerVal(true);

       }
       else if(performanceManager == ""){
      
        setPerformanceManagerVal(true);
       }
       else if(campaignBackup == ""){
       
         setCampaignBackupVal(true);
       }
       else if(teamLeader == ""){
       
        setTeamLeaderVal(true);
      }
      else if(goal == ""){
       
        setGoalVal(true);
      }
      else if(estimatedGoal == null){
       
        setEstimatedGoalVal(true);
      }
       else{

          const getUserEmail = JSON.parse(localStorage.getItem('user_details'));

            var myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/json");


            var raw= JSON.stringify({

            "master_campaign_name":masterCampaign,

            "total_budget":totalBudget,

            "client":client,

            "start_date":startDate,

            "end_date": endDate,

            "campaign_owner": campaignOwner[1] ? campaignOwner[1] : campaignOwner[0],

            "performance_manager" : performanceManager[1] ? performanceManager[1] : performanceManager[0],

            "campaign_backup": campaignBackup[1] ? campaignBackup[1] : campaignBackup[0],

            "team_leader" : teamLeader[1] ? teamLeader[1] : teamLeader[0],

            "goal" : goal[0],

            "estimated_goal": estimatedGoal,

            "user_id":getUserEmail.email

            });




            var requestOptions={

                method:'PUT',

                headers:myHeaders,

                body:raw,

                redirect:'follow'

            };




            fetch(process.env.REACT_APP_BASE_URL + "mastercampaign/update/"+mid, requestOptions)

            .then(response=>{
              
                setOpen(false);
                setMasterCampaign();
                setTotalBudget();
                setListingReload([]);
                
            })

            .catch(error=>console.log('error',error));

      }







    }



    const columns = [
      { name:"Advertiser", options: {
      
        // setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
         setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
         filter:true
        
        
         }
      },
  
      { name:"Master Campaign", options: {
      
        // setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
         setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
         customBodyRenderLite: (dataIndex) => {
          return (
            <a onClick={(e) => {

              check(data[dataIndex][3])

            }}>
           
            <span style={{cursor:'pointer'}} > {data[dataIndex][0]}</span> 
            </a>
          );
        }
        
        
        
         }
      },
  
      { name:"Total Budget", options: {
      
        // setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
         setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
        
        
         }
      },
      {
        name:"id", options: {display: false, viewColumns: false, filter: false,}
      },
      {
        name: "",
        options: {
          
          setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white',  }}),
          filter: false,
          viewColumns:false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            return (
              <a onClick={(e) => {
                e.stopPropagation()
                unarchiveMasterCampaign(data[dataIndex][3]);
              // console.log(data[dataIndex][3],"afds");
              }}>
              <span className="las la-archive" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
              </a>
            );
          }
        }
      }, 
      {
        name: "",
        options: {
          setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
          setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
       
          filter: false,
          viewColumns:false,
          sort: false,
          empty: true,
          
          customBodyRenderLite: (dataIndex) => {
            return (
              <a onClick={(e) => {
                e.stopPropagation()
                editMasterCampaign(data[dataIndex][3]);
              // console.log(data[dataIndex][3],"afds");
              }}>
              <span className="las la-edit" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
              </a>
            );
          }
        }
      },
     {
        name: "",
        options: {
          setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
          filter: false,
          viewColumns:false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            return (
              <a onClick={(e) => {
              e.stopPropagation()
              deleteMasterCampaign(data[dataIndex][3]);
              // console.log(data[dataIndex][3],"afds");
              }}>
              <span className="las la-trash" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
              </a>
            );
          }
        }
      }
  
     
      ];
  
       
    const options = {
      search: searchBtn,
      download: downloadBtn,
      print: printBtn,
      viewColumns: viewColumnBtn,
      filter: filterBtn,
      filterType: "dropdown",
      responsive,
      tableBodyHeight,
      tableBodyMaxHeight,
      selectableRows: 'none',
      responsive: "standard",
     
    
       
    };

    const data = listMasterCampaign.map((a,b)=>{return(
      [
      a.client,
      a.master_campaign_name,
      "€ "+a.total_budget.toLocaleString().replace(/,/g," ",).replace('.',','),
      a._id 
      
      ]
    )})


    useEffect(() => {


      const endpoint = process.env.REACT_APP_BASE_URL + "user/";
  
      Api(endpoint,"GET")
      .then(result => {
  
       
  
          setUsers(result.data);
  
  
       }).catch((err)=>console.log(err))
      
  
  
  
  },[])
  useEffect(() => {


    const endpoint = process.env.REACT_APP_BASE_URL + "config/GetMasterGoals/";

    Api(endpoint,"POST")
    .then(result => {

     

        setGoals(result.data.res);


     }).catch((err)=>console.log(err))
    



},[])
  


  return (
    
<>

        <main>
          {/**
         <Button variant="contained" startIcon={<AddIcon />} onClick={addMasterCampaign} style={{marginBottom:'10px',backgroundColor:'#4169e1', color:'white'}}  >
         Add Master Campaign
         </Button>
          */} 
        <div> 

        <div style={{marginBottom:10}}>
       {mError ? <Alert  onClose={() => { setMerror(false);}}  severity="error" color="error" variant='filled' style={{marginTop:10}}>{emsg}</Alert> : '' }
       </div>


            <div className="projects">

            <div className="card">
                  
            {dataLoading ? 
              <LinearProgress style={{marginBottom:5}} />
            :''}

            <ThemeProvider theme={createTheme()}>
            
              <MUIDataTable
              // title={"Campaign Information"}   
                data={data}
                columns={columns}
                options={options}
              
              
              />
          </ThemeProvider>

                    
                  {/**
                    <table width="100%">
                        <thead>
                            <tr>
                                <td width="30%">Advertiser</td>
                                <td width="40%">Master Campaign</td>
                                <td width="20%">Total Budget</td>
                               
                               <td></td>
                              

                            </tr>
                        </thead>
                        <tbody>
              
                           
                                {listMasterCampaign.map((key,index)=>{
                                    return(

                                    <tr style={{border:'1px solid lightgray'}}>
                                    <td style={{width:'10px'}}>{key.client}

                                    </td>
                                    <td >{key.master_campaign_name}</td>
                                    <td  >{key.total_budget}</td>
                                    <td>

                                        <DeleteForeverIcon  style={{color:'red',cursor:'hand'}} onClick={() => deleteMasterCampaign(key._id)} />
                                    </td>
                                   
                                </tr>

                                )})}
                              
                   
                        </tbody>
                    </table>
                     */}
           
            </div>
                
            </div>
            



            </div>
       

        </main>



     
        <Dialog
            open={open}
           // onClose={handleClose}
            fullWidth={true}
            maxWidth='md'
            
        >
      
        <div style={{display:'flex', justifyContent:'end'}}>
        <CloseIcon  onClick={handleClose} style={{padding:5, }}/>
        </div>
            <div style={{marginTop:"1%",padding:'4%',}}>

                
                <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>

             
                    
                <FormControl variant="outlined"    >
                                <TextField fullWidth variant="outlined"
                                    
                                        id="demo-simple-select"
                                        value={masterCampaign}
                                        onChange={(e) => {setMasterCampaign(e.target.value); setMasterCampaignVal(false)}}
                                        label={masterCampaignVal ?  <span style={{color:'red'}}>Please Enter Master Campaign Name</span> :"Master Campaign Name" }
                                        error={masterCampaignVal}       
                                        
                                       
                                     
                                />
                </FormControl>
                 


                <FormControl variant="outlined"  style={{marginTop:'20px'}}   >
                                <TextField fullWidth variant="outlined"
                                    
                                        id="demo-simple-select"
                                        value={totalBudget}
                                        onChange={(e) => {setTotalBudget(e.target.value); setTotalBUdgetVal(false) }}
                                        label={masterCampaignVal ?  <span style={{color:'red'}}>Please Enter Total Budget</span> :"Add  Total Budget" }
                                        type="number"
                                        error={totalBudgetVal}
                                    
                                    
                                />
                </FormControl>

                <FormControl variant="outlined"   style={{marginTop:'20px'}} >
                <Autocomplete
                          
                          value={client}
                          disablePortal
                          id="combo-box-demo"
                          options={clientRes}
                          getOptionLabel={(option) => option.clean_advertiser ? option.clean_advertiser: client}
                       
                          renderInput={(client) => <TextField {...client} label="Choose Advertiser"  variant="outlined" />}
                          onChange={(index, value) =>
                          { 
                            //  getCampaign(index, value.account_id,value.account_name);
                             // setClientVal(false);
                          
                             setClient(value.clean_advertiser);
                          
                          }}

                          disabled = {editMode ? true : false} 
                          
                  />

               
                </FormControl>




                <FormControl variant="outlined"  style={{marginTop:'30px', display:'flex', flexDirection:'row'}}   >
               
                  <div style={{fontSize:12, width:'50%', backgroundColor:'lightgray'}}>
                  <label style={{position:'absolute', bottom:45}}>Start Date: </label>
                  <input style={{padding:10,width:'100%' }}   type="date" onChange={(e) => setStartDate(e.target.value)} value={startDate ? moment.utc(new Date(startDate)).local().format("YYYY-MM-DD"): moment(new Date()).format("DD/MM/YYYY")} onKeyPress="return false" onKeyDown="return false"/>
                  </div>

                  &nbsp;&nbsp; 

                  <div style={{fontSize:12, width:'50%', backgroundColor:'lightgray'}}>
                  <label style={{position:'absolute', bottom:45}}>End Date: </label>
                  <input style={{padding:10,width:'100%' }}  type="date" onChange={(e) => setEndDate(e.target.value)} value={endDate ? moment.utc(new Date(endDate )).local().format("YYYY-MM-DD"): moment().format("DD/MM/YYYY")} onKeyPress="return false" onKeyDown="return false"/>
                  </div>

                </FormControl>



             <FormControl variant="outlined"  style={{marginTop:'20px', display:'flex', flexDirection:'row', justifyContent:'space-between'}}   >

                     <Autocomplete
                                    
                                    value={campaignOwner}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: campaignOwner[0]}
                       
                                   
                                    renderInput={(users) => <TextField {...users} label={campaignOwnerVal ? <span style={{color:'red'}}>Please Choose Campaign Owner</span> : "Campaign Owner"}   variant="outlined" />}
                                  
                                    onChange={(index, value) =>
                                        { 

                                           
                                            setCampaignOwner([value.FullName,value.LoginName]);
                                            setCampaignOwnerVal(false);
                                        
                                        }}
                                  
                                    error={campaignBackupVal}
                                    style={{width:"50%"}}
                            />

                            &nbsp;&nbsp;

                            <Autocomplete
                                    
                                    value={performanceManager}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: performanceManager[0]}

                                    onChange={(index, value) =>
                                        { 

                                            setPerformanceManager([value.FullName,value.LoginName]);
                                            setPerformanceManagerVal(false)
                                        
                                        }}
                                
                                    renderInput={(users) => <TextField {...users} label={performanceManagerVal ? <span style={{color:'red'}}>Please Choose Performance Manager</span> : "Performance Manager"}   variant="outlined" />}
                                  
                                    error={performanceManagerVal}
                                    style={{width:"50%"}}
                            />


                            
            
            </FormControl>

            <FormControl variant="outlined"  style={{marginTop:'20px', display:'flex', flexDirection:'row', justifyContent:'space-between'}}   >

                     <Autocomplete
                                    
                                    value={campaignBackup}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: campaignBackup[0]}

                                    onChange={(index, value) =>
                                        { 

                                           
                                            setCampaignBackup([value.FullName,value.LoginName]);
                                            setCampaignBackupVal(false)
                                        
                                        }}
                                
                                    renderInput={(users) => <TextField {...users} label={campaignBackupVal ? <span style={{color:'red'}}>Please Choose Campaign Backup</span> : "Campaign Backup"}   variant="outlined" />}
                                  
                                    error={campaignBackupVal}
                                    style={{width:"50%"}}
                            />

                            &nbsp;&nbsp;

                            <Autocomplete
                                    
                                    value={teamLeader }
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: teamLeader[0]}

                                    onChange={(index, value) =>
                                        { 

                                            setTeamLeader([value.FullName,value.LoginName]);
                                            setTeamLeaderVal(false);
                                        
                                        }}
                                
                                    renderInput={(users) => <TextField {...users} label={teamLeaderVal ? <span style={{color:'red'}}>Please Choose Team Leader</span> : "Team Leader"}   variant="outlined" />}
                                  
                                    error={teamLeaderVal}
                                    style={{width:"50%"}}
                            />


                            
            
            </FormControl>
         
         {console.log(goal)}

             <FormControl variant="outlined"  style={{marginTop:'20px', display:'flex', flexDirection:'row'}}>

                           <Autocomplete
                                    
                                    value={goal}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={goals}
                                    getOptionLabel={(option) => option.goal ? option.goal : goal[0]}
                                   
                                    onChange={(index, value) =>
                                      { 

                                          
                                          setGoal([value.goal]);
                                          setGoalVal(false);
                                      
                                      }}
                              
                                
                                    renderInput={(goal) => <TextField {...goal} label={goalVal ? <span style={{color:'red'}}>Please Choose Goal</span> : "Goal"}   variant="outlined" />}
                                    error={goalVal}
                                   
                                    style={{width:"50%",}}
                            />


                            &nbsp;&nbsp;
                            <TextField  variant="outlined"
                                    
                                    id="demo-simple-select"
                                    value={estimatedGoal}
                                    onChange={(e) => {setEstimatedGoal(e.target.value); setEstimatedGoalVal(false)}}
                                   
                                    type="number"
                                    error={estimatedGoalVal}
                                    label={estimatedGoalVal ? <span style={{color:'red'}}>Please Enter Estimated Goal</span> : "Estimated Goal"} 
                                   
                                    style={{ width:'50%',}}
                                    
                                
                                
                            />
            </FormControl>

         
                </div>

             
               
                
                

            </div>

          
            <div style={{display:'flex', flexDirection:'column', marginLeft:50, marginBottom:30, width:'90%', textAlign:'center'}}>

                {clientLoading ?
                <LinearProgress style={{marginBottom:5}} />
                :""}
      
                <Button variant='contained' onClick={submitEditMasterCampaign} style={{backgroundColor:'#4169e1', color:'white'}}>Edit Master Campaign</Button>
               
       

            </div>


         
          
            </Dialog>

        
  

</>

  )
}

export default ArchiveListing


import React, { useEffect, } from 'react'
import { useState } from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import moment from 'moment';

import LinearProgress from '@mui/material/LinearProgress';
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { Box,Tab,FormControl, InputLabel,Select,MenuItem, TextField, Checkbox, Button, TableCell, TableFooter, TableRow, TablePagination, makeStyles,} from '@material-ui/core';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { isObject } from '@okta/okta-auth-js';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Api from '../Service/network';

import EditMaster from './editMaster';


const ListingDetails = ({check,data}) => {

//console.log(data,"recevice in listing details page buddy")
const [mediafyCampaign,setMediafyCampaign] = useState([]);
const[mLoading,setMloading] = useState(false);

const [platformCampaign,setPlatformCampaign] = useState([]);
const[campaignId, setCampaignId] = useState([]);
const[pLoading,setPloading] = useState(false);


const[dtLoading,setDtloading] = useState(false);
const[scLoading,setScloading] = useState(false);



const [barBudgetColor,setBudgetBarColor] = useState();
const [barGoalColor,setGoalBarColor] = useState();


//const master_campaign_name = data[1];
//const advertiser_name = data[0];
//const total_budget = data[2];
//const master_campaign_id = data[3];



const searchParams = new URLSearchParams(document.location.search);
const cid = searchParams.get('cid');
const master_campaign_id = cid ? cid : data;


const [master_campaign_name, set_master_campaign_name] = useState();
const [advertiser_name, set_advertiser_name] = useState();
const [total_budget, set_total_budget ]= useState();

const [campaignOwner, setCampaignOwner ]= useState();
const [performanceManager, setPerformanceManager ]= useState();
const [campaignBackup, setCampaignBackup ]= useState();
const [teamLeader, setTeamLeader ]= useState();

const [budget,setBudget] = useState([]);
const [days,setDays] = useState([]);
const[isSubCampaign, setIsSubCampaign] = useState([]);


const [listingReload, setListingReload] = useState();

useEffect(()=>{


   setDtloading(true);
   const endpoint = process.env.REACT_APP_BASE_URL + "mastercampaign/"+master_campaign_id;
   Api(endpoint,"GET")
   .then(result => {

       set_master_campaign_name(result.data.master_campaign_name);
       set_advertiser_name(result.data.client);
       set_total_budget(result.data.total_budget);
       setCampaignOwner(result.data.campaign_owner)
       setPerformanceManager(result.data.performance_manager);
       setCampaignBackup(result.data.campaign_backup);
       setTeamLeader(result.data.team_leader)

     
      }).catch((err)=>console.log(err))
 
          

  

},[listingReload])

useEffect(()=>{


        setDtloading(true);
        const endpoint = process.env.REACT_APP_BASE_URL + "mastercampaign/data/"+master_campaign_id;
        Api(endpoint,"GET")
        .then(result => {


            Object.keys(result.data).length > 1 ? setIsSubCampaign(true) : setIsSubCampaign(false) ;
            setBudget(result.data.budget)
            setDays(result.data.days)
            setDtloading(false);
   
          
           }).catch((err)=>console.log(err))
      
               
   
       
   
    },[])

const [subCampaignList,setSubCampaignList] = useState([]);

useEffect(()=>{


    setScloading(true);
   const endpoint = process.env.REACT_APP_BASE_URL + "subCampaign/master/"+master_campaign_id;
    Api(endpoint,"GET")
    .then(result => {


        setSubCampaignList(result.data);
        setScloading(false);

      
       }).catch((err)=>console.log(err))
  
           

   

},[])

const history = useHistory();



const subCampaign = (id) => {
   
    history.push({
        pathname: '/subCampaignListing',
        search: '?cid='+id,
      }) 


}


const [open, setOpen] = React.useState(false);
const [mcid, setMcid] = useState();


const editMasterCampaign = (id) => {

   console.log(id,"get my id")

   setMcid(id);
   setOpen(true);
  
  
  
  }


      
  const handleClose = () => {
   setOpen(false);


 };

       
 const handleReload = () => {
   

   setListingReload([]);


 };


return (
    <>

   
   
    <div className="projects">

    {/**<div style={{fontSize:12, margin:5, display:'flex', justifyContent:'right'}}>Last updated at: </div> */}
   
    <div className="card" style={{padding:'20px'}}>

        <div style={{backgroundColor:'#4169e1', borderRadius:50,  display:'flex', justifyContent:'space-between' , padding:10,}}>
        
        <span style={{fontSize:15, paddingLeft:5, color:'white'}}><b>Master Campaign: {master_campaign_name}</b></span>
        <div>
        <span  className="las la-edit"   onClick={e => editMasterCampaign(master_campaign_id) }  style={{cursor:'pointer',color:'white', padding:0,marginRight:15, fontSize:23}} ></span> 
        <span  onClick={check} className="las la-arrow-alt-circle-left" style={{fontSize:25, cursor:'pointer', color:'white'}}></span> 
        </div>
      

        </div>

      

    
     {/** Sub Campaign*/}



     <div style={{display:'flex',flexDirection:'column',alignContent:'center', borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15,  }}>
        

        <div>           
     
                                                                  <table style={{width:'100%', height:'50px'}}>
                                                                    
                                                                     <tbody>

                                                                                                                                  
                                                                       <tr >
                                                                        <td style={{width:'12%'}}> <b>Advertiser</b> </td>
                                                                        <td style={{width:'40%'}}>{advertiser_name}</td>

                                                                        <td  ><b>Total Budget</b> </td>
                                                                        <td >{total_budget}</td>
                                                                       
                                                                       </tr>
                                                                       <tr>
                                                                       <td  ><b>Camapign Owner</b> </td>
                                                                        <td >{campaignOwner}</td>


                                                                        <td  ><b>Performance Manager</b> </td>
                                                                        <td >{performanceManager}</td>
                                                                       </tr>

                                                                       <tr>
                                                                       <td  ><b>Camapign Backup</b> </td>
                                                                        <td >{campaignBackup}</td>


                                                                        <td  ><b>Team Leader</b> </td>
                                                                        <td >{teamLeader}</td>
                                                                       </tr>



                                                                       
                                                                       
                       
                                                                     </tbody>
                                                                  </table>
                                                           
        </div>
      
     </div> 

  

        <div style={{display:'flex',flexDirection:'column',alignContent:'center',  borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15}}>
        
        {isSubCampaign ?
        <div>  

        {dtLoading ? <> <LinearProgress  /> <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center'}}> loading data ..... Please Wait..</div> </>:   
         <>
         <div style={{display:'flex', flexDirection:'row'}}>
            <div className='dt_box'>

                

               <div> DAYS UNTIL THE END</div> 
               <div style={{fontSize:40, fontWeight:600}}>{days.left}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div></div>
                  <div>&nbsp;</div>
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div></div>
                  <div>&nbsp;</div>
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5}}>
                  <div className='dt_box_label'>Total</div>
                  <div>{days.Total}</div>
               </div>

            

               <div style={{display:'flex', flexDirection:'column !important', justifyContent:'space-between', paddingTop:15, padding:5}}>
                     <div style={{width:"82%", paddingTop:8}}>
                     <LinearProgress 
                        // sx={{ 
                        //   '& .MuiLinearProgress-bar': {
                        //  backgroundColor: 'red'
                        // }}}
                        variant="determinate" value={days.percentage > 100 ? 100 : days.percentage} />
                     </div>
                     
                     <div>{days.percentage}%</div>
               </div>
                   
            
       
            </div>
            <div className='dt_box'> 
             <div> BUDGET LEFT PER DAY</div>
             <div style={{fontSize:40, fontWeight:600}}>{budget.left_per_days}</div>
               

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', marginTop:30,padding:5}}>
                  <div className='dt_box_label'>Spent until Yesterday</div>
                  <div>{budget.spend}</div>
                 
               </div>

               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5 }}>
               <div className='dt_box_label'>Left until Total</div>
               <div>{budget.left}</div>
                  
               </div>
               <hr/>
               <div style={{ display:'flex', flexDirection:'column !important', justifyContent:'space-between', padding:5}}>
                  <div className='dt_box_label'>Total</div>
                  <div>{budget.total}</div>
               </div>

            

               <div style={{display:'flex', flexDirection:'column !important', justifyContent:'space-between', paddingTop:15, padding:5}}>
                     <div style={{width:"82%", paddingTop:8}}>

                    
                     <LinearProgress 
                         sx={{ 
                           '& .MuiLinearProgress-bar': {
                             backgroundColor: barBudgetColor
                         }}}
                         variant="determinate" value={budget.percentage > 100 ? 100 : budget.percentage} />
                     </div>
                     
                     <div>{budget.percentage}%</div>
               </div>
            
            
            </div>
          
           
         </div>

         

         </>
                                       
         }
        </div>
        : <div style={{textAlign:'center',color:'red'}}>Sorry, there is no subcampaign associated with this master campaign</div> }
        
        </div>

    
  {/**Sub Campaign */}

  {/**Lis Sub Campaign */}

  {isSubCampaign ?
  <div style={{display:'flex',flexDirection:'column',alignContent:'center', borderRadius:50, marginTop:20, backgroundColor:'aliceblue', padding:30, fontSize:15,  }}>
        

        {scLoading ? <> <LinearProgress  /> <div style={{fontSize:12, margin:10, display:'flex', justifyContent:'center',alignContent:'center'}}> loading data ..... Please Wait..</div> </>:   
    
        <div>           
     
        <table style={{width:'100%',border:'1px solid lightgray', height:'200px', overflowY:'scroll',backgroundColor:'white'}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray', }}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'60%', fontWeight:'900' }}>Sub Campaign ({subCampaignList.length})</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Start Date</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>End Date</td>
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                     

                                                                        {subCampaignList.map((key,index)=>{ 
                                                                        return(                                                           
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                         
                                                                           <td style={{border:'1px solid lightgray',width:'60%'}} onClick={(e) => subCampaign(key._id)}><span style={{cursor:'pointer'}}>{key.sub_campaign_name} <i class="las la-external-link-alt"></i></span></td> 
                                                                           <td style={{border:'1px solid lightgray', }}>{ moment.utc(new Date(key.start_date)).local().format("YYYY-MM-DD")}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{ moment.utc(new Date(key.end_date)).local().format("YYYY-MM-DD")}</td>
                                                                        </tr>
                                                                        )})}
                                                                    
                                                                     </tbody>
                                                                  </table>
                                                           
        </div>
        }
    
      
     </div> 
    :''}

  {/**Lis Sub Campaign */}
  
                
   </div>
   </div>

   {open ?
        <EditMaster cid={mcid} open={open} handleClose={handleClose}  handleReload={handleReload} editMode={true}/>
   : '' }

   
    </>
  )
}

export default ListingDetails
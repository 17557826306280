import React,{useState, useEffect} from 'react'
import Layout from '../Layout'

import AppBar from '../Layout/appBar';

import Header from '../Layout/header';
import Footer from '../Layout/footer';
import ArchiveListing from './archiveListing';
import ArchiveListingDetails from './archiveListingDetails';
import ListingDetails from './listingDetails';
import InfoCards from './infoCards';
import BackupListing from './backupListing';


const Backup = () => {


  const[listingDetail, setListingDetail] = useState(false);

  const[listingData,setListingData] = useState([]);

  const [countListing,setCountListing] = useState();


  const showListingDetails= (rowData) => {

   // console.log(rowData,"my row data")

    setListingDetail(true);
    setListingData(rowData)

   // alert(arg)
  }


  const hideListingDetails= () => {

    setListingDetail(false);

   // alert(arg)
  }


  const manageListing= (count) => {

    setCountListing(count);

  }

  return (
    
   
<>
<Header/>

      
<div className="main-content">
  
<AppBar/>


        <main>
            
            {/** {listingDetail ? '' : <InfoCards  totalCampaign={countListing}/>} */} 
             
            {listingDetail ? 
            <ListingDetails 
             check={hideListingDetails}          
             data={listingData}
            /> : 
            <BackupListing
             check={showListingDetails} 
             count={manageListing}      
              /> }
           

             


        </main>
        
        
   </div>
      
<Footer/>
</>
  )
}

export default Backup
import React,{useState,useEffect} from 'react';
//import { useNavigate } from "react-router-dom";
import Api from '../Service/network';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton, Button,Dialog, FormControl,TextField,Box, DialogTitle} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';

import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import AppBar from '../Layout/appBar';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import MUIDataTable from "mui-datatables";
import Header from '../Layout/header';
import Footer from '../Layout/footer';
import { isObject } from '@okta/okta-auth-js';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';


export default function EditMaster({cid,open,handleClose,handleReload,editMode}) {


  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("700px");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(false);
  const [filterBtn, setFilterBtn] = useState(false);

  const [mError, setMerror] = useState(false);
  const [emsg, setEmsg] = useState('');

const [startDate, setStartDate] = useState('');
const [endDate, setEndDate] = useState('');

const [clientLoading,setClientLoading] = useState(false);
const [client,setClient] = useState([]); 
const [clientRes,setClientRes] = useState([]); 

const[listMasterCampaign,setListMasterCampaign] = useState([]);
const[masterCampaign, setMasterCampaign ] = useState();

const[totalBudget, setTotalBudget ] = useState();
const [dataLoading,setDataLoading] = useState(true);


const [listingReload, setListingReload] = useState();


const [campaignOwner,setCampaignOwner] = useState([]);
const [performanceManager,setPerformanceManager] = useState([]);
const [campaignBackup, setCampaignBackup]= useState([]);
const [teamLeader, setTeamLeader]= useState([]);
const [estimatedGoal, setEstimatedGoal] = useState();


const [users,setUsers] = useState([]);
const [campaignOwnerVal, setCampaignOwnerVal] = useState(false);
const [campaignBackupVal, setCampaignBackupVal] = useState(false);
const [performanceManagerVal, setPerformanceManagerVal] = useState(false);
const [teamLeaderVal, setTeamLeaderVal] = useState(false);
const [estimatedGoalVal, setEstimatedGoalVal] = useState(false);




const [goal, setGoal] = useState([]);
const [goalVal, setGoalVal] = useState(false);
const [goals, setGoals] = useState([]);

const[startDateValidate,setStartDateValidate] = useState(true);
const[endDateValidate,setEndDateValidate] = useState(true);


const [mid,setMid] = useState();


const submitMasterCampaign = () => {

        

        if(masterCampaign == ""){
       
    
         setMasterCampaignVal(true);

        }
        else if(totalBudget == ""){
       
         setTotalBUdgetVal(true);

        }
        else if(client == ""){
        
          setClientVal(true);
        }
        else if(startDate == ""){
           
           setStartDateValidate(false);
        }
        else if(endDate == ""){
           
         setEndDateValidate(false);
      }
        else if(campaignOwner == undefined){
       
         setCampaignOwnerVal(true);
 
        }
        else if(performanceManager == undefined){
        
         setPerformanceManagerVal(true);
        }
        else if(campaignBackup == undefined){
      
          setCampaignBackupVal(true);
        }
        else if(teamLeader == undefined){
        
         setTeamLeaderVal(true);
       }
       else if(goal == undefined){
        
         setGoalVal(true);
       }
       else if(estimatedGoal == null){
     
         setEstimatedGoalVal(true);
       }
       
        else{
        
         const getUserEmail = JSON.parse(localStorage.getItem('user_details'));
  
                   setClientLoading(true)

                   var myHeaders = new Headers();

                   myHeaders.append("Content-Type", "application/json");


                   var raw= JSON.stringify({

                   "master_campaign_name":masterCampaign,

                   "total_budget":totalBudget,

                   "client":client,

                   "start_date":startDate,

                   "end_date": endDate,

                   "campaign_owner": campaignOwner[1],

                   "performance_manager" : performanceManager[1],

                   "campaign_backup": campaignBackup[1],

                   "team_leader" : teamLeader[1],

                   "goal" : goal[0],

                   "estimated_goal": estimatedGoal,
                   
                   "user_id":getUserEmail.email

                   });




                   var requestOptions={

                       method:'POST',

                       headers:myHeaders,

                       body:raw,

                       redirect:'follow'

                   };




                   fetch(process.env.REACT_APP_BASE_URL + "extractMasterCampaign", requestOptions)

                   .then(response=>{
                       setClientLoading(false);
                       handleClose(false);
                       setMasterCampaign();
                       setTotalBudget();
                       handleReload();
                       
                   })

                   .catch(error=>console.log('error',error));

         }




   }

   useEffect(()=>{

    
    const endpoint = process.env.REACT_APP_BASE_URL + "getAccounts";
 
    Api(endpoint,"POST", { platform: "Mediafy"})
    .then(result => {
      
    
      
      setClientRes(result.data.res);
      setClientLoading(false);
      
     }).catch((err)=>console.log(err))
     

},[])



useEffect(()=> {

if(editMode){
const endpoint = process.env.REACT_APP_BASE_URL + "mastercampaign/"+cid;

Api(endpoint,"GET")
.then(result => {


        setMid(cid);
        setMasterCampaign(result.data.master_campaign_name);
        setTotalBudget(result.data.total_budget);
        setClient(result.data.client);
        setStartDate(result.data.start_date ? result.data.start_date : '');
        setEndDate(result.data.end_date ? result.data.end_date : '');
        setCampaignOwner([result.data.campaign_owner ? result.data.campaign_owner : '']);
        setPerformanceManager([result.data.performance_manager ? result.data.performance_manager :'' ]);
        setCampaignBackup([result.data.campaign_backup ? result.data.campaign_backup : '']);
        setTeamLeader([result.data.team_leader ? result.data.team_leader : ''])
        setGoal([result.data.goal ? result.data.goal : '']);
        setEstimatedGoal(result.data.estimated_goal ? result.data.estimated_goal : '')

   
 }).catch((err)=>console.log(err))

}

 //setEditMode(true);
// setOpen(true);



},[])



    const[masterCampaignVal,setMasterCampaignVal] = useState(false);
    const[totalBudgetVal,setTotalBUdgetVal] = useState(false);
    const[clientVal,setClientVal] = useState(false);

   

    const submitEditMasterCampaign = () =>{


      if(campaignOwner == ""){
     
        setCampaignOwnerVal(true);

       }
       else if(performanceManager == ""){
      
        setPerformanceManagerVal(true);
       }
       else if(campaignBackup == ""){
       
         setCampaignBackupVal(true);
       }
       else if(teamLeader == ""){
       
        setTeamLeaderVal(true);
      }
      else if(goal == ""){
       
        setGoalVal(true);
      }
      else if(estimatedGoal == ""){
       
        setEstimatedGoalVal(true);
      }
       else{


          const getUserEmail = JSON.parse(localStorage.getItem('user_details'));

            var myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/json");


            var raw= JSON.stringify({

            "master_campaign_name":masterCampaign,

            "total_budget":totalBudget,

            "client":client,

            "start_date":startDate,

            "end_date": endDate,

            "campaign_owner": campaignOwner[1] ? campaignOwner[1] : campaignOwner[0],

            "performance_manager" : performanceManager[1] ? performanceManager[1] : performanceManager[0],

            "campaign_backup": campaignBackup[1] ? campaignBackup[1] : campaignBackup[0],

            "team_leader" : teamLeader[1] ? teamLeader[1] : teamLeader[0],

            "goal" : goal[0],

            "estimated_goal": estimatedGoal,

            "user_id":getUserEmail.email

            });




            var requestOptions={

                method:'PUT',

                headers:myHeaders,

                body:raw,

                redirect:'follow'

            };




            fetch(process.env.REACT_APP_BASE_URL + "mastercampaign/update/"+mid, requestOptions)

            .then(response=>{
              
                handleClose(false);
                setMasterCampaign();
                setTotalBudget();
                handleReload();
                
            })

            .catch(error=>console.log('error',error));

      }







    }



    useEffect(() => {


        const endpoint = process.env.REACT_APP_BASE_URL + "user/";
    
        Api(endpoint,"GET")
        .then(result => {
    
         
    
            setUsers(result.data);
    
    
         }).catch((err)=>console.log(err))
        
    
    
    
    },[])


  useEffect(() => {


    const endpoint = process.env.REACT_APP_BASE_URL + "config/GetMasterGoals/";

    Api(endpoint,"POST")
    .then(result => {

     

        setGoals(result.data.res);


     }).catch((err)=>console.log(err))
    



},[]);



  

  
  return (
    <>

    <div>

    <Dialog
            open={open}
           // onClose={handleClose}
            fullWidth={true}
            maxWidth='md'
            
        >
      
        <div style={{display:'flex', justifyContent:'end'}}>
        <CloseIcon  onClick={handleClose} style={{padding:5, }}/>
        </div>
            <div style={{marginTop:"1%",padding:'4%',}}>

                
                <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>

             
                    
                <FormControl variant="outlined"    >
                                <TextField fullWidth variant="outlined"
                                    
                                        id="demo-simple-select"
                                        value={masterCampaign}
                                        onChange={(e) => {setMasterCampaign(e.target.value); setMasterCampaignVal(false)}}
                                        label={masterCampaignVal ?  <span style={{color:'red'}}>Please Enter Master Campaign Name</span> :"Master Campaign Name" }
                                        error={masterCampaignVal}       
                                        
                                       
                                     
                                />
                </FormControl>
                 


                <FormControl variant="outlined"  style={{marginTop:'20px'}}   >
                                <TextField fullWidth variant="outlined"
                                    
                                        id="demo-simple-select"
                                        value={totalBudget}
                                        onChange={(e) => {setTotalBudget(e.target.value); setTotalBUdgetVal(false) }}
                                        label={totalBudgetVal ?  <span style={{color:'red'}}>Please Enter Total Budget</span> :"Add  Total Budget" }
                                        type="number"
                                        error={totalBudgetVal}
                                    
                                    
                                />
                </FormControl>

                <FormControl variant="outlined"   style={{marginTop:'20px'}} >
                <Autocomplete
                          
                          value={client}
                          disablePortal
                          id="combo-box-demo"
                          options={clientRes}
                          getOptionLabel={(option) => option.clean_advertiser ? option.clean_advertiser: client}
                       
                          renderInput={(client) => <TextField {...client} label={clientVal ? <span style={{color:'red'}}>Please Choose Advertiser</span>   :"Choose Advertiser"}  variant="outlined" />}
                          onChange={(index, value) =>
                          { 
                            //  getCampaign(index, value.account_id,value.account_name);


                              setClientVal(false);
                          
                             setClient(value.clean_advertiser);
                          
                          }}
                          error={clientVal}
                          disabled = {editMode ? true : false} 
                          
                  />

               
                </FormControl>




                <FormControl variant="outlined"  style={{marginTop:'30px', display:'flex', flexDirection:'row'}}   >
               
                  <div style={{fontSize:12, width:'50%', backgroundColor:'lightgray'}}>

                   {startDateValidate ? 
                     <label style={{position:'absolute', bottom:45}}> Start Date : </label>
                  :  <label style={{position:'absolute', bottom:45, color:'red'}}> Select Start Date: </label> }
                  <input style={{padding:10,width:'100%' }}   type="date" onChange={(e) => {setStartDate(e.target.value); setStartDateValidate(true)}} value={startDate ? moment.utc(new Date(startDate)).local().format("YYYY-MM-DD"): ''} onKeyPress="return false" onKeyDown="return false"/>
                  </div>

                  &nbsp;&nbsp; 

                  <div style={{fontSize:12, width:'50%', backgroundColor:'lightgray'}}>
                  {endDateValidate ? 
                     <label style={{position:'absolute', bottom:45}}> End Date : </label>
                  :  <label style={{position:'absolute', bottom:45, color:'red'}}> Select End Date: </label> }
                  <input style={{padding:10,width:'100%' }}  type="date" onChange={(e) =>  {setEndDate(e.target.value); setEndDateValidate(true)}} value={endDate ? moment.utc(new Date(endDate )).local().format("YYYY-MM-DD"): moment().format("DD/MM/YYYY")} onKeyPress="return false" onKeyDown="return false"/>
                  </div>

                </FormControl>



             <FormControl variant="outlined"  style={{marginTop:'20px', display:'flex', flexDirection:'row', justifyContent:'space-between'}}   >

                     <Autocomplete
                                    
                                    value={campaignOwner}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: campaignOwner}
                       
                                   
                                    renderInput={(users) => <TextField {...users} label={campaignOwnerVal ? <span style={{color:'red'}}>Please Choose Campaign Owner</span> : "Campaign Owner"}   variant="outlined" />}
                                  
                                    onChange={(index, value) =>
                                        { 

                                           
                                            setCampaignOwner([value.FullName,value.LoginName]);
                                            setCampaignOwnerVal(false);
                                        
                                        }}
                                  
                                    error={campaignBackupVal}
                                    style={{width:"50%"}}
                            />

                            &nbsp;&nbsp;

                            <Autocomplete
                                    
                                    value={performanceManager}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: performanceManager}

                                    onChange={(index, value) =>
                                        { 

                                            setPerformanceManager([value.FullName,value.LoginName]);
                                            setPerformanceManagerVal(false)
                                        
                                        }}
                                
                                    renderInput={(users) => <TextField {...users} label={performanceManagerVal ? <span style={{color:'red'}}>Please Choose Performance Manager</span> : "Performance Manager"}   variant="outlined" />}
                                  
                                    error={performanceManagerVal}
                                    style={{width:"50%"}}
                            />


                            
            
            </FormControl>

            <FormControl variant="outlined"  style={{marginTop:'20px', display:'flex', flexDirection:'row', justifyContent:'space-between'}}   >

                     <Autocomplete
                                    
                                    value={campaignBackup}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: campaignBackup}

                                    onChange={(index, value) =>
                                        { 

                                           
                                            setCampaignBackup([value.FullName,value.LoginName]);
                                            setCampaignBackupVal(false)
                                        
                                        }}
                                
                                    renderInput={(users) => <TextField {...users} label={campaignBackupVal ? <span style={{color:'red'}}>Please Choose Campaign Backup</span> : "Campaign Backup"}   variant="outlined" />}
                                  
                                    error={campaignBackupVal}
                                    style={{width:"50%"}}
                            />

                            &nbsp;&nbsp;

                            <Autocomplete
                                    
                                    value={teamLeader}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: teamLeader}

                                    onChange={(index, value) =>
                                        { 

                                            setTeamLeader([value.FullName,value.LoginName]);
                                            setTeamLeaderVal(false);
                                        
                                        }}
                                
                                    renderInput={(users) => <TextField {...users} label={teamLeaderVal ? <span style={{color:'red'}}>Please Choose Team Leader</span> : "Team Leader"}   variant="outlined" />}
                                  
                                    error={teamLeaderVal}
                                    style={{width:"50%"}}
                            />


                            
            
            </FormControl>
         
     

             <FormControl variant="outlined"  style={{marginTop:'20px', display:'flex', flexDirection:'row'}}>

                           <Autocomplete
                                    
                                    value={goal}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={goals}
                                    getOptionLabel={(option) => option.goal ? option.goal : goal}
                                   
                                    onChange={(index, value) =>
                                      { 

                                          
                                          setGoal([value.goal]);
                                          setGoalVal(false);
                                      
                                      }}
                              
                                
                                    renderInput={(goal) => <TextField {...goal} label={goalVal ? <span style={{color:'red'}}>Please Choose Goal</span> : "Goal"}   variant="outlined" />}
                                    error={goalVal}
                                   
                                    style={{width:"50%",}}
                            />


                            &nbsp;&nbsp;
                            <TextField  variant="outlined"
                                    
                                    id="demo-simple-select"
                                    value={estimatedGoal }
                                    onChange={(e) => {setEstimatedGoal(e.target.value ); setEstimatedGoalVal(false)}}
                                   
                                    type="number"
                                    error={estimatedGoalVal}
                                    label={estimatedGoalVal ? <span style={{color:'red'}}>Please Enter Estimated Goal</span> : "Estimated Goal"} 
                                   
                                    style={{ width:'50%',}}
                                    
                                
                                
                            />
            </FormControl>

         
                </div>

             
               
                
                

            </div>

          
            <div style={{display:'flex', flexDirection:'column', marginLeft:50, marginBottom:30, width:'90%', textAlign:'center'}}>

                {clientLoading ?
                <LinearProgress style={{marginBottom:5}} />
                :""}
      

                {editMode ? 
                <Button variant='contained' onClick={submitEditMasterCampaign} style={{backgroundColor:'#4169e1', color:'white'}}>Edit Master Campaign</Button>
                :
                <Button variant='contained' onClick={submitMasterCampaign} style={{backgroundColor:'#4169e1', color:'white'}}>Add Master Campaign</Button>
                 }
               

            </div>


         
          
        </Dialog>
        

    </div>


    </>
  );
}
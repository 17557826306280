import React,{useState,useEffect} from 'react';
//import { useNavigate } from "react-router-dom";
import Api from '../Service/network';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton, Button,Dialog, FormControl,TextField,Box, DialogTitle} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';

import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import AppBar from '../Layout/appBar';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import MUIDataTable from "mui-datatables";
import Header from '../Layout/header';
import Footer from '../Layout/footer';
import { isObject } from '@okta/okta-auth-js';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import EditMaster from './editMaster';


const Listing = ({check}) => {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("700px");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(false);
  const [filterBtn, setFilterBtn] = useState(false);

  const [mError, setMerror] = useState(false);
  const [emsg, setEmsg] = useState('');

const [startDate, setStartDate] = useState('');
const [endDate, setEndDate] = useState('');

const [clientLoading,setClientLoading] = useState(false);
const [client,setClient] = useState([]); 
const [clientRes,setClientRes] = useState([]); 

const[listMasterCampaign,setListMasterCampaign] = useState([]);
const[masterCampaign, setMasterCampaign ] = useState();

const[totalBudget, setTotalBudget ] = useState();
const [dataLoading,setDataLoading] = useState(true);


const [listingReload, setListingReload] = useState();


const [campaignOwner,setCampaignOwner] = useState([]);
const [performanceManager,setPerformanceManager] = useState([]);
const [campaignBackup, setCampaignBackup]= useState([]);
const [teamLeader, setTeamLeader]= useState([]);
const [estimatedGoal, setEstimatedGoal] = useState();


const [users,setUsers] = useState([]);
const [campaignOwnerVal, setCampaignOwnerVal] = useState(false);
const [campaignBackupVal, setCampaignBackupVal] = useState(false);
const [performanceManagerVal, setPerformanceManagerVal] = useState(false);
const [teamLeaderVal, setTeamLeaderVal] = useState(false);
const [estimatedGoalVal, setEstimatedGoalVal] = useState(false);




const [goal, setGoal] = useState([]);
const [goalVal, setGoalVal] = useState(false);
const [goals, setGoals] = useState([]);

const[startDateValidate,setStartDateValidate] = useState(true);
const[endDateValidate,setEndDateValidate] = useState(true);


const [editMode, setEditMode] = useState(false);
const [mid,setMid] = useState();
const [cid, setCid] = useState();

const editMasterCampaign = (id) => {


 setCid(id);
 setEditMode(true);
 setOpen(true);



}

useEffect(()=>{

    
    const endpoint = process.env.REACT_APP_BASE_URL + "getAccounts";
 
    Api(endpoint,"POST", { platform: "Mediafy"})
    .then(result => {
      
    
      
      setClientRes(result.data.res);
      setClientLoading(false);
      
     }).catch((err)=>console.log(err))
     

},[])



useEffect(()=>{

  const getUserEmail = JSON.parse(localStorage.getItem('user_details'));
    const endpoint = process.env.REACT_APP_BASE_URL + "mastercampaign/email/"+ getUserEmail.email;
  //  setClientLoading(true);
    Api(endpoint,"GET")
    .then(result => {
        setListMasterCampaign(result.data);
        setDataLoading(false);
      
     }).catch((err)=>console.log(err))
     

},[listingReload])




    const [open, setOpen] = React.useState(false);


  //  let navigate = useNavigate();


    const navigateAddMasterCampaign = () => {
      //  navigate('/addMasterCampaign');
    }


    const handleClickOpen = () => {
        setOpen(true);
      };
     
      const handleClose = () => {
        setOpen(false);
      //  window.location.reload();
     
      };

      const handleReload = () => {
      
        setListingReload([]);
     
      };


    const archiveMasterCampaign = (id) => {


      //console.log(e);
    let isExecuted = window.confirm("Are you sure you want to Archive this Master Campaign?");
    if(isExecuted){

    const endpoint = process.env.REACT_APP_BASE_URL+"mastercampaign/archive/"+id;
   // setClientLoading(true);
     Api(endpoint,"PUT")
    .then(result => {


      if(result.data.status == 100 ){

        setMerror(true);
        setEmsg(result.data.message);
        
      }
      else{
         setListingReload([]);
      }

      
     }).catch((err)=>console.log(err))
    }

    }
    

    const addMasterCampaign = () => {
      setEditMode(false);
      setMasterCampaign('');
      setTotalBudget('');
      setClient('');
      setStartDate('');
      setEndDate('');
      setCampaignOwner();
      setPerformanceManager();
      setCampaignBackup();
      setTeamLeader();
      setGoal();
      setEstimatedGoal();
      setOpen(true);

    }



    const deleteMasterCampaign = (id) => {
       

        let isExecuted = window.confirm("Are you sure you want to delete this Master Campaign?");


        if(isExecuted){

        const endpoint = process.env.REACT_APP_BASE_URL + "mastercampaign/delete/"+id;
       // setClientLoading(true);
         Api(endpoint,"PUT")
        .then(result => {
         

            if(result.data.status == 100 ){

              setMerror(true);
              setEmsg(result.data.message);
              
            }
            else{

            setMasterCampaign([]);
            setListingReload([])
            
            }
          
         }).catch((err)=>console.log(err))
        }
         
    }


    const[masterCampaignVal,setMasterCampaignVal] = useState(false);
    const[totalBudgetVal,setTotalBUdgetVal] = useState(false);
    const[clientVal,setClientVal] = useState(false);

 


    const columns = [
      { name:"Advertiser", options: {
      
        // setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
         setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
         filter:true
        
        
         }
      },
  
      { name:"Master Campaign", options: {
      
        // setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
         setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
          customBodyRenderLite: (dataIndex) => {
          return (
            <a onClick={(e) => {

              check(data[dataIndex][3])

            }}>
           
            <span style={{cursor:'pointer'}} > {data[dataIndex][1]}</span> 
            </a>
          );
        }
        
        
         }
      },
  
      { name:"Total Budget", options: {
      
        // setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
         setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
        
        
         }
      },
      {
        name:"id", options: {display: false, viewColumns: false, filter: false,}
      },
      {
        name: "",
        options: {
          
          setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white',  }}),
          filter: false,
          viewColumns:false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            return (
              <a onClick={(e) => {
                e.stopPropagation()
                archiveMasterCampaign(data[dataIndex][3]);
              // console.log(data[dataIndex][3],"afds");
              }}>
              <span className="las la-archive" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
              </a>
            );
          }
        }
      }, 
      {
        name: "",
        options: {
          setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
          setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
       
          filter: false,
          viewColumns:false,
          sort: false,
          empty: true,
          
          customBodyRenderLite: (dataIndex) => {
            return (
              <a onClick={(e) => {
                e.stopPropagation()
                editMasterCampaign(data[dataIndex][3]);
              // console.log(data[dataIndex][3],"afds");
              }}>
              <span className="las la-edit" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
              </a>
            );
          }
        }
      },
     {
        name: "",
        options: {
          setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
          filter: false,
          viewColumns:false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            return (
              <a onClick={(e) => {
              e.stopPropagation()
              deleteMasterCampaign(data[dataIndex][3]);
              // console.log(data[dataIndex][3],"afds");
              }}>
              <span className="las la-trash" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
              </a>
            );
          }
        }
      }
  
     
      ];
  
       
    const options = {
      search: searchBtn,
      download: downloadBtn,
      print: printBtn,
      viewColumns: viewColumnBtn,
      filter: filterBtn,
      filterType: "dropdown",
      responsive,
      tableBodyHeight,
      tableBodyMaxHeight,
      selectableRows: 'none',
      responsive: "standard",
     // onRowClick: rowData => {check(rowData)},
       
    };

    const data = listMasterCampaign.map((a,b)=>{return(
      [
      a.client,
      a.master_campaign_name,
      "€ "+a.total_budget.toLocaleString().replace(/,/g," ",).replace('.',','),
      a._id 
      
      ]
    )})


    useEffect(() => {


      const endpoint = process.env.REACT_APP_BASE_URL + "user/";
  
      Api(endpoint,"GET")
      .then(result => {
  
       
  
          setUsers(result.data);
  
  
       }).catch((err)=>console.log(err))
      
  
  
  
  },[])
  useEffect(() => {


    const endpoint = process.env.REACT_APP_BASE_URL + "config/GetMasterGoals/";

    Api(endpoint,"POST")
    .then(result => {

     

        setGoals(result.data.res);


     }).catch((err)=>console.log(err))
    



},[])
  


  return (
    
<>

        <main>
           
        <Button variant="contained" startIcon={<AddIcon />} onClick={addMasterCampaign} style={{marginBottom:'10px',backgroundColor:'#4169e1', color:'white'}}  >
         Add Master Campaign
        </Button>
        <div> 

        <div style={{marginBottom:10}}>
        {mError ? <Alert  onClose={() => { setMerror(false);}}  severity="error" color="error" variant='filled' style={{marginTop:10}}>{emsg}</Alert> : '' }
        </div>


            <div className="projects">

            <div className="card">
                  
            {dataLoading ? 
              <LinearProgress style={{marginBottom:5}} />
            :''}

            <ThemeProvider theme={createTheme()}>
            
              <MUIDataTable
              // title={"Campaign Information"}   
                data={data}
                columns={columns}
                options={options}
              
              
              />
          </ThemeProvider>

                    
                  {/**
                    <table width="100%">
                        <thead>
                            <tr>
                                <td width="30%">Advertiser</td>
                                <td width="40%">Master Campaign</td>
                                <td width="20%">Total Budget</td>
                               
                               <td></td>
                              

                            </tr>
                        </thead>
                        <tbody>
              
                           
                                {listMasterCampaign.map((key,index)=>{
                                    return(

                                    <tr style={{border:'1px solid lightgray'}}>
                                    <td style={{width:'10px'}}>{key.client}

                                    </td>
                                    <td >{key.master_campaign_name}</td>
                                    <td  >{key.total_budget}</td>
                                    <td>

                                        <DeleteForeverIcon  style={{color:'red',cursor:'hand'}} onClick={() => deleteMasterCampaign(key._id)} />
                                    </td>
                                   
                                </tr>

                                )})}
                              
                   
                        </tbody>
                    </table>
                     */}
           
            </div>
                
            </div>
            



            </div>
       

        </main>

        {open ?
        <EditMaster cid={cid} open={open} handleClose={handleClose} handleReload={handleReload} editMode={editMode}/>
        : '' }


        
        
  

</>

  )
}

export default Listing
import React, { useEffect, useState,useCallback}  from 'react'
//import './home.css';
import Api from '../Service/network';
import { FormControl, InputLabel,Select,MenuItem, TextField, Checkbox} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { usePlatform, usePlatformRes, useCampaignTypeRes, 
        useGoalRes,
        useGoal,
        useOptimizationKpiRes,
        useOptimizationKpi,
        useFrontSecondaryApi,
        useCampaignType, 
        useClientRes,
        useClient, 
        useGoogleCampaignRes, 
        useGoogleCampaign,
        useMasterCampaign,
        useMasterCampaignRes,
        useSubCampaign,
        useEditCampaignDetails
      
} from '../Zustand/store';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { isObject } from '@okta/okta-auth-js';


import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Step1 = (props) => {

/////////////////Zustand///////////////////////////////
const platformRes = usePlatformRes(state  => state.platform)
const setPlatformRes = usePlatformRes(state  => state.setPlatformRes)

const platform = usePlatform(state  => state.platform)
const setPlatform = usePlatform(state  => state.setPlatform)


const campaignTypeRes = useCampaignTypeRes(state  => state.campaignType);
const setCampaignTypeRes = useCampaignTypeRes(state  => state.setCampaignTypeRes);


const campaignType = useCampaignType(state  => state.campaign);
const setCampaignType = useCampaignType(state  => state.setCampaignType);

const editCampaignDetials = useEditCampaignDetails(state  => state.campaignDetails);
const setEditCampaignDetails = useEditCampaignDetails(state  => state.setCampaignDetails);


const goalRes = useGoalRes(state  => state.goal);
const setGoalRes = useGoalRes(state  => state.setGoalRes);


const goal = useGoal(state  => state.goal);
const setGoal = useGoal(state  => state.setGoal);


const optimizationKpiRes= useOptimizationKpiRes(state  => state.Kpi);
const setOptimizationKpiRes= useOptimizationKpiRes(state  => state.addOkpi);


const optimizationKpi = useOptimizationKpi(state  => state.Kpi);
const setOptimizationKpi = useOptimizationKpi(state  => state.setOptimizationKpi);


const secondaryKpi = useFrontSecondaryApi(state  => state.Kpi);
const setSecondaryKpi = useFrontSecondaryApi(state  => state.setSecondaryKpi);


const clientRes = useClientRes(state  => state.client);
const setClientRes = useClientRes(state  => state.setClientRes);

const client = useClient(state  => state.client);
const setClient = useClient(state  => state.setClient);

const googleCampaignRes = useGoogleCampaignRes(state  => state.google_campaign);
const setGoogleCampaignRes = useGoogleCampaignRes(state  => state.setGoogleCampaign);

const googleCampaign = useGoogleCampaign(state  => state.google_campaign );
const setGoogleCampaign = useGoogleCampaign(state  => state.setGoogleCampaign);

const masterCampaign = useMasterCampaign(state  => state.masterCampaign);
const setMasterCampaign = useMasterCampaign(state  => state.setMasterCampaign);

const masterCampaignRes = useMasterCampaignRes(state  => state.masterCampaign);
const setMasterCampaignRes = useMasterCampaignRes(state  => state.setMasterCampaign);

const subCampaign = useSubCampaign(state  => state.subCampaign);
const setSubCampaign = useSubCampaign(state  => state.setSubCampaign);



const[t, setT] = useState();


////////////////////////////////////////////////////////////////////////////////////////////

const[platformLoading, setPlatformLoading] = useState(false);
const[campaignTypeLoading, setCampaignTypeLoading] = useState(false);
const[goalLoading, setGoalLoading] = useState(false);
const[clientLoading, setClientLoading] = useState(false);
const[googleCampaignLoading, setGoogleCampaignLoading] = useState(false);


//////////////////////////////validatio state/////////////////////////////////////////////

const [platformVal, setPlatformVal] = useState(false);
const [campaignTypeVal, setCampaignTypeVal] = useState(false);
const [goalVal, setGoalVal] = useState(false);
const [clientVal, setClientVal] = useState(false);
const [oKpiVal, setOKpiVal] = useState(false);

const [masterCampaignVal, setMasterCampaignVal] = useState(false);
const [subCampaignVal, setSubCampaignVal] = useState(false);

const [googleCampaignVal, setGoogleCampaignVal] = useState(false);




///////////////////////////////////////////////////////////////////////////////////////////


const validate = ()  => {
    
    if (isObject(editCampaignDetials))
    {
        if(googleCampaign == ""){  setGoogleCampaignVal(true); window.scrollTo({ top: 0,left: 0,behavior: "smooth" }); return false; }else{
         return true;
        }
    }
    else{
        if(platform == ""){  setPlatformVal(true); window.scrollTo({top: 0,left: 0,behavior: "smooth" });  return false; }

        if(campaignType == ""){ setCampaignTypeVal(true); window.scrollTo({ top: 0, left: 0, behavior: "smooth"  }); return false; }

        if(client == ""){  setClientVal(true); window.scrollTo({ top: 0,left: 0,behavior: "smooth" }); return false; }

        if(goal == ""){  setGoalVal(true); window.scrollTo({  top: 0,left: 0, behavior: "smooth" }); return false; }


        if(optimizationKpi == ""){  setOKpiVal(true); window.scrollTo({ top: 0,left: 0,behavior: "smooth" }); return false; }


        if(masterCampaign == ""){  setMasterCampaignVal(true); window.scrollTo({ top: 0,left: 0,behavior: "smooth" }); return false; }

        if(subCampaign == ""){  setSubCampaignVal(true); window.scrollTo({ top: 0,left: 0,behavior: "smooth" }); return false; }

        if(googleCampaign == ""){  setGoogleCampaignVal(true); window.scrollTo({ top: 0,left: 0,behavior: "smooth" }); return false; }

        return true;
    }

}



props.step1Ref.current = {
    validate: validate
};


useEffect(()=>{
    
   
    const endpoint = process.env.REACT_APP_BASE_URL + "config/GetPlatform";
    setPlatformLoading(true);
    Api(endpoint,"GET")
    .then(result => {

     
      setPlatformRes(result.data.res);
      setPlatformLoading(false);
     }).catch((err)=>console.log(err))

},[platform])


/*
useEffect(()=>{

    
    const endpoint = process.env.REACT_APP_BASE_URL +  "mastercampaign/";
   
    Api(endpoint,"GET")
    .then(result => {

       
     
       
       setMasterCampaignRes(result.data)
       // setDataLoading(false);
      
     }).catch((err)=>console.log(err))
     

},[])*/




const getCampaignType = ((value) => {


   // zAddPlatform(value)
    setPlatformVal(false)
    setPlatform(value);
    setCampaignTypeLoading(true);
    setOptimizationKpi([]);
    setSecondaryKpi([]);
    const endpoint = process.env.REACT_APP_BASE_URL + "config/GetCampaignType";

    Api(endpoint,"POST", { platform: value})
    .then(result => {

        setCampaignTypeRes(result.data.res)
        setCampaignTypeLoading(false);
      // setCampignTypeRes(result.data.res);
     }).catch((err)=>console.log(err))

     //getting accounts

     const accountsEndpoint = process.env.REACT_APP_BASE_URL + "getAccounts";
     setClientLoading(true);
     Api(accountsEndpoint,"POST", { platform:value})
     .then(result => {
 
         // console.log(result,"get accounts")
       setClientRes(result.data.res);
       setClientLoading(false);
      }).catch((err)=>console.log(err))
 
    
})


const getGoal = ((value) => {

    setCampaignTypeVal(false);
    setGoalLoading(true);
    setCampaignType(value);
    setOptimizationKpi([]);
    const endpoint = process.env.REACT_APP_BASE_URL + "config/GetGoals";

    Api(endpoint,"POST", { platform: platform,campaign_type: value})
    .then(result => {

       setGoalLoading(false);
       setGoalRes(result.data.res);
     }).catch((err)=>console.log(err))
    
})





const getKpi = ((value) => {

    setGoalVal(false);
    setGoal(value);
    const endpoint = process.env.REACT_APP_BASE_URL +  "config/GetKPIs";

    Api(endpoint,"POST", { platform: platform,campaign_type: campaignType,goals:value})
    .then(result => {
    
        setOptimizationKpiRes(result.data.res);

       
     }).catch((err)=>console.log(err))
    
})



const getSecondaryKpi = ((value) => {

    setOptimizationKpi(value);
    setOKpiVal(false);
  
    const endpoint = process.env.REACT_APP_BASE_URL + "config/GetSecKPIs";

    Api(endpoint,"POST", { platform: platform,campaign_type: campaignType,goals:goal, optimization_KPI:value})
    .then(result => {


        setSecondaryKpi(result.data.res[0]["Secondary_KPI"]);
    
       // setOptimizationKpiRes(result.data.res);

       
     }).catch((err)=>console.log(err))

    
    
})



const getCampaign = ((index,value,name) => {

  
   setClient([value,name]);
   setGoogleCampaignLoading(true);
   //setAccount(value);
   setGoogleCampaignRes([]);
   setGoogleCampaign([]);

   const endpoint = process.env.REACT_APP_BASE_URL + "getCampaigns";
   Api(endpoint,"POST", {platform:platform, account:value})
   .then(result => {
   
      setGoogleCampaignLoading(false);
      setGoogleCampaignRes(result.data.res);
    }).catch((err)=>console.log(err))
    
    
})


const getMasterCampaign = ((name) => {

    
    setMasterCampaign([]);

    const endpoint = process.env.REACT_APP_BASE_URL + "mastercampaign/client/"+ name;
    Api(endpoint,"GET")
    .then(result => {
    
       
        setMasterCampaignRes(result.data);
       
     }).catch((err)=>console.log(err))
     
     
 })
 



const addSubCampaign = (e) =>{

    setSubCampaign(e);

}



/**
 * 
 * Edit Campaign 
 * 
 * 
 */

const pullPlatformCampaigns = (platform,account) =>{


   


  const endpoint = process.env.REACT_APP_BASE_URL + "getCampaigns";
  Api(endpoint,"POST", {platform, account})
  .then(result => {

    
      
      //setGoogleCampaignLoading(false);
       setGoogleCampaignRes(result.data.res);
     



      }).catch((err)=>console.log(err))



}

const  pullCampaignData  = (cid) => {
    

     //setPlatform(['Google Ads'])

     const accountsEndpoint = process.env.REACT_APP_BASE_URL +  "subCampaign/"+ cid ;
     
     Api(accountsEndpoint,"GET")
     .then(result => {

       

 
         setPlatform(result.data.platform);
         setCampaignType(result.data.campaign_type);
         setClient([result.data.Client, null])
         setGoal(result.data.goal)
         setOptimizationKpi(result.data.opt_kpi)
         setSecondaryKpi(result.data.secondary_kpi)
         setMasterCampaign([result.data.master_campaign_id,result.data.master_campaign_name])
         setSubCampaign(result.data.sub_campaign_name)
       
         setGoogleCampaign(
             result.data.campaigns.map((a,b)=>{return({ 
                 metagroup_name:a.name,
                 metagroup_id:a.id,
                 account_name:a.account_name,
                 adgroup_id:a.adgroup_id,
                 account_id:a.account_id,
                 start_date:a.start_date,
                 id:a.id,
                 label:a.name,
                 end_date:a.end_date})})
         );
       

         setEditCampaignDetails(result.data)
         pullPlatformCampaigns(result.data.platform,result.data.Client )


      }).catch((err)=>console.log(err))



}

useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const cid = searchParams.get('cid');
    

    if(cid){

       pullCampaignData(cid)

    }
   
    
    

},[])



 const handleChangePlatformCampaign = (selectedItems) => {
    
   setGoogleCampaignVal(false);
   // setSelectedItems(selectedItems );
   setGoogleCampaign(selectedItems);
   

  }

  const msgMultiSelect = {
    searchPlaceholder: "Search...",
    noItemsMessage: "No Platforms...",
    noneSelectedMessage: "None Selected",
    selectedMessage: "selected",
    selectAllMessage: "Select All",
    clearAllMessage: "Clear All",
    disabledItemsTooltip: "You can only select 1 file"
  }


  return (
   <div className="card-body" >
  

<form>
    <div className="form first">
        <div className="details personal" >
            

            <div className="fields" style={{margin:'10px', padding:'10px'}}>

                <div className="input-field">


                {!isObject(editCampaignDetials) ?
                <FormControl variant="outlined"    fullWidth >
                 <InputLabel id="demo-simple-select-standard-label">  
                    {platformVal ? <span style={{color:'red'}}>Please Choose Platform</span> : "Select Platform"} 
                     &nbsp;&nbsp; 
                     
                     {platformLoading == true ? "  Loading data .... please wait " : "" }
                    </InputLabel>
                    <Select fullWidth
                      
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={platform}
                        onChange={(e) => getCampaignType(e.target.value)}
                        label= "Please Choose Platform "  
                        error={platformVal}
                        disabled={isObject(editCampaignDetials) ? true : false}
                    >
                
                     {platformRes.length >= 1 ? platformRes.map((res,index) => {
                            
                            return(  
                                    
                            <MenuItem key={index} value={res.Platform} >{res.Platform}</MenuItem>

                            )})
                            : <MenuItem >No Data Avaliable</MenuItem>}
                        
                        
                    </Select>
            
                    </FormControl>

                    :

                        <FormControl variant="outlined"  fullWidth >
                        <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                        <Select fullWidth
                            displayEmpty={true}
                            id="demo-simple-select-standard"
                            error={campaignTypeVal}
                            disabled={true}
                            >
                        
                        
                            <MenuItem>{platform}</MenuItem>

                        
                            
                            
                        </Select>
                        </FormControl>

                    }


                    
                </div> 

                   
                <div className="input-field">

                    {!isObject(editCampaignDetials) ? 
               
                    <FormControl variant="outlined"  fullWidth >
                    <InputLabel id="demo-simple-select-standard-label">{campaignTypeVal ? <span style={{color:'red'}}>Please Choose Campaign Type</span> : 
                    "Select Campaign Type"}
                    
                    &nbsp;&nbsp; {campaignTypeLoading == true ? "  Loading data .... please wait " : "" } </InputLabel>
                    <Select fullWidth
                        displayEmpty
                        value={campaignType}
                        onChange={(e) => {getGoal(e.target.value)}}
                        label="Campaign Type"
                        id="demo-simple-select-standard"
                        error={campaignTypeVal}
                        disabled={isObject(editCampaignDetials) ? true : false}
                        >
                       
                        {campaignTypeRes.length >= 1 ? campaignTypeRes.map((res,index) => {
      
                        return(  
                                
                        <MenuItem key={index} value={res.Campaign_Type} >{res.Campaign_Type}</MenuItem>

                        )})
                        : <MenuItem >No Data Avaliable</MenuItem>}
                        
                        
                    </Select>
                    </FormControl>
                     
                     : 

                     <FormControl variant="outlined"  fullWidth >
                        <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                        <Select fullWidth
                            displayEmpty={true}
                            id="demo-simple-select-standard"
                            error={campaignTypeVal}
                            disabled={true}
                            >
                        
                        
                            <MenuItem>{campaignType}</MenuItem>

                        
                            
                            
                        </Select>
                        </FormControl>
                     
                     
                     
                     
                     }
                    
                </div>


                <div className="input-field">


                  
                      <Autocomplete
                          
                             
                              value={client[0]}
                              disablePortal
                              id="combo-box-demo"
                              options={clientRes}
                              getOptionLabel={(option) => option.clean_advertiser ? option.clean_advertiser: client[0]}
                             // disabled={true}
                              renderInput={(client) => <TextField {...client} 
                              label={clientVal ? <span style={{color:'red'}}>Please Choose Advertiser</span> : isObject(editCampaignDetials) ? editCampaignDetials.Client : "Select Advertiser"}  variant="outlined"  error={clientVal}/>}
                              onChange={(index, value) =>
                              { 
                                                     
                                  getCampaign(index,value.clean_advertiser);
                                  getMasterCampaign(value.clean_advertiser);
                                  setClientVal(false);
                              
                              }}

                              disabled={isObject(editCampaignDetials)?true:false}
                             
                              
                      />
                    
  
             

                    
                </div>

             

            </div>


               <div className="fields" style={{ margin:'10px', padding:'10px'}}>
                   

                   <div className="input-field">
                      
                    {!isObject(editCampaignDetials) ? 
                    <FormControl variant="outlined"  fullWidth>
                    <InputLabel id="demo-simple-select-standard-label">
                    {goalVal ? <span style={{color:'red'}}>Please Choose Goal</span>  : "Select Goal"} &nbsp;&nbsp; {goalLoading == true ? "  Loading data .... please wait " : "" }</InputLabel>
                    <Select fullWidth
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={goal}
                        onChange={(e) => getKpi(e.target.value)}
                        label="Goal"
                         
                        error={goalVal}
                        disabled={isObject(editCampaignDetials) ? true : false}
                        >
                
                        {goalRes.length >= 1 ? goalRes.map((res,index) => {
      
                        return(  
                                
                        <MenuItem key={index} value={res.Goal} >{res.Goal}</MenuItem>

                        )})
                        : <MenuItem >No Data Avaliable</MenuItem>}
                        
                        
                    </Select>
                    </FormControl>
                    :
                    
                    <FormControl variant="outlined"  fullWidth >
                    <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                    <Select fullWidth
                        displayEmpty={true}
                        label="Campaign Type"
                        id="demo-simple-select-standard"                     
                        disabled={true}
                        >
                       
                       
                        <MenuItem>{goal}</MenuItem>

                       
                        
                        
                    </Select>
                    </FormControl>
                    
                    }
                       
                   </div>

                   <div className="input-field">
                
                    {!isObject(editCampaignDetials) ? 
                   <FormControl variant="outlined"  fullWidth >
                   <InputLabel id="demo-simple-select-standard-label">{oKpiVal ? <span style={{color:'red'}}>Please Choose Optimization KPI</span> : "Select Optimization KPI"}  &nbsp;&nbsp;</InputLabel>
                   <Select fullWidth
                       
                       value={optimizationKpi}
                       onChange={(e) => {getSecondaryKpi(e.target.value);}}
                       label="Optimization KPI"
                        
                        error={oKpiVal}
                        disabled={isObject(editCampaignDetials) ? true : false}
                       >
               
                       {optimizationKpiRes.length >= 1 ? optimizationKpiRes.map((res,index) => {
     
                       return(  
                               
                       <MenuItem key={index} value={res.Optimization_KPI} >{res.Optimization_KPI}</MenuItem>

                       )})
                       : <MenuItem >No Data Avaliable</MenuItem>}
                       
                       
                   </Select>
                    </FormControl>
                    :
                    
                    <FormControl variant="outlined"  fullWidth >
                    <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                    <Select fullWidth
                        displayEmpty={true}
                       id="demo-simple-select-standard"                     
                        disabled={true}
                        >
                        <MenuItem>{optimizationKpi}</MenuItem>
                     </Select>
                    </FormControl>
                    
                    
                    }

                   
                    
                </div>

              


                <div className="input-field">
                  
                {!isObject(editCampaignDetials) ? 
                <FormControl fullWidth >
                    <TextField fullWidth disabled variant='outlined'
                
                            id="demo-simple-select"
                            value={secondaryKpi}
                            //onChange={(e) => {setEmail(e.target.value);setEmailVal(false);}}
                            label= { "Secondary KPI"} 
                            
                        
                        
                    />
                    </FormControl>

                    :


                    <FormControl fullWidth >
                    <TextField fullWidth disabled variant='outlined'
                
                            id="demo-simple-select"
                            value={secondaryKpi}
                           
                        
                    />
                    </FormControl>
                    
                    
                    }
                </div>
               

              
              
                
               </div> 




            <div className="fields_second" style={{margin:'10px', padding:'10px'}}>
                    
                  

                    <div className="input-field" fullWidth>

                                
                    <Autocomplete
                        
                          value={masterCampaign[1]}
                          disablePortal
                          // disabled={true}
                          id="combo-box-demo"
                          options={masterCampaignRes}
                          getOptionLabel={(option) => option.master_campaign_name ? option.master_campaign_name: masterCampaign[1]}
                          renderInput={(masterCampaign) => <TextField {...masterCampaign} label={masterCampaignVal ? <span style={{color:'red'}}>Please Choose Master Campaign</span>  : isObject(editCampaignDetials) ? editCampaignDetials.master_campaign_name : "Select Master Campaign"}   variant="outlined"  error={masterCampaignVal} />}
                          onChange={(index, value) =>
                          { 
                             
                              //getCampaign(index, value._id,value.master_campaign_name);
                              setMasterCampaign([value._id,value.master_campaign_name])
                              setMasterCampaignVal(false);
                          
                          }}
                          
                          disabled={isObject(editCampaignDetials)?true:false}
                  />

                  


                    </div>



                    <div className="input-field" >
                                <FormControl variant="standard"  fullWidth   >
                                <TextField  variant="outlined"
                            
                                        id="demo-simple-select"
                                        value={subCampaign}
                                        onChange={(e) => {addSubCampaign(e.target.value); setSubCampaignVal(false)}}
                                        label={subCampaignVal ? <span style={{color:'red'}}>Please Enter Sub Campaign</span> : isObject(editCampaignDetials) ? editCampaignDetials.sub_campaign_name : "Enter Sub Campaign"} 
                                        error={subCampaignVal }
                                    
                                    
                                />
                                </FormControl>

                    </div>



             </div>



            


               <div className="fields" style={{margin:'10px', padding:'10px', }}>
               
            


                <div className="input-field" style={{width: '100%'}}>

              
                    <>                   
                   

                  
                   {googleCampaignVal ?  <label style={{padding:5, color:'red'}}>Please Select Platform Campaign</label> : '' } 
                    <MultiSelect
                        items={googleCampaignRes}
                        selectedItems={googleCampaign}
                        onChange={handleChangePlatformCampaign}
                        messages={msgMultiSelect}
                    /> 
                    
                 
                   
                </>

               

                </div>

               

             
                <div className="input-field">

              
                </div>

                </div>

               </div>

        

        </div>

    
        
        </form>
</div>
  )
}

export default Step1